import { useCallback, useState } from "react";
import { IconButton } from "../../../../../elements";
import AgentTeamAvatar from "./AgentTeamAvatar";
import AgentTeamName from "./AgentTeamName";
import AgentTeamIndicators from "./AgentTeamIndicators";
import AgentTeamList from "./AgentTeamList";

import {
  SelectorWrapper,
  SelectorContentWrapper,
  SelectorDropdownWrapper,
  SelectorHamburgerWrapper,
  SelectorHamburgerItem,
} from "./AgentTeamSelector.styled";
import { observer } from "mobx-react";

const AgentTeamSelector = observer(
  ({ agentName, status, company, agents, scope, onChange, agentImageUrl }) => {

    const [openDropdown, setOpenDropdown] = useState(false);
    const [openHamburger, setOpenHamburger] = useState(false);

    const onHandleOpenDropdown = () => {
      setOpenDropdown(!openDropdown);
    };

    const onHandleOpenHamburger = () => {
      setOpenHamburger(!openHamburger);
    };

    const onResetDropdown = useCallback(
      (id) => {
        setOpenDropdown(false);
        setOpenHamburger(false);
        onChange && onChange(id);
      },
      [onChange],
    );

    const onCloseDropdown = () => {
      setOpenDropdown(false);
    };

    return (
      <SelectorWrapper>
        <AgentTeamAvatar url={agentImageUrl} />
        <SelectorContentWrapper>
          <AgentTeamName agentName={agentName} />
          <AgentTeamIndicators
            status={status}
            company={company}
            scope={scope}
          />
        </SelectorContentWrapper>
        <SelectorDropdownWrapper openDropdown={openDropdown}>
          <IconButton
            icon="expandMoreOutlined"
            width="24px"
            height="24px"
            onClick={onHandleOpenDropdown}
          />
        </SelectorDropdownWrapper>
        <AgentTeamList
          isVisible={openDropdown}
          items={agents.map((a) => ({ name: a.name, value: a.id, photo_url: a.photo_url }))}
          onSelect={onResetDropdown}
          onClose={onCloseDropdown}
        />
        <SelectorHamburgerWrapper isVisible={openHamburger}>
          <SelectorHamburgerItem>Archive</SelectorHamburgerItem>
          <SelectorHamburgerItem>Show Versions</SelectorHamburgerItem>
        </SelectorHamburgerWrapper>
      </SelectorWrapper>
    );
  },
);

export default AgentTeamSelector;
