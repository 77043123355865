import React, { useState } from 'react';
import { TabsContainer, TabsHeader, Tab, TabContent } from './IconTabs.styled';
import { Icon } from '../../../icon';

const Tabs = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [isOpen, setIsOpen] = useState(true);

  const handleTabClick = (index) => {
    const isSameTab = activeTab === index;
  
    setActiveTab(index);
    setIsOpen(isSameTab ? !isOpen : true);
  };

  return (
    <TabsContainer>
      <TabsHeader>
        {tabs.map((tab, index) => (
          <Tab key={index} isActive={activeTab === index} onClick={() => handleTabClick(index)}>
            <Icon name={tab.icon}/>
            <span>{tab.label}</span>
          </Tab>
        ))}
      </TabsHeader>
      {isOpen && (
        <TabContent>
          {tabs.map((tab, index) => (activeTab === index && tab.content))}
        </TabContent>
      )}
    </TabsContainer>
  );
};

export default Tabs;
