import TemplateEditorStore from "../components/templateEditor/TemplateEditorStore";
import {action, makeObservable, observable} from "mobx";

class RetryStore {
  retries: [] = [];
  templateEditorStore = new TemplateEditorStore();

  constructor() {
    makeObservable(this, {
      templateEditorStore: observable,
      retries: observable,
      updateField: action,
    });
  }

  updateField(field, value) {

    this[field] = value;
  }
}

export default RetryStore;
