import React, { useState, useMemo, useEffect } from "react";
import { observer } from "mobx-react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import {
  TableHeaderStyle,
  SegmentTableWrapper,
  muiTablePaperProps,
} from "./SegmentTable.styled";
import { useStore } from "../../../../hooks";
import { SuccessfulModal } from "../../..";
import { useMediaQueries } from "../../../../utils/mediaQueries";
import { getDensity } from "../../../../utils/GetDensity/GetDensity";
import { MediaQuery } from "../../../sharedStyles/MediaQuery";
import StatusBadge from "../../../StatusBadge";

const defaultValue = ({ cell }) => {
  const getValue = cell.getValue().trim();
  return getValue.length ? getValue : "-";
};

const columns = [
  {
    accessorKey: "contact_name",
    header: "Full name",
    Cell: defaultValue,
  },
  {
    accessorKey: "contact_company_name",
    header: "Company",
    Cell: defaultValue,
  },
  {
    accessorKey: "contact_address",
    header: "Address",
    Cell: defaultValue,
  },
  {
    accessorKey: "contact_state",
    header: "State",
    Cell: defaultValue,
  },
  {
    accessorKey: "contact_city",
    header: "City",
    Cell: defaultValue,
  },
  {
    accessorKey: "contact_postal_code",
    header: "Postal Code",
    Cell: defaultValue,
  },
  {
    accessorKey: "contact_phone",
    header: "Phone",
    Cell: defaultValue,
  },
  {
    accessorKey: "contact_status",
    header: "Status",
    Cell: ({ renderedCellValue }) => (
      <StatusBadge status={renderedCellValue.toLowerCase()}>
        {renderedCellValue}
      </StatusBadge>
    ),
  },
];

const SegmentTable = observer(() => {
  const { authStore, contactSegmentStore } = useStore();
  const { isMobile, isTablet, isDesktop } = useMediaQueries;

  const { selectedAccount } = authStore;
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([{ id: "contact_name", desc: false }]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const density = useMemo(() => {
    getDensity(isMobile, isTablet, isDesktop);
  }, [isMobile, isTablet, isDesktop]);

  useEffect(() => {
    if (contactSegmentStore.account)
      contactSegmentStore.getContacts({
        pageIndex: pagination.pageIndex,
        pageSize: pagination.pageSize,
        sortBy: sorting,
        search: globalFilter,
      });
  }, [
    contactSegmentStore.account,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
    globalFilter,
    contactSegmentStore.advancedFilters,
  ]);

  useEffect(() => {
    contactSegmentStore.init(selectedAccount.id);
  }, []);

  const table = useMaterialReactTable({
    columns,
    muiTopToolbarProps: null,
    muiTableContainerProps: {
      sx: MediaQuery,
    },
    data: contactSegmentStore.contacts,

    manualPagination: true,
    manualSorting: true,
    manualGlobalFilter: true,

    enableRowSelection: false,
    enableSorting: false,
    enableColumnFilters: false,
    showGlobalFilter: false,
    enableHiding: true,
    enableColumnActions: false,
    enableColumnResizing: false,
    enableFullScreenToggle: true,
    enableDensityToggle: true,
    enableTopToolbar: false,

    positionToolbarAlertBanner: "none",
    initialState: {
      sorting,
      density: density,
    },
    getRowId: (row) => row.id,
    renderTopToolbarCustomActions: () => null,
    muiTablePaperProps,
    positionActionsColumn: "last",
    paginationDisplayMode: "pages",
    state: {
      pagination,
      globalFilter,
      sorting,
      isLoading: contactSegmentStore.isLoading,
    },
    muiPaginationProps: {
      rowsPerPageOptions: [10, 13, 15, 17, 19, 21, 30, 40],
      showFirstButton: false,
      showLastButton: false,
      labelRowsPerPage: "Show",
      showRowsPerPage: true,
      variant: "outlined",
      shape: "rounded",
    },
    muiTableBodyRowProps: {
      sx: {
        height: "42px",
      },
    },
    displayColumnDefOptions: {
      "mrt-row-actions": {
        enableColumnVisibility: false,
      },
      "mrt-row-select": {
        enableColumnVisibility: false,
      },
      "mrt-row-numbers": {
        enableColumnVisibility: false,
      },
    },
    muiTableBodyCellProps: ({ cell }) => ({
      sx: {
        "& .MuiCheckbox-root": {
          transform: "scale(0.6)",
        },
      },
    }),
    muiTableHeadCellProps: {
      sx: {
        textAlign: "left",
        height: "42px",
        ...TableHeaderStyle,
      },
    },
    onGlobalFilterChange: (filter) => {
      setGlobalFilter(filter);
      setPagination((prev) => ({ ...prev, pageIndex: 0 }));
    },
    onPaginationChange: setPagination,
    onSortingChange: (sorting) => {
      setSorting(sorting);
      setPagination((prev) => ({ ...prev, pageIndex: 0 }));
    },
    onPageSizeChange: (newPageSize) => {
      setPagination((prev) => ({
        ...prev,
        pageSize: newPageSize,
        pageIndex: 0,
      }));
    },
  });

  return (
    <SegmentTableWrapper>
      <MaterialReactTable table={table} />
      {contactSegmentStore.error && (
        <SuccessfulModal
          isOpen={contactSegmentStore.error}
          onClose={() => contactSegmentStore.onClearError()}
          title="Oops!"
          subtitle="An error occurred while loading the data. Please refresh the page or contact support"
          subtitle2={contactSegmentStore.error}
          zIndex={true}
        />
      )}
    </SegmentTableWrapper>
  );
});

export default SegmentTable;
