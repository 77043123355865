import styled from "styled-components";

export const TabsWrapper = styled.div`
  transition: width 0.3s ease;
  overflow: hidden;
`;

export const InputWrapper = styled.span`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 20px;

  p {
    margin: 0;
  }

  > div {
    margin: 0;
  }
`;

export const TabContainer = styled.div`
  margin: 24px;
  display: flex;
  flex-direction: column;

  h1 {
    margin: 0;
  }
`;

export const ButtonWrapper = styled.div`
  float: right;
  button {
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.clearBlue};
    font-weight: 400;
  }
`;

export const ButtonContiner = styled.div`
  height: 36px;
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Subtitle = styled.p`
  ${(props) => props.theme.fonts.variant.semiBold()};
  font-size: 1em;
`;