import styled from "styled-components"
import Alert from "@mui/material/Alert";

export const WarningAlert = styled(Alert)`
  position: absolute;
  top: ${(props) => props.top || "61px"};
  right: ${(props) => props.right || "16px"};
  ${(props) => props.left && `left: ${props.left}`};
  z-index: 1000;
  width:  ${(props) => props.width || "auto"};
  animation: slide-down 0.5s ease-out;
  font-family: ${(props) => props.theme.fonts.inter};

  & > .MuiAlert-icon {
    display: flex;
    align-items: center;
    padding: 0;
  }

  & > .MuiAlert-message {
    padding: 0;
    display: flex;
  }

  & > .MuiAlert-root {
    font-family: ${(props) => props.theme.fonts.inter};
  }
`;

export const ContentAlert = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  justify-content: center;

  & > p {
    margin: 0;
  }
`;

export const AlertTitle = styled.div`
  order: 0;
  font-weight: bold;
  font-family: ${(props) => props.theme.fonts.family.inter}
`;

export const AlertMessage = styled.div`
  order: 1;
  font-family: ${(props) => props.theme.fonts.family.inter}
`;
