import React, { useEffect } from "react";
import { ContactsSegment } from "../../../../elements/v2/ContactsSegment";
import {
  SegmentsWrapper,
  CustomGenericListStyleSegments,
} from "./Marketing.styled";
import { useMediaQueries } from "../../../../utils/mediaQueries";
import { useStore } from "../../../../hooks";
import { observer } from "mobx-react";

const Segments = observer(() => {
  const { isTablet } = useMediaQueries();
  const {  marketingStore, contactSegmentStore, authStore } = useStore();

  const onFileUpload = () => {
    marketingStore.setMarketingAttribute("contactFile", {
      name: `${contactSegmentStore.fileId.split('/')[1]}.json`,
      url: `resources/web/${authStore.selectedAccount.id}/marketing_conversations/${contactSegmentStore.fileId}.json`
    })

    marketingStore.setMarketingAttribute("numberSegments", contactSegmentStore.parsedContactList.length);
    marketingStore.setMarketingAttribute("contactCSVFile", contactSegmentStore.fileUploaded?.name);
  }

  useEffect(() => {
    if(marketingStore.contactCSVFile){
      contactSegmentStore.updateGeneralField("fileUploaded", {name: marketingStore.contactCSVFile})
      contactSegmentStore.setIsFileUploaded(true);
    } else {
      contactSegmentStore.setIsFileUploaded(false);
    }
  }, [marketingStore.contactCSVFile])

  return (
    <SegmentsWrapper isTablet={isTablet}>
      <ContactsSegment onFileUpload={onFileUpload}  showFile={!!marketingStore.contactCSVFile}/>
      {isTablet && <CustomGenericListStyleSegments />}
    </SegmentsWrapper>
  );
});

export default Segments;
