import { action, computed, flow, makeObservable, observable, toJS, runInAction } from "mobx";
import { API, Auth } from "aws-amplify";
import { getCustomers, getAgent, getUsers, fullUserPermissions, getPermissionCatalogUser } from "../../graphql/queries";

const PermissionLevelsMapping = {
  v: 'VIEW',
  u: 'UPDATE',
  c: 'CREATE',
  d: 'DELETE'
}

class AuthStore {
  isLoading = true;
  isError = false;
  isSuperAdmin = false;
  isGroupSuperAdmin = false;
  isGroupAdmin = false;
  isCustomerAdmin = false;
  isUser = false;
  users = [];
  customerId = "";
  userId = "";
  customer = null;
  selectedAccount = null;
  favoriteAccount = [];
  listAccount = [];
  agents = [];
  userData = null;
  currentUserFirstName = "";
  userPermissions = [];
  navbarActionElement = null;

  constructor() {
    makeObservable(this, {
      isLoading: observable,
      isError: observable,
      isSuperAdmin: observable,
      isCustomerAdmin: observable,
      isGroupSuperAdmin: observable,
      isGroupAdmin: observable,
      isUser: observable,
      customerId: observable,
      customer: observable,
      users: observable,
      userId: observable,
      listAccount: observable,
      selectedAccount: observable,
      favoriteAccount: observable,
      agents: observable,
      userData: observable,
      currentUserFirstName: observable,
      navbarActionElement: observable,
      selectedAccountInitials: computed,
      sortedAccountListByFavs: computed,
      clear: action,
      pickAccount: action,
      rollbackAccount: action,
      saveLocalStorageFavoriteAccounts: action,
      getGlobalInformation: flow,
      fetchAgents: flow,
      fetchUserData: flow,
      fetchUserPermissions: flow,
      userPermissions: observable,
      hasPermission: action,
      setNavbarActionElement: action,
      fetchAllUsers: flow
    });

  }

  get selectedAccountInitials() {
    if (this.selectedAccount) {
      const splitName = this.selectedAccount.name.split(" ").slice(0, 2);
      let initials = "";

      if (splitName.length === 1) {
        initials = splitName[0].slice(0, 2);
      } else {
        initials = splitName.map((word) => word.charAt(0)).join("");
      }

      return initials.toUpperCase();
    }
  }

  pickAccount(id) {
    const account = this.listAccount.find((account) => account.id === id);
    this.saveLocalStorageFavoriteAccounts(account.id);
    this.selectedAccount = account;
  }

  rollbackAccount() {
    if (this.favoriteAccount.length > 0) {
      this.pickAccount(this.favoriteAccount[1]);
    }
  }

  saveLocalStorageFavoriteAccounts(id) {
    const currentFavoritesAccount =
      JSON.parse(localStorage.getItem("favoriteAccount")) || [];
    const existId = currentFavoritesAccount.findIndex(
      (idSaved) => idSaved.trim() === id.trim(),
    );

    if (existId !== -1) {
      currentFavoritesAccount.splice(existId, 1);
    }

    currentFavoritesAccount.unshift(id);

    if (currentFavoritesAccount.length > 5) {
      currentFavoritesAccount.pop();
    }

    this.favoriteAccount = currentFavoritesAccount;
    localStorage.setItem(
      "favoriteAccount",
      JSON.stringify(currentFavoritesAccount),
    );
  }

  get sortedAccountListByFavs() {
    const indexMap = {};
    const currentFavoritesAccount = [...this.favoriteAccount];
    const currentAccounts = [...this.listAccount];

    currentFavoritesAccount.forEach((favorite, index) => {
      indexMap[favorite] = index;
    });

    return currentAccounts.sort((a, b) => {
      const indexA = indexMap[a.id];
      const indexB = indexMap[b.id];

      if (indexA !== undefined && indexB !== undefined) {
        return indexA - indexB;
      }

      if (indexA !== undefined) {
        return -1;
      }
      if (indexB !== undefined) {
        return 1;
      }

      return 0;
    });
  }

  clear() {
    localStorage.removeItem("favoriteAccount");
    this.isLoading = true;
    this.isError = false;
    this.isSuperAdmin = false;
    this.isCustomerAdmin = false;
    this.isUser = false;
    this.customerId = "";
    this.userId = "";
    this.customer = null;
    this.selectedAccount = null;
    this.listAccount = [];
    this.agents = [];
    this.userData = null;
    this.currentUserFirstName = "";
    this.userPermissions = [];
  }

  *getGlobalInformation() {
    try {
      this.isLoading = true;
      const userInfo = yield Auth.currentAuthenticatedUser({
        bypassCache: true,
      });

      const groups =
        userInfo.signInUserSession.accessToken.payload["cognito:groups"];

      const responseCustomer = yield API.graphql({
        query: getCustomers,
        variables: {
          input: { id: userInfo.attributes["custom:customer"] ?? "" },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      const customerData = JSON.parse(
        responseCustomer?.data?.getCustomers?.body,
      );

      const response = yield API.graphql({
        query: getCustomers,
        variables: {
          input: { id: "" },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      const customerList = JSON.parse(response.data?.getCustomers?.body).map(
        ({ name, id, metadata }) => ({ name, id, metadata }),
      );

      const favoritesAccount =
        JSON.parse(localStorage.getItem("favoriteAccount")) || [];

      if (favoritesAccount.length === 0) {
        this.saveLocalStorageFavoriteAccounts(customerData?.id);
        favoritesAccount.unshift(customerData?.id);
      }

      this.isSuperAdmin = groups?.includes("superadmin");
      this.isCustomerAdmin = groups?.includes("customeradmin");
      this.isUser = groups?.includes("user");
      this.customerId = userInfo.attributes["custom:customer"] ?? "";
      this.userId = userInfo.attributes.sub ?? "";
      this.customer = customerData;
      this.selectedAccount = customerList.find(
        (account) => account.id === favoritesAccount[0],
      );
      this.listAccount = [...customerList];
      this.favoriteAccount = favoritesAccount;
      yield this.fetchUserData(this.userId);
      yield this.fetchUserPermissions();

    } catch (error) {
      this.clear();
    } finally {
      this.isLoading = false;
    }
  }

  *fetchAgents() {
    try {
      this.isError = false;
      const response = yield API.graphql({
        query: getAgent,
        variables: {
          input: {
            customer_id: this.selectedAccount.id,
          },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      const data = JSON.parse(response.data?.getAgent?.body);
      this.agents = data.filter(
        (agent) => agent.domain === "concierge" || agent.domain === "",
      );
    } catch (error) {
      this.isError = true;
    }
  }

  *fetchAllUsers() {
    this.isError = false;
    try {
      const response = yield API.graphql({
        query: getUsers,
        variables: { input: { id: "", customer: this.customerId } },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      const users = JSON.parse(response.data?.getUsers?.body);
      this.users = users;
      return users;
    } catch (error) {
      this.isError = true;
    }
  }

  *fetchUserData(userId) {
    try {
      const response = yield API.graphql({
        query: getUsers,
        variables: { input: { id: userId, customer: this.customerId } },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      const userData = JSON.parse(response.data?.getUsers?.body);
      this.userData = userData;
      this.currentUserFirstName = userData.first_name;
      this.isGroupSuperAdmin = userData.is_super_admin;
      this.isGroupAdmin = userData.is_admin;
    } catch (error) {
      this.isError = true;
    }
  }

  *fetchUserPermissions() {
    try {
      this.isLoading = true;
      const response = yield API.graphql({
        query: getPermissionCatalogUser,
        variables: {
          input: {
            customer_id: this.selectedAccount.id,
            user_id: this.userId
          }
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });

      this.userPermissions = JSON.parse(response.data?.getPermissionCatalogUser?.body) || [];
    } catch (error) {
      this.userPermissions = [];
    }
  }


  hasPermission(code, action = 'v') {
    if (this.isGroupSuperAdmin || this.isGroupAdmin) {
      return true;
    }
    const permissionObject = this.userPermissions.find(p => p.code === code);
    return  permissionObject && permissionObject.permission[PermissionLevelsMapping[action]] !== 'none'
  }

  setNavbarActionElement(element) {
    this.navbarActionElement = element;
  }
}

export default AuthStore;