import styled from "styled-components";

export const StyledButton = styled.button`
  padding: 6px 12px;
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.aliceBlue};
  color: ${(props) => props.theme.colors.black};
  cursor: pointer;
  font-size: 12px;
`;

export const CompletedText = styled.p`
  color: ${(props) => props.theme.colors.veroneseGreen};
  font-weight: bold;
  font-size: 12px;
  margin: 0;
`;

export const StyledContainer = styled.div`
  display: flex;
  margin-left: 10px;
  flex-direction: column;
  height: 93vh;
`;

export const StyledText = styled.span`
  font-weight: "normal";
`;

export const TableBodyRow = styled.div`
  background-color: ${(props) => (!props.isResolved ? props.theme.colors.aliceBlue : 'transparent')};
`;

export const TablePaper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
`;

export const TableContainer = styled.div`
  flex-grow: 1;
  overflow: auto;
`;