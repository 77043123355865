import styled from "styled-components";

import { Box } from "@mui/material";

export const ActionBox = styled(Box)`
  display: flex;
  gap: 1rem;
`;

export const TopToolbarBox = styled(Box)`
  display: flex;
  justify-content: flex-end;
`;

export const CreateButtonWrapper = styled.div`
  margin-left: auto;
`;

export const LoadingWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1;
`;

export const EmptyStateWrapper = styled.div`
  text-align: center;
  padding: 2rem;
  color: ${(props) => props.theme.colors.gray};
`;
