import { useCallback } from "react";
import { observer, Provider as ProviderStore } from "mobx-react";
import { ImagePickerModal } from "./components";
import BasedTemplateEditor from "./BasedTemplateEditor";
import ModalTemplateEditor from "./ModalTemplateEditor";

const TemplateEditor = observer(({
 store,
 isPopup = true,
 onClose,
 isReadMode,
 onExportHtml,
}) => {
  return (
    <ProviderStore templateEditorStore={store}>
      <ImagePickerModal />
      {isPopup ? (
        <ModalTemplateEditor
          isOpen={isPopup}
          isReadMode={isReadMode}
          onClose={onClose}
          onSave={onExportHtml}
        />
      ) : (
        <BasedTemplateEditor />
      )}
    </ProviderStore>
  );
});

export default TemplateEditor;