import React from "react";
import CloseIcon from '@mui/icons-material/Close';
import {
  StyledFormControl,
  StyledInputLabel,
  StyledMenuItem,
  StyledSelect,
  ClearButton,
} from "./FilterSelector.styled";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import Radio from "@mui/material/Radio";
import { DefaultTheme as theme  } from "../../../theme";

const FilterSelector = ({
  title,
  data,
  itemHeight,
  itemPaddingTop,
  minWidthForm,
  minWidthPaper,
  value,
  handleChange,
  type = "multiple",
  disabled = false,
}) => {
  const valueIsGreaterThan = value.length > 0;
  const isTypeMultiple = type === "multiple";
  const menuProps = {
    PaperProps: {
      style: {
        maxHeight: itemHeight * 5 + itemPaddingTop,
        minWidth: minWidthPaper,
        width: "auto",
        borderRadius: "8px",
        border: `1px solid ${theme.colors.clearGray}`,
        marginTop: 8,
        scrollbarWidth: "thin",
      },
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "right",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "right",
    },
  };

  const getRenderValue = (selected) => {
    return title;
  };

  const handleClear = (e) => {
    e.stopPropagation();
    handleChange({ target: { value: [] } });
  };

  return (
    <>
      <StyledFormControl
        variant="outlined"
        sx={{ m: 1 }}
        minWidth={minWidthForm}
        size="small"
        hasSelection={valueIsGreaterThan}
        disabled={disabled}
      >
        <StyledInputLabel>
          {title}
        </StyledInputLabel>
        <StyledSelect
          disabled={disabled}
          minWidth={minWidthForm}
          multiple={isTypeMultiple}
          autoWidth
          variant="outlined"
          label={title}
          value={isTypeMultiple ? value : value[0] || ""}
          onChange={handleChange}
          renderValue={getRenderValue}
          MenuProps={menuProps}
          hasSelection={valueIsGreaterThan}
          endAdornment={
            valueIsGreaterThan ? (
              <ClearButton onClick={handleClear}>
                <CloseIcon fontSize="small" />
              </ClearButton>
            ) : null
          }
        >
          {data &&
            data?.map((item, index) => {
              const lowerCaseItem = item.toLowerCase();
              const capitalizedItem = item.charAt(0).toUpperCase() + item.slice(1);
              const firstIndexIsCorrect = value[0] === lowerCaseItem;
              return (
                <StyledMenuItem
                  key={index}
                  value={lowerCaseItem}
                  onClick={(e) => {
                    if (!isTypeMultiple) {
                      e.stopPropagation();
                      handleChange({ target: { value: firstIndexIsCorrect ? [] : [lowerCaseItem] } });
                    }
                  }}
                >
                  {
                    isTypeMultiple ?
                      <Checkbox checked={value.includes(lowerCaseItem)} />
                      :
                      (<Radio checked={value[0] === lowerCaseItem} onClick={(e) => e.stopPropagation()} />)
                  }
                  <ListItemText primary={capitalizedItem} />
                </StyledMenuItem>
              );
            })}
        </StyledSelect>
      </StyledFormControl>
    </>
  );
};

export default FilterSelector;
