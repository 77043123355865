import styled from "styled-components";

export const VariableWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
`;

export const VariableContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  width: 90%;

  input {
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.darkGray};
    font-size: 0.875em;
    padding: 0 5px;
  }
`;

export const VariableHeader = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.colors.highClearGray};
  height: 48px;
  align-items: center;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  border-bottom: 1px solid ${(props) => props.theme.colors.clearGray};

  span,
  div {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div:nth-child(1) {
    flex: 1;
  }

  div:nth-child(2) {
    flex: 1;
    border: 1px solid ${(props) => props.theme.colors.clearGray};
    border-top: 0;
    border-bottom: 0;
  }

  span:nth-child(3) {
    margin: 10px;
  }

  div {
    input {
      height: 32px;
    }
  }
`;

export const VariableBody = styled.div`
  background-color: ${(props) => props.theme.colors.primaryWhite};
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  height: 122px;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > div:first-child {
    margin-top: 10px;
    > div:first-child {
      border: 1px solid ${(props) => props.theme.colors.clearGray};
    }
  }

  > div {
    > div {
      border: 1px solid ${(props) => props.theme.colors.clearGray};
    }
  }

  input {
    width: 90%;
    height: 40px;
  }
`;

export const VariableSelects = styled.div`
  margin-top: 10px;
  display: flex;
  gap: 5px;
`;