import {API} from "aws-amplify";
import {
  getImage,
  getUploadUrlFile,
  getVehicleStockFilter,
  getVehicleStockPictures,
  getVehicleStockImages as getVehicleStockImagesData,
  putImage
} from "../../../graphql/queries";
import {action, flow, makeObservable, observable} from "mobx";
import axios from "axios";

export default class ImagePickerStore {
  isOpenCustomLibraryModal = false;
  vehicleStockImages = {};
  vehicleStockImagesTotalRowCount = 0;
  onPickImage = null;
  s3FileRoute = "email_templates";

  constructor() {
    makeObservable(this, {
      isOpenCustomLibraryModal: observable,
      onPickImage: observable,
      vehicleStockImages: observable,
      vehicleStockImagesTotalRowCount: observable,
      s3FileRoute: observable,
      onSearchImagesStock: action,
      setOnPickImage: action,
      putFileName: flow,
      uploadFileToS3: flow,
      getImageRequest: flow,
      getVehicleStockImages: flow,
      getVehiclesStockPicturesRequest: flow,
      getVehiclesStockPicturesFilters: flow,
    });
  }

  setOnPickImage(onPickImage) {
    this.onPickImage = onPickImage;
  }

  *onSearchImagesStock(search) {
    yield this.getVehicleStockImages(search);

    if (!this.vehicleStockImages.vehicles) {
      return {
        stockImages: [],
        total: 0,
        hasMore: false,
      }
    }

    const total = this.vehicleStockImagesTotalRowCount;
    const hasMore = total > search.page * search.page_size;

    return {
      stockImages: this.vehicleStockImages.vehicles,
      total,
      hasMore,
    }
  }

  *putFileName(props) {
    const { account, display_name, gallery_name, attributes, metadata } = props;
    try {
      const response = yield API.graphql({
        query: putImage,
        variables: {
          input: {
            account,
            display_name,
            gallery_name,
            attributes: attributes || [],
            metadata: JSON.stringify({})
          }
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      return JSON.parse(response.data?.putImage?.body);
    } catch (error) {
      this.isError = true;
    }
  }

  *uploadFileToS3(customer, content, type, name, file_route=null) {
    const blobFile = new Blob([content], { type });

    const response = yield API.graphql({
      query: getUploadUrlFile,
      variables: {
        input: {
          customer: customer,
          file_name: name,
          file_type: type,
          file_route: file_route || this.s3FileRoute,
          overwrite: true,
        },
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });

    const uploadUrl = JSON.parse(response.data.getUploadUrlFile?.body);
    const config = {
      headers: {
        "Content-Type": type,
      },
    };

    yield axios.put(uploadUrl, blobFile, config);
    return uploadUrl;
  }

  *getImageRequest(accountId) {
    try {
      this.isError = false;
      const response = yield API.graphql({
        query: getImage,
        variables: {
          input: {
            account: accountId,
            gallery_name: "unlayer_editor",
            last_evaluated_key: null
          },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      return JSON.parse(response.data?.getImage?.body);

    } catch (error) {
      this.isError = true;
    }
  }

  *getVehiclesStockPicturesRequest(parameters) {
    const {
      page_size,
      page,
      order_by,
      search,
      filters: {
        make,
        model,
        year,
        type,
        angle,
        background,
        size,
      }
    } = parameters;

    try {
      const response = yield API.graphql({
        query: getVehicleStockPictures,
        variables: {
          input: {
            parameters: {
              count_records: true,
              page_size,
              page,
              order_by: {
                id: order_by.id,
                desc: order_by.desc,
              },
              search,
              filters: {
                make,
                model,
                year,
                type,
                angle,
                background,
                size,
              },
            },
          },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      return JSON.parse(response.data?.getVehicleStockPictures.body);

    } catch (error) {
      this.isError = true;
    }
  }

  *getVehiclesStockPicturesFilters({ search, filters }) {
    try {
      const response = yield API.graphql({
        query: getVehicleStockFilter,
        variables: {
          input: {
            search: search || "",
            filters: {
              make: filters?.make || [],
              model: filters?.model || [],
              year: filters?.year || [],
              type: filters?.type || [],
              angle: filters?.angle || [],
              background: filters?.background || [],
              size: filters?.size || [],
            },
          },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      return JSON.parse(response.data?.getVehicleStockFilter?.body);
    } catch (error) {
      this.isError = true;
    }
  }

  *getVehicleStockImages({ count_records, page_size, page, search }) {
    try {
      this.isError = false;
      const response = yield API.graphql({
        query: getVehicleStockImagesData,
        variables: {
          input: {
            parameters: {
              count_records: count_records,
              page_size: page_size,
              page: page,
              search: search,
            },
          },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      this.vehicleStockImages = JSON.parse(
        response.data?.getVehicleStockImages?.body
      );
      if (count_records) {
        this.vehicleStockImagesTotalRowCount =
          this.vehicleStockImages.totalRowCount;
      }
    } catch (error) {
      this.vehicleStockImages = {};
    }
  }

  setIsOpenCustomLibraryModal(value) {
    this.isOpenCustomLibraryModal = value;
  }

  clear() {
    this.vehicleStockImages = {};
    this.vehicleStockImagesTotalRowCount = 0;
  }
}