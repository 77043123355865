import React, { useEffect, useState, useCallback, useMemo, useRef } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Amplify, Auth } from "aws-amplify";
import awsconfig from "../../aws-exports";
import { ConciergeChat } from "../../pages/Chatbox/components/ConciergeChat";
import { observer } from "mobx-react";
import { useStore } from "../../hooks";
import { Outlet, useNavigate } from "react-router-dom";
import { Loading } from "../loading";
import { Icon } from "../../elements";
import {
  Wrapper,
  Menu,
  Content,
  Header,
  MenuWrapper,
  TitlePage,
  FloatingChatWrapper,
  CloseConciergeButton,
  SettingsUl,
  LinkContainer,
  SubMenuHeader,
  OpenCloseSidebarWrapper,
  TopMenu,
  TopMenuAccountInfo,
  CustomAccountModalBodyListContainerIcon,
  TopNotification,
  CollapsedLogoWrapper,
  CollapsedCloseWrapper,
  ToogleIconWrapper,
} from "./App.styled";
import { menuItems, settingsItems } from "./consts";
import ModalSubMenu from "./ModalSubMenu";
import CustomAccountModal from './CustomerAccountModal';
import { hideHelpContainer, adjustWidgetPosition, showHelpButton } from '../../elements/SupporButton/supportUtils';
import Popup from "../../elements/v2/Popup";
import Notifications from "../../elements/v2/Notifications";

Amplify.configure(awsconfig);

const theme = createTheme({
  palette: {
    primary: { main: "#3D3D3D" },
    secondary: { main: "#FF5722" },
  }
});

const App = observer(() => {
	const [isMenuCollapsed, setIsMenuCollapsed] = useState(true);
	const [isConciergeCollapsed, setIsConciergeCollapsed] = useState(true);
	const { authStore, title, notificationStore } = useStore();
	const navigate = useNavigate();
	const [isSettingsOpen, setIsSettingsOpen] = useState(false);
	const [openModalIndex, setOpenModalIndex] = useState(null);
	const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
	const [isCustomAccountModalOpen, setIsCustomAccountModalOpen] = useState(false);
	const [customAccountModalItems, setCustomAccountModalItems] = useState([]);
	const [userModalOpen, setUserModalOpen] = useState(false);
	const [observerHelpButton, setObserver] = useState(null);
	const [fullName, setFullName] = useState("");
	const selectedAccountIdRef = useRef(null);

	useEffect(() => {
		if (authStore.userData) {
			const {first_name, last_name} = authStore.userData;
			const fullName = `${first_name} ${last_name}`
			setFullName(fullName);
			
		}
		getNotifications();

	}, [authStore.userData])

	useEffect(() => {
		adjustWidgetPosition();
	}, []);

	useEffect(() => {
		selectedAccountIdRef.current = authStore.selectedAccount?.id;
	}, [authStore.selectedAccount])
	
	const requestToGetNotifications = async (user_id, limit, id) => {
		await notificationStore.getNotificationsRequest({
			customer_id: selectedAccountIdRef.current, user_id, limit, id: null
		});
	}

	const getNotificationInternalRequest = async (user_id, limit) => {
		await authStore.fetchAllUsers()
		await requestToGetNotifications(user_id, limit, null);
	}

	const getNotifications = async () => {
		if (!selectedAccountIdRef.current) return false;
		const user_id = authStore.userData.id;
		const limit = 10;
		await getNotificationInternalRequest(user_id, limit);

		const notificationInterval = setInterval(async () => {
			await requestToGetNotifications(user_id, limit, null)
		}, 300000);

		return () => {
			clearInterval(notificationInterval);
		};	
	}

	useEffect(() => {
		const mutationObserver = new MutationObserver(() => {
		  hideHelpContainer();
		});
	
		setObserver(mutationObserver);
	
		return () => mutationObserver.disconnect();
	  }, []);

	  const observeIframe = useCallback(() => {
		const iframe = document.querySelector('iframe#jsd-widget');
		if (iframe) {
		  const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
		  if (observerHelpButton) {
			observerHelpButton.observe(iframeDocument, { childList: true, subtree: true });
		  }
		}
	  }, [observerHelpButton]);

	  const handleCloseSupportModal = () => {
		hideHelpContainer();
		observeIframe();
	  };

	  useEffect(() => {
		const iframe = document.querySelector('iframe#jsd-widget');
		if (iframe) {
		  hideHelpContainer();
		  observeIframe();
		}
	  }, [observeIframe]);
	
	
	useEffect(() => {
		if (authStore.isLoading) {
			authStore.getGlobalInformation();
		}
	}, [authStore]);
	
	useEffect(() => {
		setIsSettingsOpen(false);
	}, [isMenuCollapsed]);
	
	const toggleMenu = () => {
		setIsMenuCollapsed(!isMenuCollapsed);
	};
	
	const handleSignOut = useCallback(() => {
		Auth.signOut()
			.then((data) => authStore.clear())
			.catch((error) => console.log("error signing out: ", error));
	}, [authStore]);
	
	const getAccountSelector = () => {
		return authStore.sortedAccountListByFavs.map((item) => ({
			text: item.name,
			id: item.id,
			onClick: () => handleAccountSelector(item.id)
		}));
	}
	
	const handleAccountSelector = useCallback(
		(id) => {
			authStore.pickAccount(id);
		},
		[authStore]
	);
	
	const memoizedMenuItems = useMemo(() => {
		return menuItems(navigate).filter(item => authStore.hasPermission(item.permissionCode))
			.map(item => ({...item, customItems: item.customItems.filter(subitem => authStore.hasPermission(subitem.permissionCode)) }));
	}, [navigate, authStore.isGroupSuperAdmin, authStore.isGroupAdmin, authStore.userPermissions.length]);
	

	const handleProfile = () => {
		navigate('/v2/profile')
	}

	const memoizedSettingsItems = useMemo(() => {
		return settingsItems(navigate, handleSignOut, handleProfile);
	}, [navigate, handleSignOut]);
	
	const toggleModal = (index, event) => {
		const rect = event.currentTarget.getBoundingClientRect();
		setModalPosition({ top: rect.top, left: rect.right });
		setUserModalOpen(false);
		setOpenModalIndex(openModalIndex === index ? null : index);
	};
	
	const handleMenuItemClick = (item, index, event) => {
		setUserModalOpen(false);
		if (item.customItems && item.customItems.length > 0) {
			if (openModalIndex === index) {
				setOpenModalIndex(null);
			} else {
				toggleModal(index, event);
			}
		} else if (item.link) {
			navigate(item.link);
		}
	};
	
	const handleCustomAccountModal = (items) => {
		if(authStore.isGroupSuperAdmin){
			setCustomAccountModalItems(items);
			setIsCustomAccountModalOpen(true);
		}
	}
	
	const closeAccountModal = async () => {
		const user_id = authStore.userData.id;
		const limit = 10;
		await getNotificationInternalRequest(user_id, limit);
		setIsCustomAccountModalOpen(false);
	};
	
	const handleCloseModalSubMenu = () => {
		setOpenModalIndex(null);
	};
	
	const handleUserModalOpen = (event) => {
		const rect = event.currentTarget.getBoundingClientRect();
		setModalPosition({ top: rect.bottom, left: rect.left });
		setOpenModalIndex(null);
		setUserModalOpen(true);
	};
	
	const handleUserModalClose = () => {
		setUserModalOpen(false);
	};
	
	const avatarCustomItems = useMemo(() => {
		const avatarItem = memoizedSettingsItems.find(item => item.text === "User");
		return avatarItem ? avatarItem.customItems : [];
	}, [memoizedSettingsItems]);
	
	return authStore.isLoading ? (
		<Loading />
	) : (
		<Wrapper isCollapsed={isMenuCollapsed} title={title} >
			<TopMenu>
				<TopMenuAccountInfo>
					<CustomAccountModalBodyListContainerIcon data-testid="account-selector-icon" isSuperAdmin={authStore.isGroupSuperAdmin} onClick={() => authStore.hasPermission("CUSTOM_ACCOUNT_MODAL_PERMISSION") && handleCustomAccountModal(getAccountSelector())}>
						{`${authStore.selectedAccount.name.split(' ').slice(0, 2).map(word => word[0]).join('')}`}
					</CustomAccountModalBodyListContainerIcon>
					<div>{authStore.selectedAccount.name} </div>
				</TopMenuAccountInfo>
				<TopNotification>
						<SettingsUl>
						{authStore.hasPermission('NOTIFICATIONS_VIEW') && <Popup
								header={<Notifications.Bell data-testid="notifications-icon" />}
								content={<Notifications />}
							/>}
						{memoizedSettingsItems.map((item, index) => (
							(item.superAdminOnly && authStore.isGroupSuperAdmin) || !item.superAdminOnly ? <li key={index}>
								{
									item.text === 'Chat' ? (
										<a onClick={() => setIsConciergeCollapsed(!isConciergeCollapsed)}>
											<Icon 
												name={item.icon} 
												height={"32px"} 
												width={"32px"} 
												dataTestId="chat-icon"
											/>
										</a>) :
									item.text === 'Support' ? (
										<a onClick={showHelpButton}>
											<Icon 
												name="support" 
												height={"32px"} 
												width={"32px"} 
												dataTestId="support-icon"
											/>
										</a>) :
									item.text === "User" ? (
										<a onClick={handleUserModalOpen}
										   onMouseEnter={handleUserModalOpen}>
											<Icon 
												name={item.icon} 
												height={"32px"} 
												width={"32px"} 
												dataTestId="avatar-icon"
											/>
										</a>
									) : <></>
								}
							</li> : <></>
						))}
						
					</SettingsUl>
				</TopNotification>
				
			</TopMenu>
			
			<MenuWrapper isCollapsed={isMenuCollapsed}>
				<Menu isCollapsed={isMenuCollapsed}>
					<>
						<OpenCloseSidebarWrapper isCollapsed={isMenuCollapsed}>
							{ isMenuCollapsed ?
								(
									<ToogleIconWrapper onClick={toggleMenu} >
										<Icon name={"arrowWhiteRight"} width={"11px"} height={"11px"} dataTestId="arrow-white-button" />
									</ToogleIconWrapper>
								) :
								(
									<>
										<CollapsedLogoWrapper  onClick={() => navigate('/home')}>
											<Icon dataTestId="new-logo-white-button" name={"newLogoWhite"} width={"34px"} height={"25px"} />
										</CollapsedLogoWrapper>
										
										<CollapsedCloseWrapper onClick={toggleMenu}>
											<Icon dataTestId="close-white-button" name={"closeWhite"} width={"16px"} height={"16px"}/>
										</CollapsedCloseWrapper>		
									</>
								)
							}	
						</OpenCloseSidebarWrapper>
					</>
					
					<ul>
						{memoizedMenuItems.map((item, index) => (
							<SubMenuHeader
								isMenuCollapsed={isMenuCollapsed}
								onClick={(event) => handleMenuItemClick(item, index, event)}
								onMouseEnter={(event) => toggleModal(index, event)}
							>
								<LinkContainer isMenuCollapsed={isMenuCollapsed}>
									<Icon name={item.icon} height={"20px"} width={"20px"} />
									{!isMenuCollapsed && <div className="link-menu" data-testid={`menu-item-${item.text?.toLowerCase()}`}>{item.text}</div>}
								</LinkContainer>
							</SubMenuHeader>
						))}
					</ul>
				</Menu>
			</MenuWrapper>
			
			{title && (
				<Header showDivision={title}>
					<TitlePage>{title}</TitlePage>
				</Header>
			)}
			
			<Content id="main-content">
				<ThemeProvider theme={theme}>
					<CssBaseline />
					<Outlet />
				</ThemeProvider>
			</Content>
			
			<FloatingChatWrapper isCollapsed={isConciergeCollapsed}>
				{!isConciergeCollapsed && (
					<CloseConciergeButton onClick={() => setIsConciergeCollapsed(!isConciergeCollapsed)}>
						<img 
							src="widget_chat.svg" 
							alt="" 
							data-testid="chat-icon"
						/>
					</CloseConciergeButton>
				)}
				<ConciergeChat isCollapsed={isConciergeCollapsed} onClose={() => setIsConciergeCollapsed(true)}
				/>
			</FloatingChatWrapper>
			
			{openModalIndex !== null && memoizedMenuItems[openModalIndex].customItems.length > 0 && (
				<ModalSubMenu
					position={modalPosition}
					title={memoizedMenuItems[openModalIndex].text}
					items={memoizedMenuItems[openModalIndex].customItems}
					onClose={handleCloseModalSubMenu} />
			)}
			
			{isCustomAccountModalOpen && (
				<CustomAccountModal 
					items={customAccountModalItems} 
					onClose={closeAccountModal} 
				/>
			)}
			
			{userModalOpen &&(
				<ModalSubMenu
					position={modalPosition}
					title={fullName}
					subtitle={authStore.userData.email}
					items={avatarCustomItems}
					onClose={handleUserModalClose}
					avatar={true}
				/>
			)}
		</Wrapper>
	);
});

export default App;
