import React, { useState } from "react";
import {
  StyledDialog,
  StyledTextField,
  StyledDialogTitle,
  StyledButton,
  AddIconButton,
  AddButtonWrapper,
  DeleteIconButton,
  DomainFieldsContainer,
  DomainFieldWrapper,
  StyledDialogContent,
  StyledDialogActions,
  StyledSelect,
  StyledLoadingButton
} from "./EditCustomerModal.styled";
import { Icon } from "../../../../elements/";
import states from "../../../../components/common/USstates";
import { FIELD_VALIDATIONS } from "../../../../utils/enums";
import CloseIcon from "@mui/icons-material/Close";
import { FormControl, InputLabel, MenuItem } from "@mui/material";
import { useCustomerForm } from "./hooks/useCustomerForm";
import { useStore } from "../../../../hooks";
import { observer } from "mobx-react";

const EditCustomerModal = observer(({ open, onClose, customerId }) => {
  const { customer } = useStore();
  const [isSaving, setIsSaving] = useState(false);
  const selectedCustomer = customer.customers.find(c => c.id === customerId);

  const {
    editedCustomer,
    setEditedCustomer,
    domainFields,
    setDomainFields,
    resetForm,
  } = useCustomerForm(selectedCustomer);

  const handleCancel = () => {
    resetForm();
    onClose();
  };

  const handleChange = (field, value) => {
    setEditedCustomer((prev) => ({ ...prev, [field]: value }));
  };

  const handleAddDomainField = () => {
    setDomainFields((prev) => [...prev, { id: prev.length + 1, value: "" }]);
  };

  const handleDomainChange = (id, value) => {
    const updatedFields = domainFields.map((field) =>
      field.id === id ? { ...field, value } : field
    );
    setDomainFields(updatedFields);
  };

  const handleSaveDomains = () => {
    const validDomains = domainFields
      .map((field) => field.value.trim())
      .filter((domain) => domain !== "");

    setEditedCustomer((prev) => ({
      ...prev,
      domains: validDomains,
    }));
  };

  const handleDeleteDomainField = (idToDelete) => {
    setDomainFields((prev) => {
      const updatedFields = prev
        .filter((field) => field.id !== idToDelete)
        .map((field, index) => ({
          ...field,
          id: index + 1,
        }));
      return updatedFields;
    });
    handleSaveDomains();
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setIsSaving(true);

    const validDomains = domainFields
      .map((field) => field.value.trim())
      .filter((domain) => domain !== "");

    const updatedCustomer = {
      id: selectedCustomer.id,
      name: editedCustomer.name,
      state: editedCustomer.state,
      city: editedCustomer.city,
      street: editedCustomer.street,
      site_url: editedCustomer.site_url || selectedCustomer.site_url,
      zip_code: editedCustomer.zip_code,
      domains: validDomains,
      metadata: JSON.stringify(selectedCustomer.metadata),
      default_email: editedCustomer.default_email,
    };

    customer.updateCustomer(updatedCustomer);
    handleCancel();
    setIsSaving(false);
  };

  return (
    <StyledDialog open={open} onClose={handleCancel} maxWidth="sm" fullWidth>
      <StyledDialogTitle>
        <span>Edit</span>
        <div onClick={handleCancel}>
          <CloseIcon />
        </div>
      </StyledDialogTitle>
      <form onSubmit={handleSave}>
        <StyledDialogContent>
          <StyledTextField
            label="Company Name"
            value={editedCustomer.name}
            onChange={(e) => handleChange("name", e.target.value)}
            fullWidth
            size="small"
            margin="normal"
          />
          <StyledTextField
            label="Address"
            value={editedCustomer.street}
            onChange={(e) => handleChange("street", e.target.value)}
            fullWidth
            margin="normal"
            size="small"
          />
          <StyledTextField
            label="City"
            value={editedCustomer.city}
            onChange={(e) => handleChange("city", e.target.value)}
            fullWidth
            margin="normal"
            size="small"
          />
          <FormControl fullWidth margin="normal" size="small">
            <InputLabel>State</InputLabel>
            <StyledSelect
              label="State"
              value={editedCustomer.state}
              onChange={(e) => handleChange("state", e.target.value)}
              data-testid="state-select"
            >
              {states.map((state) => (
                <MenuItem key={state.value} value={state.name}>
                  {state.name}
                </MenuItem>
              ))}
            </StyledSelect>
          </FormControl>
          <StyledTextField
            label="Postal Code"
            value={editedCustomer.zip_code}
            onChange={(e) => handleChange("zip_code", e.target.value)}
            fullWidth
            margin="normal"
            size="small"
            inputProps={{
              pattern: FIELD_VALIDATIONS.postal_code.string_pattern,
            }}
            error={
              !FIELD_VALIDATIONS.postal_code.pattern.test(
                editedCustomer.zip_code,
              )
            }
            helperText={
              !FIELD_VALIDATIONS.postal_code.pattern.test(
                editedCustomer.zip_code,
              )
                ? FIELD_VALIDATIONS.postal_code.error
                : ""
            }
          />
          <DomainFieldsContainer>
            {domainFields.map((field) => (
              <DomainFieldWrapper key={field.id}>
                <StyledTextField
                  label={field.id === 1 ? `Domain *` : `Domain ${field.id}`}
                  value={field.value}
                  onChange={(e) => handleDomainChange(field.id, e.target.value)}
                  fullWidth
                  size="small"
                  margin="normal"
                  onBlur={handleSaveDomains}
                />
                {domainFields.length > 1 && (
                  <DeleteIconButton
                    type="button"
                    onClick={() => handleDeleteDomainField(field.id)}
                  >
                    <Icon name="circleRemove" height="20px" width="20px" />
                  </DeleteIconButton>
                )}
              </DomainFieldWrapper>
            ))}
          </DomainFieldsContainer>
          <AddButtonWrapper>
            <AddIconButton
              type="button"
              data-testid="add-domain-button"
              onClick={handleAddDomainField}
            >
              <Icon name="circleAdd" height="25px" width="25px" />
            </AddIconButton>
          </AddButtonWrapper>
          <StyledTextField
            label="Default Email"
            value={editedCustomer.default_email}
            onChange={(e) => handleChange("default_email", e.target.value)}
            fullWidth
            margin="normal"
            size="small"
            inputProps={{ pattern: FIELD_VALIDATIONS.email.string_pattern }}
            error={
              !FIELD_VALIDATIONS.email.pattern.test(
                editedCustomer.default_email,
              )
            }
            helperText={
              !FIELD_VALIDATIONS.email.pattern.test(
                editedCustomer.default_email,
              )
                ? FIELD_VALIDATIONS.email.error
                : ""
            }
            required
          />
        </StyledDialogContent>
        <StyledDialogActions>
          <StyledButton
            fullWidth
            disabled={isSaving}
            onClick={handleCancel}
            type="button"
          >
            Cancel
          </StyledButton>
          <StyledLoadingButton
            fullWidth
            type="submit"
            variant="contained"
            loading={isSaving}
          >
            Save
          </StyledLoadingButton>
        </StyledDialogActions>
      </form>
    </StyledDialog>
  );
});

export default EditCustomerModal;
