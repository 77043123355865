export const getColor = (status) => {
  switch (status) {
    case "Completed":
      return "#0A7A62";
    case "Pending":
      return "#F24822";
    default:
      return "gray";
  }
};

export const getBackgroundColor = (status) => {
  switch (status) {
    case "Completed":
      return "#C5DDD8";
    case "Pending":
      return "#FFEAE5";
    default:
      return "white";
  }
};

export const getFormControlStyles = () => ({
  minWidth: 140,
  height: 28,
  "& .MuiOutlinedInput-root": {
    padding: 0,
    height: 28,
    display: "flex",
    alignItems: "center",
  },
});

export const getSelectStyles = (value) => ({
  backgroundColor: getBackgroundColor(value),
  color: getColor(value),
  fontWeight: "bold",
  fontSize: "0.8rem",
  height: 28,
  lineHeight: "1",
  borderRadius: 2,
  "&:focus": {
    outline: "none",
    border: "none",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "& .MuiSelect-icon": {
    color: "gray",
    fontSize: "24px",
  },
});

export const getCircleIconStyles = (status) => ({
  color: getColor(status),
  fontSize: "0.6rem",
});

export const getTypographyStyles = () => ({
  fontWeight: "bold",
  fontSize: "12px",
});

export const getMenuTypographyStyles = () => ({
  fontSize: "12px",
});
