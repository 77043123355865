import {DefaultFieldVariables, DefaultVariables, OriginVariables} from "../../../utils/enums";
import {action, computed, makeObservable, observable} from "mobx";

class VariableStore {
  id = null;
  name = "";
  variable = "";
  isDefault = false;
  isOpened = false;
  origin = OriginVariables.FIXED;
  value = "";
  source = null;
  field = null;
  _value: {};

  constructor() {
    makeObservable(this, {
      id: observable,
      name: observable,
      variable: observable,
      isDefault: observable,
      isOpened: observable,
      origin: observable,
      value: observable,
      source: observable,
      field: observable,
      updateField: action,
      setFields: action,
    });
  }

  setFields(data) {
    this.id = data.id;
    this.name = data.name;
    this.variable = data.variable;
    this.isDefault = data.isDefault;
    this.origin = data.origin;
    this.value = data.value;
    this.source = data.source;
    this.field = data.field;

    this._value = {
      source: data.source,
      field: data.field
    };
  }

  updateField(field, value) {
    this[field] = value;
  }
}

export default class TemplateEditorVariablesStore {
  variables = [];
  sources = [];

  constructor() {
    makeObservable(this, {
      variables: observable,
      sources: observable,
      filteredSources: computed,
      getFilteredFields: action,
      setDefaultVariables: action,
      addVariableDefault: action,
      addVariable: action,
      setSources: action,
      setVariables: action,
      deleteVariable: action,
      updateFieldVariable: action,
      getVariable: action,
      clean: action,
    });
  }

  setSources(sources) {
    this.sources = sources;
  }

  setVariables(variables) {
    this.variables = variables;
  }

  setDefaultVariables() {
    const existingVariables = new Set(this.variables.map(v => v.variable));

    for (let i = 0; i < DefaultVariables.length; i++) {
      const {variable, isDefault, name, field, source, origin} = DefaultVariables[i];

      if (!existingVariables.has(variable)) {
        if (variable === DefaultFieldVariables.CUSTOMER_NAME || variable === DefaultFieldVariables.TO) {
          if (!source) {
            const sourceWithMatchField = this.sources.find(source =>
              source.fields.some(fieldData => fieldData.field === field)
            );

            if (sourceWithMatchField) {
              const fieldFound = sourceWithMatchField.fields.find(f => f.field === field);
              this.addVariableDefault({
                name,
                variable,
                isDefault,
                origin,
                source: sourceWithMatchField.source,
                field: fieldFound
              });
            } else {
              this.addVariableDefault({
                name,
                isDefault,
                variable,
                origin,
              });
            }
          } else {
            this.addVariableDefault({
              name,
              variable,
              isDefault,
              origin,
              source,
              field,
            });
          }
        } else {
          this.addVariableDefault({
            name,
            isDefault,
            variable,
            origin,
          });
        }
      }
    }
  }

  addVariableDefault(dependencies) {
    const {name, variable, origin, isDefault, source = null, field = null} = dependencies;
    if (!name || !variable || !origin) {
      return;
    }
    this.addVariable({name, variable, isDefault, origin, source, field});
  }

  addVariable({
                name = "",
                variable = "",
                isDefault = false,
                origin = OriginVariables.FIXED,
                value = "",
                source = null,
                field = null
              }) {

    const newVariable = new VariableStore();
    newVariable.setFields({
      id: this.variables.length + 1,
      name,
      variable,
      isDefault,
      origin,
      value,
      source,
      field,
    });

    this.variables.push(newVariable);
  }

  getVariable(id) {
    return this.variables[id];
  }

  deleteVariable(id) {
    this.variables.splice(id, 1);
  }

  updateFieldVariable(field, id, value) {
    this.variables[id].updateField(field, value);
  }

  get filteredSources() {
    return this.sources.map((source) => ({name: source.origin, value: source.origin}));
  }

  getFilteredFields(source) {
    if (this.sources && this.sources.length > 0) {
      const selectedOrigin = this.sources.find((field) => field.origin === source);
      if (selectedOrigin) {
        return selectedOrigin.fields?.map((field) => ({
          name: field.name,
          value: field.field,
          field_type: field.field_type,
          operators: field.operators,
          default_values: field.default_values
        }));
      }
    }
    return [];
  }

  clean() {
    this.variables = [
      {
        name: "",
        variable: "",
        origin: OriginVariables.FIXED,
        value: "",
      },
    ];
  }
}