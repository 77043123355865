import InputText from '../../../../TextInput/TextInput';
import {
  VariableWrapper,
  VariableContainer,
  VariableHeader,
  VariableBody,
  VariableSelects
} from './Variable.styled';
import {CustomSelect, IconButton} from '../../../../../index'
import {useCallback, useEffect, useMemo, useState} from 'react';
import {OriginVariables} from "../../../../../../utils/enums";
import {observer} from "mobx-react";
import {useTemplateEditorStore} from "../../../hook";

const Variable = observer(({id}) => {
  const { variablesEditorStore } = useTemplateEditorStore();
  const { filteredSources } = variablesEditorStore;
  const currentVariable = variablesEditorStore.getVariable(id);

  return (
    <VariableWrapper>
      <IconButton
        icon={!currentVariable.isOpened ? 'arrowDown' : 'arrowUp'}
        width='12px'
        height='12px'
        onClick={() => currentVariable.updateField('isOpened', !currentVariable.isOpened)}
      />
      <VariableContainer>
        <VariableHeader>
          <InputText
            placeholder="Name Tag"
            width="90%"
            value={currentVariable.name}
            disabled={currentVariable.isDefault}
            onChange={(e) => currentVariable.updateField('name', e.target.value)}
          />
          <InputText
            placeholder="Internal Value"
            width="90%"
            value={currentVariable.variable}
            disabled={currentVariable.isDefault}
            onChange={(e) => currentVariable.updateField('variable', e.target.value)}
          />
          <span>
            <IconButton
              icon={'coloredTrashcan'}
              disabled={currentVariable.isDefault}
              width={'15px'}
              onClick={() => variablesEditorStore.deleteVariable(id)}
            />
          </span>
        </VariableHeader>
        {currentVariable.isOpened && <VariableBody>
          <div>
            <CustomSelect
              placeholder="Select type"
              width='90%'
              searchBar={false}
              disabled={currentVariable.isDefault}
              options={[
                {name: 'Fixed', value: OriginVariables.FIXED},
                {name: 'Event', value: OriginVariables.EVENT},
              ]}
              handleSelect={(_, value) => currentVariable.updateField('origin', value)}
              selectedDefault={currentVariable.origin}
            />
          </div>
          {
            currentVariable.origin === OriginVariables.FIXED &&
            (
              <InputText
                placeholder="Value"
                width="90%"
                value={currentVariable.value}
                onChange={(e) => currentVariable.updateField('value', e.target.value)}
              />
            )
          }
          {currentVariable.origin === OriginVariables.EVENT &&
            (
              <VariableSelects>
                {/* Options in the selects below are intended to be hardcoded */}
                <CustomSelect
                  width='45%'
                  placeholder='Source'
                  searchBar={false}
                  options={filteredSources}
                  handleSelect={(_, value) => currentVariable.updateField('source', value)}
                  selectedDefault={currentVariable.source}
                />
                <CustomSelect
                  width='45%'
                  searchBar={false}
                  placeholder='Field'
                  options={variablesEditorStore.getFilteredFields(currentVariable.source)}
                  handleSelect={(_, value) => currentVariable.updateField('field', value)}
                  selectedDefault={currentVariable.field}
                />
              </VariableSelects>
            )
          }
        </VariableBody>}
      </VariableContainer>
    </VariableWrapper>
  );
});

export default Variable