import styled from "styled-components";
import {
  Dialog,
  TextField,
  DialogTitle,
  Button,
  Box,
  DialogContent,
  DialogActions,
  Select,
} from "@mui/material";
import { LoadingButton } from "./LoadingButton";

const StyledDialog = styled(Dialog)`
  & .MuiDialog-paper {
    background: ${(props) => props.theme.colors.highClearGray};
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 450px;
    scrollbar-width: thin;
  }

  & .MuiDialogContent-root {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  & .MuiDialogActions-root {
    padding: 16px;
    justify-content: center;
    gap: 16px;
  }
`;

const StyledTextField = styled(TextField)`
  & .MuiInputBase-root {
    background: white;
  }

  & .MuiInputLabel-root {
    font-size: 0.875rem;
  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  display: flex;
  justify-content: space-between;
  padding: 7px 19px;
  font-size: 0.875rem;

  & > div:last-child {
    cursor: pointer;
  }
`;

const AddButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const AddIconButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid ${(props) => props.theme.colors.cottonSeed};
  border-radius: 8px;
  cursor: pointer;
  width: 43px;
  height: 43px;
`;

const StyledButton = styled(Button)`
  &.MuiButton-root {
    background-color: transparent;
    color: ${(props) => props.theme.colors.black};
    border: 1px solid ${(props) => props.theme.colors.black};
    cursor: ${(props) => props.disable ? "default" : "pointer"};
  }
`;

const StyledLoadingButton = styled(LoadingButton)`
  &.MuiButton-root {
    background-color: ${(props) => props.theme.colors.black};
    color: ${(props) => props.theme.colors.white};
    border: 1px solid ${(props) => props.theme.colors.black};
    cursor: ${(props) => props.disable ? "default" : "pointer"};
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
  }
`;

const DeleteIconButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid ${(props) => props.theme.colors.cottonSeed};
  border-radius: 8px;
  cursor: pointer;
  width: 44px;
  height: 40px;
  margin-top: 8px;
`;

const DomainFieldsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const DomainFieldWrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StyledDialogActions = styled(DialogActions)`
  padding: 16px;
  justify-content: center;
  gap: 16px;
`;

const StyledSelect = styled(Select)`
  && {
    height: 40px;
    background-color: white;
  }
`;

export {
  StyledDialog,
  StyledTextField,
  StyledDialogTitle,
  AddIconButton,
  AddButtonWrapper,
  StyledButton,
  DeleteIconButton,
  DomainFieldsContainer,
  DomainFieldWrapper,
  StyledDialogContent,
  StyledDialogActions,
  StyledSelect,
  StyledLoadingButton,
};
