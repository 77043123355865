import {action, makeObservable, observable} from "mobx";

export default class TemplateEditorFormStore {
  from = null;
  senderName = null;
  to = null;
  subject = null;
  cc = null;
  bcc = null;
  description = null;

  constructor() {
    makeObservable(this, {
      from: observable,
      senderName: observable,
      to: observable,
      subject: observable,
      cc: observable,
      bcc: observable,
      description: observable,
      updateField: action,
      clean: action,
    });
  }

  updateField(field, value) {
    this[field] = value;
  }

  clean() {
    this.from = undefined;
    this.senderName = undefined;
    this.to = undefined;
    this.subject = undefined;
    this.cc = undefined;
    this.bcc = undefined;
    this.description = undefined;
  }
}