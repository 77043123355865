import styled from "styled-components";
import { FormControl, InputLabel, Select } from "@mui/material";

const getFormControlStyles = () => ({
  minWidth: 100,
  maxWidth: 150,
  borderRadius: "8px",
  boxShadow: "none",
  border: "none",
});

const getInputLabelStyles = () => ({
  fontSize: "0.9rem",
  color: "black",
});

const getSelectStyles = () => ({
  height: 40,
  borderRadius: "8px",
  boxShadow: "none",
  "& .MuiSelect-select": {
    padding: "8px",
  },
});

const getMenuPropsStyles = () => ({
  PaperProps: {
    sx: {
      marginTop: "30px",
      boxShadow: "none",
      maxHeight: 200,
      overflowY: "auto",
    },
  },
});

export const StyledFormControl = styled(FormControl).attrs(() => ({
  sx: getFormControlStyles(),
}))``;

export const StyledInputLabel = styled(InputLabel).attrs(() => ({
  sx: getInputLabelStyles(),
}))``;

export const StyledSelect = styled(Select).attrs(() => ({
  sx: getSelectStyles(),
}))``;

export const StyledMenuProps = getMenuPropsStyles();
