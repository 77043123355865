import { 
    InputWrapper, 
    TabContainer,
    ButtonContiner,
    ButtonWrapper
} from './Tabs.styled';
import { Variable } from './component';
import RoundedIconButton from '../../../Buttons/RoundedIconButton/RoundedIconButton';
import { RoundedButton } from '../../../../button';
import {useCallback} from "react";
import {observer} from "mobx-react";
import {useTemplateEditorStore} from "../../hook";

const VariablesTab = observer(() => {
  const { variablesEditorStore } = useTemplateEditorStore();
  const { variables } = variablesEditorStore;

  const addVariable = useCallback(() => {
    variablesEditorStore.addVariable({
      isDefault: false,
      name: '',
      variable: '',
      value: '',
      origin: '',
      source: '',
      field: ''
    })
  }, [variablesEditorStore]);

  const onDeleteVariable = useCallback((id) => {
    variablesEditorStore.deleteVariable(id);
  }, [variablesEditorStore]);

  const onChangeVariable = useCallback((field, id, value) => {
    variablesEditorStore.updateFieldVariable(field, id, value);
  }, [variablesEditorStore]);

  return (
    <TabContainer>
      <h1>Variable</h1>
      <ButtonContiner>
        <RoundedIconButton width='24px' height='24px' icon='circleAdd' onClick={addVariable}/>
      </ButtonContiner>
      <InputWrapper>
        {variables.map((data, idx) =>
          (<Variable key={idx} id={idx} />)
        )}
      </InputWrapper>
    </TabContainer>
  );
});

export default VariablesTab