import InputText from '../../../TextInput/TextInput';
import TextArea from '../../../TextArea/TextArea';
import {Subtitle, InputWrapper, TabContainer} from './Tabs.styled';
import {observer} from "mobx-react";
import {useTemplateEditorStore} from "../../hook";

const GeneralTab = observer(() => {
  const {requiredFieldsError, formEditorStore} = useTemplateEditorStore();
  const {from, senderName, to, subject, cc, bcc, description} = formEditorStore;

  return (
    <TabContainer>
      <h1>General</h1>
      <InputWrapper>
        <Subtitle>From*</Subtitle>
        <InputText
          placeholder="From"
          value={from}
          displayError={requiredFieldsError && !from}
          validationError={"From is required for sending this message."}
          onChange={(e) => formEditorStore.updateField('from', e.target.value)}
        />
      </InputWrapper>

      <InputWrapper>
        <Subtitle>Sender Name*</Subtitle>
        <InputText
          placeholder="Please enter your name"
          value={senderName}
          displayError={requiredFieldsError && !senderName}
          validationError={"Sender name is required for sending this message."}
          onChange={(e) => formEditorStore.updateField('senderName', e.target.value)}
        />
      </InputWrapper>

      <InputWrapper>
        <Subtitle>To</Subtitle>
        <InputText
          placeholder="To"
          value={to}
          disabled={true}
          onChange={(e) => formEditorStore.updateField('to', e.target.value)}
        />
      </InputWrapper>

      <InputWrapper>
        <Subtitle>Subject*</Subtitle>
        <InputText
          placeholder="Subject"
          value={subject}
          displayError={requiredFieldsError && !subject}
          validationError={"Subject is required for sending this message."}
          onChange={(e) => formEditorStore.updateField('subject', e.target.value)}
        />
      </InputWrapper>

      <InputWrapper>
        <Subtitle>CC</Subtitle>
        <InputText
          placeholder="CC"
          value={cc}
          onChange={(e) => formEditorStore.updateField('cc', e.target.value)}
        />
      </InputWrapper>

      <InputWrapper>
        <Subtitle>BCC</Subtitle>
        <InputText
          placeholder="BCC"
          value={bcc}
          onChange={(e) => formEditorStore.updateField('bcc', e.target.value)}
        />
      </InputWrapper>

      <InputWrapper>
        <Subtitle>Description (optional)</Subtitle>
        <TextArea
          placeholder="Enter a description"
          height="120px"
          resize="none"
          value={description}
          onChange={(e) => formEditorStore.updateField('description', e.target.value)}
        />
      </InputWrapper>
    </TabContainer>
  );
});

export default GeneralTab