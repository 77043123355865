export const useTabs = () => {
  return [
    {
      label: "Triggers",
      permission: { code: "CONVERSATIONS_CREATE", level: "c"},
    },
    {
      label: "Marketing",
      permission: { code: "CONVERSATIONS_CREATE", level: "c"},
    },
  ];
};
