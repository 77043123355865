import EmailEditor from "react-email-editor";
import {templateEditor} from "../../../../../components/app/consts";
import {EmailEditorWrapper} from "./EditorUnlayer.styled";
import {toJS} from "mobx";
import {useEffect, useRef} from "react";
import {useTemplateEditorStore} from "../../hook";
import {observer} from "mobx-react";

const EditorUnlayer = observer(() => {
  const emailEditorRef = useRef(null);
  const store = useTemplateEditorStore();

  useEffect(() => {
    if (store.variablesEditorStore.variables.length && emailEditorRef.current?.editor) {
      const variablesToSet = {};
      for (const variable of store.variablesEditorStore.variables) {
        variablesToSet[variable.variable] = {
          name: variable.name,
          value: `{{${variable.variable}}}`,
          sample: `{{${variable.variable}}}`,
        };
      }

      emailEditorRef.current.editor.setMergeTags(variablesToSet);
    }
  }, [store.variablesEditorStore, store.variablesEditorStore.variables]);

  const exportHtml = () => {
    const unlayer = emailEditorRef.current?.editor;
    unlayer?.exportHtml((data) => {
      const { design, html } = data;

      store.updateField('html', html);
      store.updateField('design', design);
    });
  };

  const onReady = (unlayerRef) => {
    unlayerRef.addEventListener('design:updated', exportHtml);
    unlayerRef.addEventListener('design:updated:paste', exportHtml);

    if (store.design) {
      unlayerRef.loadDesign(toJS(store.design));
    } else if (store.text) {
      const safeDesign = {
        body: {
          rows: [{
            cells: [1],
            columns: [{
              contents: [{
                type: "text",
                values: {
                  text: store.text,
                },
              }],
            }],
          }],
          values: {
            backgroundColor: "#ffffff",
            width: "600px",
            padding: "20px",
          },
        },
      };

      unlayerRef.loadDesign(safeDesign);
    }

    unlayerRef.registerCallback("selectImage", (data, done) => {
      store.imagePickerStore.setOnPickImage(done);
      store.imagePickerStore.setIsOpenCustomLibraryModal(true);
    });

    unlayerRef.registerProvider("userUploads", async (params, done) => {
      const page = params.page || 1;
      const perPage = params.perPage || 20;
      const isCount = page === 1;
      const searchText = params.searchText;

      const payload = {
        count_records: isCount,
        page_size: perPage,
        page,
        search: searchText,
      };

      const { stockImages, hasMore, total } = await store.imagePickerStore.onSearchImagesStock(payload);

      done(toJS(stockImages), {
        hasMore,
        page,
        perPage,
        total,
      });
    });
  };

  return (
    <EmailEditorWrapper>
      <EmailEditor
        onReady={(unlayerRef) => onReady(unlayerRef)}
        ref={emailEditorRef}
        options={templateEditor.options}
      />
    </EmailEditorWrapper>
  );
});

export default EditorUnlayer;
