import React from "react";
import GenericActionCard from "./GenericActionCard";
import { observer } from "mobx-react";
import { useStore } from "../../../../../../../hooks";
import { v4 as uuidv4 } from "uuid";

const ActionLibraryCards = observer(({ options, openModalAddAction }) => {
  const { viewConversationStore } = useStore();
  const { selectedAction } = viewConversationStore.conversationsActionsStore;
  const { messageEditorStore } = useStore();

  const handleOnClick = (options) => {
    const id = uuidv4();
    selectedAction.id = id;
    selectedAction.action = options.action;
    selectedAction.name = options.name;
    selectedAction.description = options.description;
    messageEditorStore.setId(id);

    options.id = id;
    const completedAction = {
      ...selectedAction,
      ...options,
    };

    viewConversationStore.conversationsActionsStore.addNewAction(
      completedAction
    );
    viewConversationStore.updateChangesDetected(true);
    openModalAddAction(completedAction);
  };

  const customActions = [
    { iconName: "circleAdd", onClick: () => handleOnClick(options) },
  ];

  return (
    <GenericActionCard
      options={options}
      openModalAddAction={openModalAddAction}
      customActions={customActions}
    />
  );
});

export default ActionLibraryCards;
