import styled, { css } from "styled-components";

export const ModalContainer = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
`

export const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 70%;
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    height: 600px;

    & p {
        font-weight: bold;
    }
`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 1;

  ${(props) =>
    props.isOpen &&
    css`
      display: flex;
    `}
`;

export const TemplateEditorWrapper = styled.section`
  background-color: ${(props) => props.theme.colors.white};
  height: calc(100% - 40px);
  display: flex;
  width: 85%;
  flex-direction: column;
  justify-content: space-between;
`;

export const TemplateEditorContainer = styled.div`
  min-height: 90%;
  flex: 1;
  display: flex;
`;

export const TemplateEditorFooter = styled.div`
  display: flex;
  height: 10%;
  max-height: 72px;
  border: 1px solid ${(props) => props.theme.colors.clearGray};
  gap: 10px;
  justify-content: flex-end;
  align-items: center;
  padding: 0 16px;
`;

export const ButtonWrapper = styled.div`
  float: right;
  button {
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.clearBlue};
    font-weight: 400;
  }
`;
