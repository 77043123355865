import { action, flow, makeObservable, observable } from "mobx";
import { API } from "@aws-amplify/api";
import {getAlert, putAlert } from "../../graphql/queries";

class AlertStore {
  alerts = [];
  filteredAlerts = [];
  totalRowCount = 0;
  isLoading = false;
  isError = false;
  isSuccessful = false;
  currentPage = 1;
  pageSize = 10;

  //Notification UCP

  lastEvaluatedKey = "";
  segmentId = "";
  alertNotification = { items: [] };
  userId = "";
  accountId = "";


  constructor() {
    makeObservable(this, {
      alerts: observable,
      filteredAlerts: observable,
      totalRowCount: observable,
      isLoading: observable,
      isError: observable,
      currentPage: observable,
      pageSize: observable,
      isSuccessful: observable,
      fetchAllAlerts: flow,
      getAlerts: flow,
      updateAlert: flow,
      setError: action,
      onClearError: action,
      setCompleted: action,
      setResolved: action,
      setFilteredAlerts: action,

      //Notifications UCP

      alertNotification: observable,
      setSegmentId: action,
      setUserId: action,
      setAccountId: action,
      onClearUCP: action,
      fetchNotificationsUCP: flow,
    });
  }

  *fetchAllAlerts(customerId, assignee, status, last_evaluated_key) {
    this.isLoading = true;
    this.isError = false;
    try {
      const response = yield API.graphql({
        query: getAlert,
        variables: {
          input: {
            customer_id: customerId,
            assignee: assignee,
            status: status,
            last_evaluated_key: last_evaluated_key || null
          },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      return JSON.parse(response.data?.getAlert?.body);

    } catch (error) {
      this.setError(true);
    } finally {
      this.isLoading = false;
    }
  }

  *getAlerts({ customerId, assignee, status, pageIndex = 0, pageSize = 10, sortBy, search, filters }) {
    this.isLoading = true;
    this.isError = false;

    try {
      const data =  yield this.fetchAllAlerts(customerId, filters.assignee, status);
      let filteredAlerts = data.items || [];
      if (filters?.status?.length) {
        filteredAlerts = filteredAlerts.filter((alert) =>
          filters.status.includes(alert.status)
        );
      }
      this.alerts = filteredAlerts.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize);
      this.filteredAlerts = this.alerts;
      this.totalRowCount = filteredAlerts.length;
    } catch (error) {
      this.setError(true);
    } finally {
      this.isLoading = false;
    }
  }

  setError(errorState) {
    this.isError = errorState;
  }

  setFilteredAlerts(newAlerts) {
    this.filteredAlerts = newAlerts;
  }

  onClearError() {
    this.isError = false;
  }
  
  setCompleted(id) {
    const alert = this.alerts.find((item) => item.id === id);
    if (alert) {
      alert.status = "resolved";
      this.filteredAlerts = [...this.alerts];
    }
  }
  
  setResolved(id) {
    const alert = this.alerts.find((item) => item.id === id);
    if (alert) {
      alert.status = "resolved";
      this.filteredAlerts = [...this.alerts];
    }
  }
  
  /**
   * Updates the status of an alert in the database.
   * @param {string} alert_id - the id of the alert to be updated
   * @param {string} customer_id - the id of the customer that owns the alert
   * @param {string} status - the new status of the alert
   * @returns {Promise<void>}
   */
  *updateAlert(alert_id, customer_id, status, assignee) {
    try {
      this.isLoading = true;
      
      yield API.graphql({
        query: putAlert,
        variables: {
          input: {
            alert_id,
            customer_id,
            status,
            solved_at: new Date(),
            assignee
          },
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
  
      this.filteredAlerts = this.filteredAlerts.map((alert) =>
        alert.id === alert_id ? { ...alert, assignee } : alert
      );
  
      this.isSuccessful = true;
    } catch (error) {
      this.isError = true;
    } finally {
      this.isLoading = false;
    }
  }

  *fetchNotificationsUCP(customer_id, assignee, status, last_evaluated_key) {
    try {
      this.isLoading = true;
      const res = yield this.fetchAllAlerts(customer_id, assignee, status, last_evaluated_key);

      const filteredItems = res.items.filter(
        (item) => item.payload?.contact_id === this.segmentId,
      );

      this.alertNotification = {
        ...res,
        items: [...this.alertNotification.items, ...filteredItems],
      };
  
      this.lastEvaluatedKey = res.next_page;

    } catch (error) {
      this.setError(true);
    } finally {
      this.isLoading = false;
    }
  }

  onClearUCP() {
    this.alertNotification = { items: [] };
    this.initialLoad = false;
    this.isError = false;
    this.lastEvaluatedKey = null;
  }

  setSegmentId = (id) => {
    this.segmentId = id;
  };

  setUserId = (id) => {
    this.userId = id;
  };

  setAccountId = (accountId) => {
    this.accountId = accountId;
  };

}

export default AlertStore;
