import styled from "styled-components";

const SideBar1 = styled.div`
    background: ${props => props.theme.colors.silverGrayThree};
    transition: width 0.3s ease, transform 0.3s ease;
    width: ${props => props.isExpanded ? '298px' : '87px'};
    overflow: hidden;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: calc(100vh - 48px - 1.75rem);
		max-height: calc(100vh - 48px - 1.75rem);


	@media (max-width: 1024px) {
        position: fixed;
        top: 50px;
        left: 0;
        bottom: 0;
        z-index: 1000;
        transform: translateX(${props => props.isExpanded ? '0' : '-100%'});
        width: ${props => props.isExpanded ? '280px' : '60px'};
    }

    & div > div > div {
        font-weight: bold;
        margin-bottom: 0;
        font-size: 0.875rem;
    }
    & div > div > img {
        cursor: pointer;
    }
`;

const CollapseContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: ${props => props.isExpanded ? 'flex-start' : 'center'};
    align-items: center;
    cursor: pointer;
    
`;

const ListOfAgentContainer = styled.div`
	display:flex;
    justify-content: space-around;
	width: 100%;
	flex-grow: 1;
    max-height: calc(100svh - 181px);

    @media (max-width: 768px) {
        max-height: calc(100svh - 150px);
    }
`;

const AgentListContainer = styled.div`
    padding: 15px;
    height: ${props => props.isExpanded ? `calc(100svh - 188px)` : `calc(100svh - 112px)`};
	overflow-y: auto;
	scrollbar-width: thin;
	scroll-margin-left: 8px;
	scrollbar-color: ${props => props.theme.colors.thumbGray} ${props => props.theme.colors.highClearGray};

    @media (max-width: 768px) {
        height: ${props => props.isExpanded ? `calc(100svh - 160px)` : `calc(100svh - 100px)`};
    }
`;

const AgentListCard = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: stretch;
    align-items: center; /*Temporary to center while favorites is ready otherwise the value is flex-start*/
    width: 100%;
    height: 48px;
    margin-bottom: 15px;
    gap: 8px;
    cursor: pointer;
	
	& div:nth-child(2){
		gap: 0;
	}
`;

const AgentAvatar = styled.div`
    order: 0;
    flex: 0 1 auto;
    align-self: auto;
    display: flex;
	max-width: 32px;
	max-height: 32px;
`;

const AgentData = styled.div`
    order: 0;
    flex: 1 1 auto;
    align-self: auto;
    display: flex;
    flex-direction: column;
    gap:8px;
`;

const AgentFavorites = styled.div`
    order: 0;
    flex: 1 1 auto;
    align-self: auto;
    display:flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 14px;
`;

const AgentName = styled.p`
    margin: 0;
    font-weight: bold;
    overflow: hidden;
    white-space: nowrap; 
    text-overflow: ellipsis;
    width: 170px;  /*Temporary to 170px while favorites is ready otherwise the value is 100px*/
	font-size: 0.875rem;

    @media (max-width: 768px) {
        width: 140px;
        font-size: 0.8125rem;
    }
`;

const AgentWelcome = styled.p`
    margin: 0;
    font-weight: normal;
    font-size: 14px;
    color: ${props => props.theme.colors.grayX11};
`;

const AgentDateTime = styled.p`
    margin: 0;
    font-weight: normal;
    font-size: 10px;
    color: ${props => props.theme.colors.grayX11};
`;

const InputWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-radius: 8px;
    border: 1px solid ${(props) => props.theme.colors.clearGray};
    background: ${(props) => props.theme.colors.white};
    height: 32px;
    padding-right: 5px;
	width: 100%;

    @media (max-width: 768px) {
        height: 28px;
    }
`;

const InputField = styled.input`
    width: 100%;
	height: auto;
    max-height: 20px;
    border: none;
    outline: none;
    padding: 0 10px;
    background: ${(props) => props.theme.colors.white};
    ${(props) => props.theme.fonts.variant.regular()};

    ::placeholder {
        color: ${(props) => props.theme.colors.clearGray};
    }

    @media (max-width: 768px) {
        font-size: 0.8125rem;
    }
`;

const SearchIcon = styled.div`
    display: block;
    margin: auto;
    
	
	& > div {
        height: 32px;
	}
`;

const Sidebar1Header = styled.div `
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-content: flex-start;
	gap:15px;
	width: 100%;
	max-height: 35px;
	flex-shrink: 0;

    @media (max-width: 768px) {
        max-height: 30px;
    }
`;

const AgentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	
	& > div:nth-child(1) > div:nth-child(2){
		display:flex;
		width: 100%;
	}
`;

const AgentListMainWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const AgentListCollapsedHeader = styled.div`
	display:flex;
	justify-content: space-between;
`
const SliceButton = styled.button`
    display: none;
    position: fixed;
    top: 60px;
    left: 10px;
    z-index: 1001;
    background: ${props => props.theme.colors.silverGrayThree};
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);

    @media (max-width: 1024px) {
        display: contents;
        align-items: center;
        justify-content: center;
    }
`;

export {
	SideBar1,
	CollapseContainer,
	ListOfAgentContainer,
	AgentListContainer,
	AgentName,
	AgentWelcome,
	AgentListCard,
	AgentAvatar,
	AgentData,
	AgentFavorites,
	AgentDateTime,
	InputWrapper,
	InputField,
	SearchIcon,
	Sidebar1Header,
	AgentWrapper,
	AgentListMainWrapper,
	AgentListCollapsedHeader,
	SliceButton
}