import React, { useState, useEffect } from "react";
import {
  MainContainer,
  Content,
  MainCard,
  MainCardTitle,
  MainIconContainer,
} from "./Actions.styled";
import Icon from "../../../../../elements/icon/Icon";
import ActionCards from "./components/ActionCards/ActionCards";
import ModalLibrary from "./components/ModalLibrary/ModalLibrary";
import ModalAddAction from "./components/ModalAddAction/ModalAddAction";
import { ConfirmationModal } from "../../../../../elements/v2/modals";
import { observer } from "mobx-react";
import { useStore } from "../../../../../hooks";
import TemplateEditor from "../../../../../elements/v2/TemplateEditor/TemplateEditor";
import { v4 as uuidv4 } from "uuid";

const Actions = observer(() => {
  const { viewConversationStore, messageEditorStore } = useStore();

  const { actions } = viewConversationStore.conversationsActionsStore;

  const [isModalLibraryOpen, setIsModalLibraryOpen] = useState(false);
  const [isModalAddActionOpen, setIsModalAddActionOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [confirmationModalTitle, setConfirmationModalTitle] = useState("");
  const [confirmationModalMessage, setConfirmationModalMessage] = useState("");

  const [isTemplateEditorOpen, setIsTemplateEditorOpen] = useState(false);

  const closeTemplateEditor = () => {
    setIsTemplateEditorOpen(false);
    viewConversationStore.conversationsActionsStore.setSelectedAction({});
  };

  const onLoadEditor = () => {
    messageEditorStore.updateGeneralField(
      "customer",
      viewConversationStore.account
    );
    messageEditorStore.loadPayload(
      viewConversationStore.conversationsActionsStore.selectedAction
    );
  };

  const onExportEditor = () => {
    const updatedAction = {
      ...viewConversationStore.conversationsActionsStore.selectedAction,
      payload: messageEditorStore.payload.payload,
      binding: messageEditorStore.payload.binding,
    };
    
    viewConversationStore.conversationsActionsStore.updateAction(updatedAction);
    viewConversationStore.updateChangesDetected(true);
  };

  const openModalLibrary = () => {
    setIsModalLibraryOpen(true);
  };

  const closeModalLibrary = () => {
    setIsModalLibraryOpen(false);
  };

  const closeModalAddAction = () => {
    setIsModalAddActionOpen(false);
  };

  const openConfirmationModal = (title, message, action) => {
    viewConversationStore.conversationsActionsStore.setSelectedAction(action);
    setConfirmationModalTitle(title);
    setConfirmationModalMessage(message);
    setIsConfirmationModalOpen(true);
  };

  const closeConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
  };

  const removeSelectedAction = () => {
    viewConversationStore.conversationsActionsStore.removeSelectedAction();
    viewConversationStore.updateChangesDetected(true);
    setIsConfirmationModalOpen(false);
  };

  const openModalSelectedAction = (action) => {
    viewConversationStore.conversationsActionsStore.setSelectedAction(action);
    closeModalLibrary();   
    setIsTemplateEditorOpen(true);
  };

  return (
    <>
      {isTemplateEditorOpen && (
        <TemplateEditor
          isOpen={isTemplateEditorOpen}
          onClose={closeTemplateEditor}
          onLoad={onLoadEditor}
          onExport={onExportEditor}
          isReadMode={viewConversationStore.isReadMode()}
        />
      )}

      <MainContainer>
        <Content>
          {isModalLibraryOpen && (
            <ModalLibrary
              closeModalLibrary={closeModalLibrary}
              openModalAddAction={openModalSelectedAction}
            />
          )}
          {isModalAddActionOpen && (
            <ModalAddAction
              isOpen={isModalAddActionOpen}
              onClose={closeModalAddAction}
            />
          )}
          {isConfirmationModalOpen && (
            <ConfirmationModal
              isOpen={isConfirmationModalOpen}
              onClose={closeConfirmationModal}
              onConfirm={removeSelectedAction}
              title={confirmationModalTitle}
              message={confirmationModalMessage}
              icon="deleteFilled"
            />
          )}

          <MainCard onClick={openModalLibrary}>
            <MainCardTitle>New</MainCardTitle>
            <MainIconContainer>
              <Icon name={"circleAdd"} width={"30px"} height={"30px"} />
            </MainIconContainer>
          </MainCard>

          {actions.map((action, index) => {
            return (
              <ActionCards
                key={index}
                options={action}
                openModalAddAction={openModalSelectedAction}
                openConfirmationModal={openConfirmationModal}
                displaySettingsButton={true}
                setIsTemplateEditorOpen={setIsTemplateEditorOpen}
              />
            );
          })}
        </Content>
      </MainContainer>
    </>
  );
});

export default Actions;
