import styled from "styled-components";

export const FiltersContainer = styled.div`
  display: column;
  align-items: center;
  gap: 10px;
`;

export const SearchInput = styled.input`
  flex: 1;
  padding: 8px 12px;
  border: 1px solid ${(props) => props.theme.colors.lightGray};
  border-radius: 4px;
  font-size: 14px;
  outline: none;

  &:focus {
    border-color: ${(props) => props.theme.colors.activeBlue};
  }
`;

export const Dropdown = styled.div`
  select {
    padding: 8px 12px;
    border: 1px solid ${(props) => props.theme.colors.lightGray};
    border-radius: 4px;
    font-size: 14px;
    background: ${(props) => props.theme.colors.white};
    outline: none;

    &:focus {
      border-color: ${(props) => props.theme.colors.activeBlue};
    }
  }
`;

export const ClearLink = styled.a`
  font-size: 14px;
  color: ${(props) => props.theme.colors.activeBlue};
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: ${(props) => props.theme.colors.hoverBlue};
  }
`;

export const CustomGrid = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
  & button {
    font-family: "Inter", sans-serif;
  }  
`;

export const FilterContainter = styled.div`
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
    flex-direction: row;
    align-items: flex-end;
    flex-wrap: wrap;
    
    & button {
      font-family: "Inter", sans-serif;
    }
`

export const SearchIcon = styled.div`
  width: 14px;
  height: auto;
`;

export const InputField = styled.input`
  flex: 1;
  height: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 12px;
  color: black;
`;

export const CustomSearchInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
  gap: 1rem;
  border-radius: 10px;
  padding: 6px 8px;
  color: ${(props) => props.theme.colors.black};
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.clearGray};

`;
