import React, { useMemo } from "react";
import { Select, MenuItem, Box, Typography } from "@mui/material";
import {
  StyledFormControl,
  StyledTypography,
  menuProps,
  getUserNameTypographyStyles
} from "./UserSelect.styled";

const UserSelect = ({ users = [], value, onChange }) => {
  const options = useMemo(
    () => [{ id: "Unassigned", name: "Unassigned" }, ...users],
    [users]
  );

  const selectedValue = typeof value === "object" ? value.id : value;

  return (
    <StyledFormControl variant="outlined" size="small">
      <Select
        value={options?.some((user) => user.id === selectedValue) ? selectedValue : "Unassigned"}
        onChange={(event) => onChange(event.target.value)}
        displayEmpty
        MenuProps={menuProps}
        renderValue={(selected) => {
          const selectedUser = options?.find((user) => user.id === selected);
          return (
            <Box display="flex" alignItems="center">
              <Typography variant="body2" sx={{ fontSize: "12px" }}>
                {selectedUser ? selectedUser.name : "Unassigned"}
              </Typography>
            </Box>
          );
        }}
      >
        {options?.map((user) => (
          <MenuItem key={user.id} value={user.id} dense>
            <Box display="flex" alignItems="center">
            <StyledTypography sx={getUserNameTypographyStyles()}>
                {user.name}
            </StyledTypography>
            </Box>
          </MenuItem>
        ))}
      </Select>
    </StyledFormControl>
  );
};

export default UserSelect;
