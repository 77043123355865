import { observer } from "mobx-react";
import IconTabs from "../../../Tabs/IconTabs/IconTabs";
import GeneralTab from "./GeneralTab";
import VariablesTab from "./VariablesTab";
import {TabsWrapper} from "./Tabs.styled";

const TabsEditor = observer(() => {
  const tabs = [
    {
      label: "General",
      icon: "gear",
      content: <GeneralTab />,
    },
    {
      label: "Variables",
      icon: "brackets",
      content: <VariablesTab />,
    },
  ];

  return (
    <TabsWrapper>
      <IconTabs tabs={tabs} />
    </TabsWrapper>
  );
});

export default TabsEditor;