import { action, flow, makeObservable, observable } from "mobx";
import CreateOrEditCustomerStore from "./page/CreateOrEditCustomerStore";
import { API } from "aws-amplify";
import {
  putCustomer,
  deleteCustomer,
  getUsers,
  getCustomers,
} from "../../graphql/queries";

const CustomerPageStrategy = {
  list: { namePage: "list" },
  create: new CreateOrEditCustomerStore(),
};

class CustomerStore {
  currentPage = undefined;
  isLoading = false;
  isSuccessful = false;
  isError = false;
  customers = [];

  constructor() {
    makeObservable(this, {
      currentPage: observable,
      isLoading: observable,
      isSuccessful: observable,
      isError: observable,
      setCurrentPage: action,
      updateCustomer: flow,
      fetchUsers: flow,
      fetchCustomers: flow,
    });

    this.setCurrentPage("list");
  }

  setCurrentPage(page) {
    this.currentPage = CustomerPageStrategy[page];
  }

  *fetchUsers(customerId) {
    try {
      const resultUsers = yield API.graphql({
        query: getUsers,
        variables: {
          input: { customer: customerId },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      return JSON.parse(resultUsers?.data?.getUsers?.body).length || 0;
    } catch (error) {
      this.isError = true;
      return 0;
    }
  }


  /**
   * Fetches customers and their respective user counts.
   *
   * Fetches customers using the `getCustomers` GraphQL query.
   * For each customer, fetches the number of users using the `fetchUsers` method.
   * Finally, updates the `customers` property with the customer data.
   *
   * @returns {Promise<void>}
   */
  *fetchCustomers() {
    this.isLoading = true;
    this.isError = false;

    try {
      const result = yield API.graphql({
        query: getCustomers,
        variables: { input: { id: "" } },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      const customersData = JSON.parse(
          result?.data?.getCustomers?.body || "[]"
      );

      for (const customer of customersData) {
        try {
          customer.user_count = yield this.fetchUsers(customer.id);
        } catch (error) {
          customer.user_count = 0;
        }
      }

      this.customers = customersData;
    } catch (error) {
      this.isError = true;
    } finally {
      this.isLoading = false;
    }
  }

  *updateCustomer(customerData) {
    this.isLoading = true;
    this.isError = false;
    this.isSuccessful = false;
    try {
      yield API.graphql({
        query: putCustomer,
        variables: {
          input: customerData,
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      this.isSuccessful = true;
    } catch (error) {
      this.isError = true;
      this.isSuccessful = false;
    } finally {
      this.isLoading = false;
    }
  }
}

export default CustomerStore;
