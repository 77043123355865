import EditIcon from "@mui/icons-material/Edit";
import { Button, IconButton, CircularProgress } from "@mui/material";
import { useMemo, useState, useEffect } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { observer } from "mobx-react";
import { useStore } from "../../hooks";
import { useNavigate } from "react-router-dom";
import states from "../../components/common/USstates";
import EditCustomerModal from "./components/EditCustomerModal/EditCustomerModal";
import SuccessModal from "../../elements/v2/SuccessModal/SuccessModal";
import {
  CustomerTableMessages,
  CustomerTableColumns,
  CustomerTableHeaders,
  TableAlignment,
  ModalTitles,
} from "../../utils/enums";

import {
  ActionBox,
  TopToolbarBox,
  CreateButtonWrapper,
  LoadingWrapper,
} from "./CustomerList.styled";
import Alerts from "../../elements/v2/Alerts/Alerts";

const CustomLoadingOverlay = () => (
  <LoadingWrapper>
    <CircularProgress />
  </LoadingWrapper>
);

const AccountList = observer(() => {
  const { customer } = useStore();
  const navigate = useNavigate();

  customer.setCurrentPage("list");
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);

  useEffect(() => {
    if (customer.isSuccessful) {
      customer.fetchCustomers();
    }
  }, [customer.isSuccessful]);

  const handleEditClick = (row) => {
    if (row && row.original) {
      setSelectedCustomerId(row.original.id);
      setIsEditModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setIsEditModalOpen(false);
    setSelectedCustomerId(null);
  };

  const openCreateModal = () => {
    navigate("/customers/add");
  };

  useEffect(() => {
    customer.fetchCustomers();
  }, [customer]);

  const columns = useMemo(
    () => [
      {
        accessorKey: CustomerTableColumns.NAME,
        header: CustomerTableHeaders[CustomerTableColumns.NAME],
        muiTableHeadCellProps: { align: TableAlignment.CENTER },
        muiTableBodyCellProps: { align: TableAlignment.CENTER },
      },
      {
        accessorKey: CustomerTableColumns.USER_COUNT,
        header: CustomerTableHeaders[CustomerTableColumns.USER_COUNT],
        enableEditing: false,
        muiTableHeadCellProps: { align: TableAlignment.CENTER },
        muiTableBodyCellProps: { align: TableAlignment.CENTER },
      },
      {
        accessorKey: CustomerTableColumns.STREET,
        header: CustomerTableHeaders[CustomerTableColumns.STREET],
        muiTableHeadCellProps: { align: TableAlignment.CENTER },
        muiTableBodyCellProps: { align: TableAlignment.CENTER },
      },
      {
        accessorKey: CustomerTableColumns.CITY,
        header: CustomerTableHeaders[CustomerTableColumns.CITY],
        muiTableHeadCellProps: { align: TableAlignment.CENTER },
        muiTableBodyCellProps: { align: TableAlignment.CENTER },
      },
      {
        accessorKey: CustomerTableColumns.STATE,
        header: CustomerTableHeaders[CustomerTableColumns.STATE],
        muiTableHeadCellProps: { align: TableAlignment.CENTER },
        muiTableBodyCellProps: { align: TableAlignment.CENTER },
        editSelectOptions: states.map((item) => ({
          name: item.name,
          value: item.name,
        })),
      },
      {
        accessorKey: CustomerTableColumns.ZIP_CODE,
        header: CustomerTableHeaders[CustomerTableColumns.ZIP_CODE],
        muiTableHeadCellProps: { align: TableAlignment.CENTER },
        muiTableBodyCellProps: { align: TableAlignment.CENTER },
      },
      {
        accessorKey: CustomerTableColumns.DOMAINS,
        header: CustomerTableHeaders[CustomerTableColumns.DOMAINS],
        muiTableHeadCellProps: { align: TableAlignment.CENTER },
        muiTableBodyCellProps: { align: TableAlignment.CENTER },
      },
      {
        accessorKey: CustomerTableColumns.DEFAULT_EMAIL,
        header: CustomerTableHeaders[CustomerTableColumns.DEFAULT_EMAIL],
        muiTableHeadCellProps: { align: TableAlignment.CENTER },
        muiTableBodyCellProps: { align: TableAlignment.CENTER },
      },
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns,
    data: customer.customers,
    enableEditing: true,
    getRowId: (row) => row.id,
    positionActionsColumn: "last",
    state: {
      isLoading: customer.isLoading,
      showAlertBanner: customer.isError,
    },
    muiLoadingOverlayProps: {
      slots: {
        loadingOverlay: CustomLoadingOverlay,
      },
    },
    muiToolbarAlertBannerProps: {
      color: "error",
      children: customer.errorMessage,
    },
    renderEmptyRowsFallback: () => (
      <tr>
        <td colSpan={columns.length} style={{ textAlign: "center" }}>
          {CustomerTableMessages.NO_RECORDS}
        </td>
      </tr>
    ),
    renderRowActions: ({ row }) => (
      <ActionBox>
        <IconButton onClick={() => handleEditClick(row)}>
          <EditIcon />
        </IconButton>
      </ActionBox>
    ),
    renderTopToolbarCustomActions: () => (
      <TopToolbarBox>
        <CreateButtonWrapper>
          <Button variant="contained" onClick={openCreateModal}>
            Create Account
          </Button>
        </CreateButtonWrapper>
      </TopToolbarBox>
    ),
  });

  return (
    <>
      {customer.isError && (
        <Alerts
          severity="error"
          title="Error"
          message={CustomerTableMessages.ERROR_UPDATE}
          onClose={() => {
            customer.isError = false;
          }}
          top="20px"
          right="20px"
        />
      )}
      <MaterialReactTable table={table} />
      {selectedCustomerId && (
        <EditCustomerModal
          open={isEditModalOpen}
          onClose={handleCloseModal}
          customerId={selectedCustomerId}
        />
      )}
      <SuccessModal
        isOpen={customer.isSuccessful}
        onClose={() => {
          customer.isSuccessful = false;
        }}
        title={ModalTitles.SUCCESS}
        message={CustomerTableMessages.SUCCESS_UPDATE}
        autoClose={true}
      />
    </>
  );
});

export default AccountList;
