import {observer} from "mobx-react";
import {EditorUnlayer, TabsEditor} from "./components";
import {TemplateEditorContainer} from "./TemplateEditor.styled";

const BasedTemplateEditor = observer(() => {
  return (
    <TemplateEditorContainer>
      <TabsEditor />
      <EditorUnlayer />
    </TemplateEditorContainer>
  );
});

export default BasedTemplateEditor;
