import styled from "styled-components";
import { FormControl, Typography } from "@mui/material";

export const StyledFormControl = styled(FormControl)`
  width: 150px;
  margin: 10px;
  font-family: "Inter", sans-serif;

  .MuiOutlinedInput-root {
    height: 30px;
    padding: 4px 8px;
    border-radius: 10px;
  }

  .MuiSelect-select {
    display: flex;
    align-items: center;
    padding: 4px 8px;
    font-size: 12px;
    font-family: "Inter", sans-serif;
    line-height: 1;
  }
`;

export const StyledTypography = styled(Typography)`
  font-weight: bold;
  font-size: 12px;
  font-family: "Inter", sans-serif;
`;

export const getUserNameTypographyStyles = () => ({
  fontSize: "12px",
  fontFamily: '"Inter", sans-serif',
});

export const menuProps = {
  PaperProps: {
    style: {
      maxHeight: 200,
      marginTop: 8,
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
};
