import { action, flow, makeObservable, observable } from "mobx";
import ListDataStore from "./page/ListDataStore";
import { getNotification, putNotificationRead } from "../../graphql/queries";
import { API } from "aws-amplify";

const NotificationPageStrategy = {
  list: new ListDataStore(),
};

class NotificationStore {
  notifications = [];
  filteredNotifications = [];
  totalRowCount = 0;
  isLoading = false;
  isError = false;
  currentPage = 1;
  pageSize = 10;
  currentPage = undefined;
  itemsBell = [];

  constructor() {
    makeObservable(this, {
      notifications: observable,
      filteredNotifications: observable,
      totalRowCount: observable,
      isLoading: observable,
      isError: observable,
      currentPage: observable,
      pageSize: observable,
      getNotifications: flow,
      setError: action,
      onClearError: action,
      setCompleted: action,
      filterNotifications: action,
      itemsBell: observable,
      getNotificationsRequest: flow,
      putReadNotification: flow,
      setItemStatusById: action
    });
  }

  *putReadNotification(props) {
    const { user_id, notification_id, customer_id } = props;
    try {
      this.isLoading = true;

      const response = yield API.graphql({
        query: putNotificationRead,
        variables: {
          input: {
            customer_id,
            user_id,
            notification_id
          },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      return JSON.parse(response.data?.putReadNotification?.body);

    } catch (error) {
      this.isError = true;
    } finally {
      this.isLoading = false;
    }
  }

  *getNotificationsRequest(props) {
    const { user_id, id, limit, customer_id } = props;
    try {
      this.isLoading = true;

      const response = yield API.graphql({
        query: getNotification,
        variables: {
          input: {
            customer_id,
            limit,
            user_id: "",
            id: ""
          },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      const notificationsReceived = JSON.parse(response.data?.getNotification?.body);
      this.itemsBell = notificationsReceived;
      return JSON.parse(notificationsReceived);

    } catch (error) {
      this.isError = true;
    } finally {
      this.isLoading = false;
    }
  }

  setItemStatusById(idList, status) {
    if (!idList.length) return;
    const newItemsBell = [...this.itemsBell];
  
    for (let i = 0; i < newItemsBell.length; i++) {
      if (idList.includes(newItemsBell[i].id)) {
        newItemsBell[i] = { ...newItemsBell[i], status };
      }
    }

    this.itemsBell = newItemsBell;
  }
  

  getItemsBellByStatus(status) {
    const findElements = this.itemsBell?.map((item) => {
      if (item.status !== status) return;
      return {
        id: item.id,
        name: item.payload?.fullName,
        description: item.message,
        time: item.insert_date,
        status: status,
        user: item.user,
        customer: item.customer,
        metadata: item.metadata,
      };
    });
    return findElements.filter((i) => i != undefined);
  }

  *getNotifications({ customer, pageIndex, pageSize, sortBy, search }) {
    this.isLoading = true;
    this.isError = false;

    try {
      yield new Promise((resolve) => setTimeout(resolve, 500));

      const mockData = [
        {
          id: 1,
          title: "Notification 1",
          message:
            "This is a long message that demonstrates the 'View Details' functionality. Click to see the full message and more details about this notification. This is a long message that demonstrates the 'View Details' functionality. Click to see the full message and more details about this notification. This is a long message that demonstrates the 'View Details' functionality. Click to see the full message and more details about this notification. This is a long message that demonstrates the 'View Details' functionality. Click to see the full message and more details about this notification. This is a long message that demonstrates the 'View Details' functionality. Click to see the full message and more details about this notification. This is a long message that demonstrates the 'View Details' functionality. Click to see the full message and more details about this notification ",
          date: "09/20/2024",
          isCompleted: false,
          channel: "email",
          status: "unread",
        },
        {
          id: 2,
          title: "Notification 2",
          message: "Your request has been approved.",
          date: "09/19/2024",
          isCompleted: true,
          channel: "sms",
          status: "read",
        },
        {
          id: 3,
          title: "Notification 3",
          message: "Reminder: Check your inbox.",
          date: "09/18/2024",
          isCompleted: false,
          channel: "phone",
          status: "unread",
        },
        {
          id: 4,
          title: "Notification 4",
          message: "Your account settings have been updated.",
          date: "09/17/2024",
          isCompleted: true,
          channel: "email",
          status: "read",
        },
        {
          id: 5,
          title: "Notification 5",
          message: "New comment on your post.",
          date: "09/16/2024",
          isCompleted: false,
          channel: "sms",
          status: "unread",
        },
        {
          id: 6,
          title: "Notification 6",
          message: "System maintenance scheduled.",
          date: "09/15/2024",
          isCompleted: false,
          channel: "phone",
          status: "unread",
        },
        {
          id: 7,
          title: "Notification 7",
          message: "Password change request.",
          date: "09/14/2024",
          isCompleted: true,
          channel: "email",
          status: "read",
        },
        {
          id: 8,
          title: "Notification 8",
          message: "Your subscription will expire soon.",
          date: "09/13/2024",
          isCompleted: false,
          channel: "sms",
          status: "overdue",
        },
        {
          id: 9,
          title: "Notification 9",
          message: "New feature release available.",
          date: "09/12/2024",
          isCompleted: true,
          channel: "phone",
          status: "completed",
        },
        {
          id: 10,
          title: "Notification 10",
          message: "Don’t forget to verify your email.",
          date: "09/11/2024",
          isCompleted: false,
          channel: "email",
          status: "unread",
        },
      ];

      this.notifications = mockData.slice(
        pageIndex * pageSize,
        (pageIndex + 1) * pageSize
      );

      this.filteredNotifications = this.notifications;
      this.totalRowCount = mockData.length;
    } catch (error) {
      this.setError(true);
    } finally {
      this.isLoading = false;
    }
  }

  filterNotifications(filters) {
    if (!filters.length || filters.includes("all")) {
      this.filteredNotifications = this.notifications;
      return;
    }

    this.filteredNotifications = this.notifications.filter((notification) => {
      const isUnread = !notification.isCompleted;
      const isRead = notification.isCompleted && notification.status === "read";
      const isOverdue = notification.status === "overdue";
      const isCompleted = notification.status === "completed";

      return filters.some((filter) => {
        if (filter === "unread" && isUnread) return true;
        if (filter === "read" && isRead) return true;
        if (filter === "overdue" && isOverdue) return true;
        if (filter === "completed" && isCompleted) return true;
        return false;
      });
    });
  }

  setError(errorState) {
    this.isError = errorState;
  }

  onClearError() {
    this.isError = false;
  }

  setCompleted(id) {
    const notification = this.notifications.find((item) => item.id === id);
    if (notification) {
      notification.isCompleted = true;
      notification.status = "completed";
    }
  }
}

export default NotificationStore;
