import React, { useState } from "react";
import AlertTable from "./components/AlertTable";
import { Breadcrumb } from "./AlertView.styled"

const AlertView = (() => {

  const [alertFilters, setAlertFilters] = useState([]);

  return (
    <>
    <Breadcrumb>
      <p>Alerts</p>
    </Breadcrumb>
    <AlertTable filters={alertFilters}></AlertTable>
    </>
    
  );
});

export default AlertView;