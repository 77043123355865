import styled from "styled-components";

export const RetryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: ${(props) => props.theme.colors.primaryWhite};
  font-family: ${(props) => props.theme.fonts.family.inter};
`;

export const RetryHeader = styled.label`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 2px;
  color: ${(props) => props.theme.colors.textColor};
`;

export const RetryRow = styled.div`
  margin-top: 0px;
  display: grid;
  grid-template-columns: 150px 1fr 120px;
  align-items: center;
  gap: 16px;
  padding: 4px 0;
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;

  .spinner {
    width: 16px;
    height: 16px;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  textarea {
    border: 1px solid ${(props) => props.theme.colors.silverGrayTwo};
    border-radius: 4px;
    height: 40px;
    resize: none;
    overflow-y: auto;
    padding: 8px;
    font-size: 0.775rem;
    line-height: 1.5;
    outline: none;
    background-color: ${(props) => props.theme.colors.primaryWhite};
    color: ${(props) => props.theme.colors.textColor};
  }
`;

export const TimeInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const UnitSelectWrapper = styled.div`
  width: 120px;

  .MuiOutlinedInput-root {
    height: 36px;
    font-size: 14px;
    
    fieldset {
      border-color: ${(props) => props.theme.colors.silverGrayTwo};
    }
  }
`;

export const NumberInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  width: fit-content;

  input {
    width: 60px;
    padding: 8px;
    border: 1px solid ${(props) => props.theme.colors.silverGrayTwo};
    border-radius: 4px;
  }

  .arrows {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 8px;
    height: 100%;
    justify-content: center;

    svg {
      cursor: pointer;
      font-size: 18px;
      &:hover {
        color: #666;
      }
    }
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;
  margin-top: 20px;
`;

export const AddButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;

  button {
    display: flex;
    align-items: center;
    gap: 8px;
    background: ${(props) => props.theme.colors.activeBlue};
    border: 1px solid ${(props) => props.theme.colors.activeBlue};
    color: ${(props) => props.theme.colors.white};
    padding: 8px 16px;
    border-radius: 8px;
    font-size: 12px;
    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.6;
    }
  }
`;

export const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

export const ModalContent = styled.div`
  width: 90%;
  max-width: 1200px;
  height: 90%;
  background-color: ${(props) => props.theme.colors.primaryWhite};
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  color: ${(props) => props.theme.colors.textColor};

  &:hover {
    color: ${(props) => props.theme.colors.dangerRed};
  }
`;

export const EditorContainer = styled.div`
  margin-top: 1rem;
  border: 1px solid ${props => props.theme.colors.silverGrayTwo};
  border-radius: 4px;
  position: relative;
`;

export const SavingOverlay = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  padding: 5px;
  display: flex;
  align-items: center;
  gap: 8px;
  z-index: 1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s ease-in-out;
  opacity: ${({ isSaving }) => (isSaving ? 0.8 : 0)};

  span {
    font-size: 12px;
    color: ${props => props.theme.colors.gray};
  }
`;

export const GeneratingRetryOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

