import {action, computed, makeObservable, observable} from "mobx";
import TemplateEditorFormStore from "./TemplateEditorFormStore";
import TemplateEditorVariablesStore from "./TemplateEditorVariablesStore";
import ImagePickerStore from "./ImagePickerStore";
import {debounce} from "lodash";

export default class TemplateEditorStore {
  requiredFieldsError = false;
  html = null;
  text = null;
  design = null;

  formEditorStore = new TemplateEditorFormStore();
  variablesEditorStore = new TemplateEditorVariablesStore();
  imagePickerStore = new ImagePickerStore();

  constructor() {
    makeObservable(this, {
      requiredFieldsError: observable,
      formEditorStore: observable,
      variablesEditorStore: observable,
      imagePickerStore: observable,
      html: observable,
      text: observable,
      design: observable,
      requiredFields: computed,
      showRequiredFieldsError: action,
      updateField: action,
      exportData: action
    });
  }

  updateField(field, value) {
    const fieldMapping = {
      sources: (value) => this.variablesEditorStore.setSources(value),
      variables: (value) => this.variablesEditorStore.setVariables(value),
      from: (value) => this.formEditorStore.updateField('from', value),
      sender_name: (value) => this.formEditorStore.updateField('senderName', value),
      senderName: (value) => this.formEditorStore.updateField('senderName', value),
      to: (value) => this.formEditorStore.updateField('to', value),
      subject: (value) => this.formEditorStore.updateField('subject', value),
      cc: (value) => this.formEditorStore.updateField('cc', value),
      bcc: (value) => this.formEditorStore.updateField('bcc', value),
      description: (value) => this.formEditorStore.updateField('description', value),
    };

    if (fieldMapping[field]) {
      fieldMapping[field](value);
    } else {
      this[field] = value;
    }
  }

  exportData() {
    return {
      html: this.html,
      design: this.design,
      form: {
        from: this.formEditorStore.from,
        senderName: this.formEditorStore.senderName,
        to: this.formEditorStore.to,
        subject: this.formEditorStore.subject,
        cc: this.formEditorStore.cc,
        bcc: this.formEditorStore.bcc,
        description: this.formEditorStore.description,
      },
      variables: this.variablesEditorStore.variables,
    }
  }

  onClearRequiredFieldsError() {
    this.requiredFieldsError = false;
  }

  showRequiredFieldsError() {
    if (this.requiredFieldsError === true) return;

    this.requiredFieldsError = true;
    const hideModal = debounce(() => {
      this.onClearRequiredFieldsError();
    }, 3000);
    hideModal();
  }

  get requiredFields() {
    return this.formEditorStore.from && this.formEditorStore.senderName && this.formEditorStore.to && this.formEditorStore.subject;
  }

  clear() {
    this.html = null;
    this.text = null;
    this.design = null;
    this.formEditorStore.clean();
    this.variablesEditorStore.clean();

    this.variablesEditorStore.setDefaultVariables();
  }
}