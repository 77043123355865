import { API } from "aws-amplify";
import { action, makeObservable, observable, flow } from "mobx";
import {
  getUploadUrlFile,
  getUrlFile,
} from "../../graphql/queries";
import axios from "axios";
import TemplateEditorStore from "../components/templateEditor/TemplateEditorStore";
import {OriginVariables} from "../../utils/enums";

class MessageStore {
  isError = false;
  isLoading = false;
  initialLoading = false;
  setMessageRequiredFields = false;
  payload = {};
  templateJson = null;
  updatingTemplate = false;
  customer = "";
  id = "";
  template = "";
  body = "";
  sourceList = [];
  s3FileRoute = "email_templates";

  templateEditorStore = new TemplateEditorStore();

  constructor() {
    makeObservable(this, {
      templateEditorStore: observable,
      isLoading: observable,
      isError: observable,
      initialLoading: observable,
      payload: observable,
      templateJson: observable,
      updatingTemplate: observable,
      customer: observable,
      id: observable,
      template: observable,
      body: observable,
      sourceList: observable,
      s3FileRoute: observable,
      updateGeneralField: action,
      savePayload: action,
      clearTemplate: action,
      loadPayload: action,
      onClearError: action,
      setId: action,
      uploadFileToS3: flow,
      saveTemplate: flow,
      loadTemplate: flow
    });
  }

  setId(value) {
    this.id = value;
  }

  updateGeneralField(field, value) {
    this[field] = value;

    if (field === "sourceList") {
      this.templateEditorStore.updateField('sources', value);
    }
  }

  savePayload() {
    const bindingArray = this.templateEditorStore.variablesEditorStore.variables.map((variable) => ({
      name: variable.name,
      variable: `{{${variable.variable}}}`,
      origin: variable.origin,
      value: variable.origin === OriginVariables.EVENT ? variable.field : variable.value,
    }));

    const payloadArray = [
      { field: "from", value: this.templateEditorStore.formEditorStore.from || "" },
      { field: "sender_name", value: this.templateEditorStore.formEditorStore.senderName || "" },
      { field: "to", value: this.templateEditorStore.formEditorStore.to || "" },
      { field: "subject", value: this.templateEditorStore.formEditorStore.subject || "" },
      { field: "cc", value: this.templateEditorStore.formEditorStore.cc || "" },
      { field: "bcc", value: this.templateEditorStore.formEditorStore.bcc || "" },
      { field: "description", value: this.templateEditorStore.formEditorStore.description || "" },
      { field: "body", value: `${this.id}.html` },
      { field: "template", value: `${this.id}.json` },
    ];

    this.payload = {
      binding: bindingArray,
      payload: payloadArray,
    };
  }

  loadPayload(action) {
    this.id = action.id;
    const payload = action?.payload;
    if (payload?.length) {
      payload.forEach((field) => {
        if (field.field === "id") {
          this.id = field.value;
        } else if (field.field === "body") {
          this.body = field.value;
        } else if (field.field === "template") {
          this.template = field.value;
        } else {
          this.templateEditorStore.updateField(field.field, field.value);
        }
      });
    }

    if (action?.binding?.length) {
      action.binding.forEach((binding) => {
        binding.variable = binding.variable.replaceAll("{", "");
        binding.variable = binding.variable.replaceAll("}", "");

        if (binding.name === "") {
          return;
        }

        const [source, field] = binding.value?.split(".");

        const existInDefaultVariables = this.templateEditorStore.variablesEditorStore.variables.findIndex((variable) => variable.name === binding.name);
        if (existInDefaultVariables > 0) {
          this.templateEditorStore.variablesEditorStore.variables[existInDefaultVariables].value = binding.value;

          if (binding.origin === OriginVariables.EVENT) {
            this.templateEditorStore.variablesEditorStore.variables[existInDefaultVariables]._value = { source, field };
          }
        } else {
          if (binding.origin === OriginVariables.EVENT) {
            binding.value = binding.value || "";
            binding._value = { source, field };
          }

          this.templateEditorStore.variablesEditorStore.variables.push(binding);
        }
      });
    }
  }

  *loadTemplate() {
    if (!this.template) return;
    try {
      this.isLoading = true;
      this.isError = false;

      const response = yield API.graphql({
        query: getUrlFile,
        variables: {
          input: {
            customer: this.customer,
            file_name: `${this.template}`,
            file_route: this.s3FileRoute,
          },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      const uploadUrl = JSON.parse(response.data.getUrlFile?.body);

      const res = yield axios({
        url: uploadUrl,
        method: "GET",
        responseType: "blob",
      });
      const blobRes = new Blob([res.data]);
      const streamer = yield blobRes.text();

      this.templateJson = JSON.parse(streamer);
      this.templateEditorStore.updateField('design', this.templateJson);

    } catch (error) {
      this.templateJson = {};
    } finally {
      this.isLoading = false;
    }
  }

  *uploadFileToS3(content, type, name, file_route=null) {
    const blobFile = new Blob([content], {type});

    const response = yield API.graphql({
      query: getUploadUrlFile,
      variables: {
        input: {
          customer: this.customer,
          file_name: name,
          file_type: type,
          file_route: file_route || this.s3FileRoute,
          overwrite: true,
        },
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });

    const uploadUrl = JSON.parse(response.data.getUploadUrlFile?.body);
    const config = {
      headers: {
        "Content-Type": type,
      },
    };

    yield axios.put(uploadUrl, blobFile, config);
    return uploadUrl;
  }

  *saveTemplate(html, json) {
    try {
      this.updatingTemplate = true;
      this.isError = false;

      yield this.uploadFileToS3(
        JSON.stringify(json, null, 2),
        "application/json",
        `${this.id}.json`
      );

      yield this.uploadFileToS3(html, "text/html", `${this.id}.html`);
    } catch (error) {
      this.isError = true;
    } finally {
      this.updatingTemplate = false;
    }
  }

  clearTemplate() {
    this.isError = false;
    this.isLoading = false;
    this.updatingTemplate = false;
    this.initialLoading = false;
    this.s3FileRoute = "email_templates";
    this.template = "";
    this.body = "";
    this.variables = [];
    this.setMessageRequiredFields = false;
    this.payload = {};
    this.templateJson = null;

    this.templateEditorStore.clear();
  }

  onClearError() {
    this.isError = false;
  }
}

export default MessageStore;
