import styled from 'styled-components';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';

const StyledFormControl = styled(FormControl)`
    && {
        margin: 0;
        min-width: ${(props) => `${props.minWidth}px`};
    }

    && .MuiOutlinedInput-root {
        border-radius: 8px;
    }

    ${props => props.hasSelection && `
      && fieldset {
        border: none;
      }
    `}
`;

const StyledSelect = styled(Select)`
    && .MuiSelect-outlined {
        background-color: ${(props) => props.theme.colors.primaryWhite};
        ${props => props.hasSelection && `
      background-color: ${props.theme.colors.aliceLightBlue};
    `}
    }
    && .MuiSelect-select {
        width: calc(${(props) => `${props.minWidth}px`} - 46px);
        font-family: "Inter", sans-serif;
    }

    ${props => props.hasSelection && `
    && .MuiSelect-icon {
      display: none;
    }
  `}
`;

const StyledInputLabel = styled(InputLabel)`
    && {
        color: ${props => props.theme.colors.black};
        font-size: 14px;
        font-family: "Inter", sans-serif;
    }
`;

const StyledMenuItem = styled(MenuItem)`
    && {
        font-size: 14px;
        color: ${(props) => props.theme.colors.darkCharcoal};
        font-family: "Inter", sans-serif;
        text-transform: capitalize;

        .MuiTypography-root {
            font-family: "Inter", sans-serif;
        }
    }

    && li.Mui-selected {
        background: none;
        font-family: "Inter", sans-serif;
    }
`;

const ClearButton = styled.div`
    position: absolute;
    right: 12px;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.54);
    
    &:hover {
        color: rgba(0, 0, 0, 0.87);
    }

    svg {
        font-size: 18px;
        margin-right: 10px;
    }
`;

export {
  StyledFormControl,
  StyledSelect,
  StyledInputLabel,
  StyledMenuItem,
  ClearButton,
};
