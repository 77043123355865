import React from "react";
import { Route, Routes } from "react-router-dom";
import AlertView from "./AlertView";

const AlertPage = () => {
  return (
    <Routes>
      <Route index element={<AlertView />} />
    </Routes>
  );
};

export default AlertPage;
