import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  TabsContainer,
  TabsHeader,
  Tab,
  TabContent,
} from "./PlaygroundTabs.styled";
import { observer } from "mobx-react";
import { useStore } from "../../../hooks";

const Tabs2 = observer(
  ({
    tabs,
    onToggleTab,
    showBorder,
    width,
    blockToggle = false,
    onToggleBlocked,
    defaultTab,

  }) => {
    const { authStore } = useStore();
    const [activeTab, setActiveTab] = useState(defaultTab || 0);

    const hasPermission = useCallback(
      (tab) => {
        if (!tab.permission) return true;
        return authStore.hasPermission(
          tab.permission.code,
          tab.permission.level
        );
      },
      [authStore]
    );
    
    useEffect(() => {
      setActiveTab(defaultTab);
    }, [defaultTab])

    const renderTabs = (tabs) => {
      return tabs.map(
        (tab, index) =>
          {
            const tabClick = () =>{
              if (tab.disabled){
                return tab?.onDisabled();
              }
              handleTabClick(index);
            };

            return hasPermission(tab) && (
              <Tab
                key={index}
                isActive={activeTab === index}
                onClick={tabClick}
                disabled={tab.disabled}
              >
                {tab.label}
              </Tab>
            )
          }
      );
    };

    const handleTabClick = (index) => {
      if (blockToggle) return onToggleBlocked?.();
      setActiveTab(index);
      onToggleTab && onToggleTab(index);
    };

    return (
      <TabsContainer>
        <TabsHeader showBorder={showBorder} width={width}>
          {renderTabs(tabs)}
        </TabsHeader>
      </TabsContainer>
    );
  }
);

Tabs2.Outlet = ({ show, children }) => {
  return <TabContent isShow={show}>{children}</TabContent>;
};

export default Tabs2;
