export const AgentDomain = {
  CONCIERGE: "concierge",
};

export const ApiResponseStatus = {
  DONE: "done",
  GUARDRAIL: "guardrail",
  STREAM: "stream",
  METADATA: "metadata",
};

export const MessaggeType = {
  SENT: "sent",
  RECEIVED: "received",
};

export const MarketingStatus = {
  ACTIVE: "ACTIVE",
  DRAFT: "DRAFT",
  CANCELLED: "CANCELLED",
};

export const PermissionCodes = {
  USER_PERSONA_TAB: "USER_PERSONA_TAB",
  USER_PERSONA_FIELD: "USER_PERSONA_FIELD",
  USER_PERSONA_BUILDER: "USER_PERSONA_BUILDER",
};

export const PermissionLevels = {
  VIEW: "v",
  CREATE: "c",
  UPDATE: "u",
  DELETE: "d",
};

export const DefaultValues = {
  UNKNOWN: "Unknown",
};

export const NoteCategories = {
  GENERAL: "General",
  FOLLOW_UP: "Follow-Up",
  OPPORTUNITY: "Opportunity",
  URGENT: "Urgent",
  MANAGER_REVIEW: "Manager Review",
};

export const PHOTO_UPLOAD_STAGES = {
  IDLE: 0,
  PUTTING_FILE_NAME: 1,
  UPLOADING_FILE_TO_S3: 2,
  GETTING_IMAGE_FROM_PHOTO_URL: 3,
  FINISHED_PHOTO_UPLOAD: 4,
};

export const STAGE_PERCENTAGES = {
  [PHOTO_UPLOAD_STAGES.IDLE]: 0,
  [PHOTO_UPLOAD_STAGES.PUTTING_FILE_NAME]: 33,
  [PHOTO_UPLOAD_STAGES.UPLOADING_FILE_TO_S3]: 66,
  [PHOTO_UPLOAD_STAGES.GETTING_IMAGE_FROM_PHOTO_URL]: 90,
  [PHOTO_UPLOAD_STAGES.FINISHED_PHOTO_UPLOAD]: 100,
};

export const NotificationTab = {
  UnreadTab: 0,
  ReadTab: 1,
};

export const ItemStatus = {
  unread: "UNREAD",
  read: "READ",
};

export const AGENT_ROLES = ["assistant", "agent", "annaims"];

export const NOTE_CATEGORIES_LIST = Object.values(NoteCategories);

export const OriginVariables = {
  FIXED: 'FIXED',
  EVENT: 'EVENT'
}

export const DefaultFieldVariables = {
  TO: 'To',
  AGENT_NAME: 'agent_name',
  AGENT_POSITION: 'agent_position',
  COMPANY_NAME: 'company_name',
  CUSTOMER_NAME: 'customer_name'
};

export const DefaultVariables = [
  { isDefault: true, name: "To", origin: OriginVariables.EVENT, variable: DefaultFieldVariables.TO, field: 'email', source: 'contacts', value:"email" },
  { isDefault: true, name: "Agent Name", origin: OriginVariables.FIXED, variable: DefaultFieldVariables.AGENT_NAME },
  { isDefault: true, name: "Agent Position", origin: OriginVariables.FIXED, variable: DefaultFieldVariables.AGENT_POSITION },
  { isDefault: true, name: "Company Name", origin: OriginVariables.FIXED, variable: DefaultFieldVariables.COMPANY_NAME },
  { isDefault: true, name: "Customer Name", origin: OriginVariables.EVENT, variable: DefaultFieldVariables.CUSTOMER_NAME, field: 'first_name', source: 'contacts' },
];

export const FIELD_VALIDATIONS = {
  first_name: {},
  last_name: {},
  email: {
    pattern: /^\s*(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[^"])*")@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\s*$/,
    string_pattern: "[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?",
    error: "Please enter a valid email address. The email should follow a standard format (e.g., user@domain.com)",
    required: true
  },
  company_name: {
    pattern: /^\s*[\w\s\-&.,'"()!@#$%]+\s*$/,
    error: "Company Name contains invalid characters",
    required: false
  },
  address: {
    pattern: /^\s*[\w\s\-,.#&/()'"]+\s*$/,
    error: "Address contains invalid characters",
    required: false
  },
  city: {
    pattern: /^\s*[A-Za-z\s]+\s*$/,
    error: "City should only contain letters",
    required: false
  },
  state: {
    pattern: /^\s*[A-Za-z\s]+\s*$/,
    error: "State should only contain letters",
    required: false
  },
  postal_code: {
    pattern: /^\s*\d{5}(-\d{4})?\s*$/,
    string_pattern: "\\d{5}(-\\d{4})?",
    error: "Invalid postal code format",
    required: false
  },
  phone: {
    pattern: /^\s*\+?(?:[\d()\s-]*\d[\d()\s-]*){10,}\s*$/,
    error: "Phone number must have only numbers and at least 10 digits.",
    required: false
  }
};

export const VALIDATION_MESSAGES = {
  NO_MAPPINGS: "No field mappings defined. Please map at least one field.",
  NO_DATA: "No data to validate. Please upload a file with data.",
  EMAIL_REQUIRED: "Email is a required.",
  UPLOAD_ERROR: (message) => `Error uploading file: ${message}`,
  VALIDATION_MODAL: {
    TITLE: "Validation Errors",
    SUBTITLE: "There are issues in your file:",
  }
};

export const RowStatus = {
  RESOLVED: "completed",
  UNRESOLVED: "pending",
};

export const MarketingErrorMessages = {
  MESSAGE_TAB: {
    title: "Missing Segment",
    message: "Complete your segment selection or CSV upload before creating your message. This ensures proper email destinations and variables."
  },
  SEGMENT_CONTACT_DATA:{
    message: "No uploaded data found. Please re-upload your CSV file."
  },
  PREVENT_PUBLISH_WITHOUT_AGENT: {
    title: "Error",
    message: "An agent is required to publish this campaign. Please assign an agent in the Overview tab."
  },
  PREVENT_DRAFT_WITHOUT_AGENT: {
    title: "Warning",
    message: "No agent is assigned. Do you want to save this campaign as a draft?"
  }
}

export const CustomerTableMessages = {
  NO_RECORDS: "No records to display",
  ERROR_UPDATE: "Error updating customer",
  ERROR_EDIT: "Could not get customer for editing",
  SUCCESS_UPDATE: "Customer updated successfully",
  ERROR_LOADING: "Error loading customers"
};

export const TableAlignment = {
  CENTER: "center"
};

export const CustomerTableColumns = {
  NAME: "name",
  USER_COUNT: "user_count",
  STREET: "street",
  CITY: "city",
  STATE: "state",
  ZIP_CODE: "zip_code",
  DOMAINS: "domains",
  DEFAULT_EMAIL: "default_email"
};

export const CustomerTableHeaders = {
  [CustomerTableColumns.NAME]: "Company Name",
  [CustomerTableColumns.USER_COUNT]: "Number of Users",
  [CustomerTableColumns.STREET]: "Address",
  [CustomerTableColumns.CITY]: "City",
  [CustomerTableColumns.STATE]: "State or Province",
  [CustomerTableColumns.ZIP_CODE]: "Postal Code",
  [CustomerTableColumns.DOMAINS]: "Domains",
  [CustomerTableColumns.DEFAULT_EMAIL]: "Default Email"
};

export const ModalTitles = {
  SUCCESS: "Success!"
};

export const MarketingTab = {
  Segments: 0,
  Message: 1,
  Overview: 2,
  AuditLogs: 3,
};
