import React from "react";
import { MenuItem } from "@mui/material";
import { StyledFormControl, StyledInputLabel, StyledSelect, StyledMenuProps } from "./AlertUserSelectTable.styled";

const UserTableSelect = ({ users = [], value, onChange, label = "User", disabled = false }) => {
  const handleSelectionChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <StyledFormControl size="small">
      <StyledInputLabel>{label}</StyledInputLabel>
      <StyledSelect
        disabled={disabled}
        value={value}
        onChange={handleSelectionChange}
        label={label}
        MenuProps={StyledMenuProps}
      >
        {users?.map((user) => (
          <MenuItem key={user.id} value={user.id}>
            {user.name}
          </MenuItem>
        ))}
      </StyledSelect>
    </StyledFormControl>
  );
};

export default UserTableSelect;
