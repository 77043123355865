import React, { useState, useEffect, useRef } from "react";
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { IconButton, SuccessfulModal } from "../../../../elements";
import TemplateEditor from "../../../../elements/v2/TemplateEditor/TemplateEditor";
import { useStore } from "../../../../hooks";
import { observer } from "mobx-react";
import {
  RetryContainer,
  RetryHeader,
  RetryRow,
  LabelWrapper,
  InputWrapper,
  NumberInputWrapper,
  ActionsWrapper,
  AddButtonWrapper,
  TimeInputWrapper,
  UnitSelectWrapper,
  EditorContainer,
  GeneratingRetryOverlay,
  SavingOverlay,
} from "./RetryComponent.styled";
import CircularProgress from '@mui/material/CircularProgress';
import { Select, MenuItem } from "@mui/material";

const TIME_UNITS = [
  { value: "minutes", label: "Minutes" },
  { value: "hours", label: "Hours" },
  { value: "days", label: "Days" },
  { value: "months", label: "Months" },
  { value: "years", label: "Years" },
];

const RetryComponent = observer(() => {
  const { marketingStore, retryStore } = useStore();
  const [retries, setRetries] = useState([]);
  
  const originalMessageRef = useRef("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentRetryIndex, setCurrentRetryIndex] = useState(null);
  const [expandedEditorIndex, setExpandedEditorIndex] = useState(null);
  const emailEditorRef = useRef(null);
  const [isEditorReady, setIsEditorReady] = useState(false);
  const [isSavingContent, setIsSavingContent] = useState(false);
  const saveTimeoutRef = useRef(null);

  useEffect(() => {
    const initializeRetries = async () => {
      try {
        if (marketingStore.retries && marketingStore.retries.length > 0) {
          const newRetries = marketingStore.retries.map((retry) => {
            const versionKey = Object.keys(retry)[0];
            const retryData = retry[versionKey];
            if (!retryData.generalData) {
              retryData.generalData = {
                from: "",
                sender_name: "",
                to: "",
                subject: "",
                cc: "",
                bcc: "",
                description: "",
              };
            }
            if (!retryData.variables) {
              retryData.variables = [];
            }
            retry[versionKey] = retryData;
            return retry;
          });
          setRetries(newRetries);
        } else {
          const emailPayload = marketingStore.getEmailPayload();
          const bodyField = emailPayload.find(item => item.field === "body");
          if (bodyField?.value && !originalMessageRef.current) {
            const response = await marketingStore.getEmailTemplateContent(bodyField.value);
            originalMessageRef.current = response || "";
          }
        }
      } catch (error) {
        marketingStore.setError(`Error initializing retries: ${error}`);
      }
    };
    initializeRetries();
  }, [marketingStore.marketingConversation]);

  const logRetries = (updatedRetries) => {
    marketingStore.setRetries(updatedRetries);
    marketingStore.updateMarketingConversation({
      ...marketingStore.marketingConversation,
      retries: updatedRetries
    });
  };

  const addRetry = async () => {
    if (retries.length < 10) {
      const version = `v${retries.length + 1}`;
      const emailPayload = marketingStore.getEmailPayload();
      const bodyField = emailPayload.find(item => item.field === "body");
      try {
        
        let templateContent = "";
        if (originalMessageRef.current) {
          templateContent = originalMessageRef.current;
        } else if (bodyField?.value) {
          const response = await marketingStore.getEmailTemplateContent(bodyField.value);
          templateContent = response || "";
          originalMessageRef.current = templateContent;
        }
        templateContent = templateContent || "";
        const updatedRetries = [
          ...retries,
          {
            [version]: {
              unit: "days",
              value: 1,
              message: templateContent,
            },
          },
        ];
        setRetries(updatedRetries);
        logRetries(updatedRetries);
      } catch (error) {
        marketingStore.setError(`Error adding retry: ${error}`);
        setIsModalOpen(false);
      }
    }
  };

  const removeRetry = (index) => {
    const updatedRetries = retries.filter((_, i) => i !== index);
    setRetries(updatedRetries);
    logRetries(updatedRetries);
  };

  const updateRetry = (index, key, value) => {
    const updatedRetries = retries.map((retry, i) => {
      if (i === index) {
        const versionKey = Object.keys(retry)[0];
        return {
          [versionKey]: { ...retry[versionKey], [key]: value },
        };
      }
      return retry;
    });
    setRetries(updatedRetries);
    logRetries(updatedRetries);
  };

  const handleDaysChange = (index, increment) => {
    const updatedRetries = retries.map((retry, i) => {
      if (i === index) {
        const versionKey = Object.keys(retry)[0];
        return {
          [versionKey]: {
            ...retry[versionKey],
            value: Math.min(10, Math.max(1, retry[versionKey].value + increment)),
          },
        };
      }
      return retry;
    });
    setRetries(updatedRetries);
    logRetries(updatedRetries);
  };

  const handleBodyChange = (index, value) => {
    const updatedRetries = retries.map((retry, i) => {
      if (i === index) {
        const versionKey = Object.keys(retry)[0];
        return {
          [versionKey]: { ...retry[versionKey], message: value },
        };
      }
      return retry;
    });
    setRetries(updatedRetries);
    logRetries(updatedRetries);
  };


  const handleEditorClose = async (index) => {
    const unlayer = emailEditorRef.current?.editor;
    if (unlayer) {
      unlayer.exportHtml((data) => {
        const { html } = data;
        if (html) {
          marketingStore.setTemporaryRetryEdit(index, html);
          const versionKey = Object.keys(retries[index])[0];
          const updatedRetries = retries.map((retry, i) => {
            if (i === index) {
              return {
                [versionKey]: { 
                  ...retry[versionKey],
                  tempContent: html,
                  isModified: true
                },
              };
            }
            return retry;
          });
          setRetries(updatedRetries);
          logRetries(updatedRetries);
        }
      });
    }
    setExpandedEditorIndex(null);
    setIsEditorReady(false);
  };

  const openTemplateEditor = async (index) => {
    try {
      if (expandedEditorIndex === index) {
        handleEditorClose(index);
        return;
      }
      setCurrentRetryIndex(index);
      const versionKey = Object.keys(retries[index])[0];
      const retryData = retries[index][versionKey];
      const temporaryContent = marketingStore.getTemporaryRetryEdit(index);
      if (temporaryContent) {
        const updatedRetries = retries.map((retry, i) => {
          if (i === index) {
            return {
              [versionKey]: { 
                ...retry[versionKey],
                tempContent: temporaryContent,
              },
            };
          }
          return retry;
        });
        setRetries(updatedRetries);
        setExpandedEditorIndex(index);
        return;
      }
      const response = await marketingStore.getCampaignRetryTemplate({
        message: retryData.message,
        unit: retryData.unit,
        value: retryData.value,
        customer_id: marketingStore.marketingConversation.customer,
        campaign_id: marketingStore.marketingConversation.id
      });
      if (!response?.body) {
        throw new Error('No content received from API');
      }
      const updatedRetries = retries.map((retry, i) => {
        if (i === index) {
          return {
            [versionKey]: { 
              ...retry[versionKey],
              tempContent: response.body,
            },
          };
        }
        return retry;
      });
      setRetries(updatedRetries);
      setExpandedEditorIndex(index);
    } catch (error) {
      marketingStore.setError(`Error in openTemplateEditor: ${error}`);
    }
  };

  const updateRetryMessageFromStore = async (index) => {
    try {
      const versionKey = Object.keys(retries[index])[0];
      const currentRetry = retries[index][versionKey];
      const response = await marketingStore.getCampaignRetryTemplate({
        message: currentRetry.message,
        unit: currentRetry.unit,
        value: currentRetry.value,
        customer_id: marketingStore.marketingConversation.customer,
        campaign_id: marketingStore.marketingConversation.id
      });
      const updatedRetries = retries.map((retry, i) => {
        if (i === index) {
          const versionKey = Object.keys(retry)[0];
          return {
            [versionKey]: { 
              ...retry[versionKey], 
              message: response.body,
              tempContent: response.body,
              isApiContent: true
            },
          };
        }
        return retry;
      });
      setRetries(updatedRetries);
      logRetries(updatedRetries);
    } catch (error) {
      marketingStore.setError(`Error updating retry message: ${error}`);
    }
  };

  const handleUnitChange = (index, selectedOption) => {
    updateRetry(index, "unit", selectedOption.value);
  };

  const closeErrorModal = () => {
    marketingStore.clearError();
  };

  useEffect(() => {
    return () => {
      if (saveTimeoutRef.current) {
        clearTimeout(saveTimeoutRef.current);
      }
    };
  }, []);

  return (
    <RetryContainer>
      {retries.map((retry, index) => {
        const versionKey = Object.keys(retry)[0];
        const retryData = retry[versionKey];
        return (
          <div key={index}>
            <RetryHeader>{`Retry ${index + 1}`}</RetryHeader>
            <RetryRow>
              <InputWrapper>
                <LabelWrapper>
                  <label>Delivery Schedule</label>
                  <IconButton icon={"iconInformation"} height="16px" width="16px" />
                </LabelWrapper>
                <TimeInputWrapper>
                  <NumberInputWrapper>
                    <input
                      type="number"
                      value={retryData.value}
                      onChange={(e) => updateRetry(index, "value", Number(e.target.value))}
                      min={1}
                      max={10}
                    />
                    <div className="arrows">
                      <KeyboardArrowUpIcon onClick={() => handleDaysChange(index, 1)} />
                      <KeyboardArrowDownIcon onClick={() => handleDaysChange(index, -1)} />
                    </div>
                  </NumberInputWrapper>
                  <UnitSelectWrapper>
                    <Select
                      value={retryData.unit}
                      onChange={(e) => handleUnitChange(index, { value: e.target.value })}
                      size="small"
                      fullWidth
                    >
                      {TIME_UNITS.map((unit) => (
                        <MenuItem key={unit.value} value={unit.value}>
                          {unit.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </UnitSelectWrapper>
                </TimeInputWrapper>
              </InputWrapper>
              <InputWrapper>
                <LabelWrapper>
                  <label>Message</label>
                  {marketingStore.isGeneratingRetry && currentRetryIndex === index && (
                    <CircularProgress size={16} />
                  )}
                </LabelWrapper>
                <textarea
                  value={retryData.tempContent}
                  onChange={(e) => handleBodyChange(index, e.target.value)}
                  rows={4}
                />
              </InputWrapper>
              <ActionsWrapper>
                <IconButton
                  icon={"iconLess"}
                  height="25px"
                  width="25px"
                  onClick={() => removeRetry(index)}
                  title="Delete retry"
                />
                <IconButton
                  icon={"iconUpdate"}
                  height="25px"
                  width="25px"
                  onClick={() => updateRetryMessageFromStore(index)}
                  title="Update retry with template from store"
                />
                <IconButton
                  icon={"iconAdd"}
                  height="25px"
                  width="25px"
                  onClick={() => openTemplateEditor(index)}
                  title={expandedEditorIndex === index ? "Close Editor" : "Edit retry in Template Editor"}
                />
              </ActionsWrapper>
            </RetryRow>
            {expandedEditorIndex === index && (
              <EditorContainer>
                {isSavingContent && (
                  <SavingOverlay isSaving={isSavingContent}>
                    <CircularProgress size={16} />
                    <span>Saving...</span>
                  </SavingOverlay>
                )}
                <TemplateEditor
                  ref={emailEditorRef}
                  isOpen={true}
                  store={retryStore.templateEditorStore}
                  isPopup={false}
                  key={`editor-${index}-${isEditorReady}`}
                />
              </EditorContainer>
            )}
          </div>
        );
      })}

      <AddButtonWrapper>
        <button onClick={addRetry} disabled={retries.length >= 10}>
          <AddIcon />
          New
        </button>
      </AddButtonWrapper>

      {isModalOpen && !marketingStore.isError && (
        <SuccessfulModal
          isOpen={marketingStore.isError}
          onClose={closeErrorModal}
          title="Error"
          subtitle={marketingStore.errorMessage}
          subtitle2="Please try again later."
        />
      )}
      {marketingStore.isGeneratingRetry && (
        <GeneratingRetryOverlay>
          <CircularProgress />
        </GeneratingRetryOverlay>
      )}
    </RetryContainer>
  );
});

export default RetryComponent;
