import React from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

export function LoadingButton({
  loading,
  children,
  progressProps,
  ...buttonProps
}) {
  return (
    <Button
      {...buttonProps}
      disabled={loading || buttonProps.disabled}
    >
      <>
        {loading && (
          <CircularProgress size={24} {...progressProps} />
        )}
        {children}
      </>
    </Button>
  );
}
