import styled from "styled-components";

const SideBar2 = styled.div`
    display: flex;
    background: ${props => props.theme.colors.silverGrayThree};
    transition: width 0.3s ease, transform 0.3s ease;
    width: 100%;
    max-width: 358px;
    overflow: hidden;
    padding: 0.625rem;
    flex-direction: column;
    height: calc(100vh - 48px - 1.75rem);
    gap: 1rem;

    @media (max-width: 1024px) {
        position: fixed;
        top: 50px;
        right: 0;
        bottom: 0;
        z-index: 1000;
        transform: translateX(${props => props.isExpanded ? '0' : '100%'});
        width: ${props => props.isExpanded ? '280px' : '60px'};
        height: calc(100vh - 50px);
    }

    // ... rest of the styles remain the same
`;

const Sidebar2HeaderContent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    background: ${(props) => props.theme.colors.highClearGray};
    height: 3.5rem;
    padding: 0.5rem;
    gap: 0.5rem;

    @media (min-width: 768px) {
        height: 4.25rem;
        padding: 0.5rem 0.9375rem;
        gap: 0.9375rem;
    }

    @media (max-width: 767px) {
        height: 3rem;
        padding: 0.375rem;
        gap: 0.375rem;
    }
`;

const Sidebar2HeaderTextContainer = styled.div`
    order: 0;
    flex: 1;
    align-self: center;
    display: flex;
    flex-direction: column;
`;

const Sidebar2AHeaderAgentName = styled.div`
    font-weight: normal;
    color: ${props => props.theme.colors.black};
    overflow: hidden; 
    text-overflow: ellipsis;
    font-size: clamp(0.875rem, 2.5vw, 1rem);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    white-space: break-spaces;
`;

const Sidebar2StatusContainer = styled.div`
    order: 0;
    flex: 1;
    align-self: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const Sidebar2AgentStatus = styled.div`
    order: 0;
    flex: 1;
    align-self: auto;
    color: ${props => props.theme.colors.black};
    font-weight: normal;
    display: flex;
    flex-direction: row;
    gap: 0.375rem;
    justify-content: flex-start;
    font-size: clamp(0.75rem, 2vw, 0.875rem);
`;

const Sidebar2Description = styled.div`
    display: flex;
    margin-top: 10%;
    padding: 0 0.9375rem;
`;

const CircleActive = styled.div`
    width: 0.625rem;
    height: 0.625rem;
    background-color: ${props => props.isActive ? props.theme.colors.caribbeanGreen : props.theme.colors.silverGrayFour};
    border-radius: 50%;
    display: block;
    margin: auto;
`;

const CircleSeparator = styled.div`
    width: 0.5rem;
    height: 0.5rem;
    background-color: ${props => props.theme.colors.silverGrayFour};
    border-radius: 50%;
    display: block;
    margin: auto 0.6875rem;
`;

const Sidebar2DescriptionTitle = styled.div`
    font-weight: 500;
    color: ${props => props.theme.colors.black};
    margin-bottom: 0;
    font-size: clamp(0.75rem, 2vw, 0.875rem);
`;

const Sidebar2DescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.625rem;

    & > div:first-child {
        margin-top: 0;
    }
`;

const Sidebar2DescriptionText = styled.div`
    color: ${props => props.theme.colors.black};
    text-align: justify;    
    padding: 0 0.5rem;

    @media (min-width: 768px) {
        padding: 0 0.9375rem;
    }

    font-size: clamp(0.6875rem, 1.8vw, 0.75rem);
`;

const Sidebar2KnowledgeBase = styled.div`
    padding: 0 0.5rem;

    @media (min-width: 768px) {
        padding: 0 0.9375rem;
    }
`;

const Sidebar2KnowledgeBaseTitle = styled.p`
    margin: 0;
    font-weight: 500;    
`;

const Sidebar2ActionContainer = styled.div`
    padding: 0 0.5rem;

    @media (min-width: 768px) {
        padding: 0 0.9375rem;
    }

    & p:first-child {
        font-size: 0.75rem;
    }

    & p:nth-child(2) {
        font-size: 0.625rem;
    }

    & div > div > p:nth-child(1) {
        font-size: 0.875rem;
    }

    &.sidebar2-actions > div > ul {
        overflow-y: auto;
        scrollbar-width: thin;
        scroll-margin-left: 0.5rem;
            
        scrollbar-color: ${props => props.theme.colors.thumbGray} ${props => props.theme.colors.highClearGray};
        max-height: 18.75rem;

        @media (min-width: 768px) {
            max-height: 20.9375rem;
        }
    }

    &.sidebar2-actions > div > ul > div {
        margin-right: 0.5rem;        
    }
`;

const Sidebar2KnowledgeBaseBody = styled.div`
    max-height: 16.875rem;
    overflow-y: auto;
    scrollbar-width: thin;
    scroll-margin-left: 0.5rem;
    scrollbar-color: ${props => props.theme.colors.thumbGray} ${props => props.theme.colors.highClearGray};
    margin-top: 0.625rem;
`;

const Sidebar2HistoryContainer = styled.div`
    padding: 0.5rem;
    overflow-y: auto;
    flex-grow: 1;
    flex-shrink: 0;
    max-height: calc(100vh - 12rem);
    scrollbar-width: thin;
    scroll-margin-left: 0.5rem;
    scrollbar-color: ${props => props.theme.colors.thumbGray} ${props => props.theme.colors.highClearGray};

    @media (min-width: 768px) {
        padding: 0.9375rem;
        max-height: calc(100vh - 16.125rem);
    }

    @media (max-width: 767px) {
        padding: 0.375rem;
        max-height: calc(100vh - 10rem);
    }
`;

const Sidebar2HistoryGroup = styled.div`
    margin-bottom: 2.1875rem;
`;

const Sidebar2HistoryTitle = styled.p`
    margin: 0;
    color: ${props => props.theme.colors.darkGray};
    font-size: 0.75rem;
`;

const Sidebar2HistoryText = styled.p`
    margin: 0;
    color: ${props => props.theme.colors.darkGray};
    font-size: clamp(0.875rem, 2.5vw, 1.0625rem);
    cursor: pointer;

    @media (min-width: 768px) {
        font-size: 1.0625rem;
    }
`;

const Sidebar2HistoryWrapper = styled.div`
    display: flex;
    flex-direction: column;
    cursor: pointer;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    background: ${props => props.theme.colors.highClearGray};

    @media (min-width: 768px) {
        padding: 0.625rem;
        margin-bottom: 0.625rem;
    }

    @media (max-width: 767px) {
        padding: 0.375rem;
        margin-bottom: 0.375rem;
    }
`;

const Sidebar2HistoryDate = styled.p`
    margin: 0;
    font-size: 0.7em;
`;

const DetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: clamp(1rem, 3vw, 1.5rem);
`;

const SliceButton = styled.button`
    display: none;
    position: fixed;
    top: 60px;
    right: 10px;
    z-index: 1001;
    background: ${props => props.theme.colors.silverGrayThree};
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);

    @media (max-width: 1024px) {
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

const ProfileImage = styled.img`
    height: ${(props) => (props.isMobile ? '32px' : '40px')};
    width: ${(props) => (props.isMobile ? '32px' : '40px')};
    border-radius: 50%;
    object-fit: cover;
`;

export {
    SideBar2,
    Sidebar2HeaderContent,
    Sidebar2HeaderTextContainer,
    Sidebar2AHeaderAgentName,
    Sidebar2StatusContainer,
    Sidebar2AgentStatus,
    Sidebar2Description,
    CircleActive,
    CircleSeparator,
    Sidebar2DescriptionTitle,
    Sidebar2DescriptionText,
    Sidebar2KnowledgeBase,
    Sidebar2ActionContainer,
    Sidebar2KnowledgeBaseBody,
    Sidebar2HistoryContainer,
    Sidebar2HistoryTitle,
    Sidebar2HistoryGroup,
    Sidebar2HistoryText,
    Sidebar2HistoryWrapper,
    Sidebar2HistoryDate,
    DetailsContainer,
    Sidebar2DescriptionContainer,
    SliceButton,
    ProfileImage,
};