import { useState, useEffect } from "react";

export const useCustomerForm = (initialCustomer) => {
  const [editedCustomer, setEditedCustomer] = useState({
    name: "",
    street: "",
    city: "",
    state: "",
    zip_code: "",
    domains: [],
    default_email: "",
    site_url: "",
  });

  const [domainFields, setDomainFields] = useState([{ id: 1, value: "" }]);

  useEffect(() => {
    if (initialCustomer) {
      setEditedCustomer({
        name: initialCustomer.name || "",
        street: initialCustomer.street || "",
        city: initialCustomer.city || "",
        state: initialCustomer.state || "",
        zip_code: initialCustomer.zip_code || "",
        domains: initialCustomer.domains || [],
        default_email: initialCustomer.default_email || "",
        site_url: initialCustomer.site_url || "",
      });

      if (initialCustomer.domains?.length > 0) {
        setDomainFields(
          initialCustomer.domains.map((domain, index) => ({
            id: index + 1,
            value: domain,
          })),
        );
      } else {
        setDomainFields([{ id: 1, value: "" }]);
      }
    }
  }, [initialCustomer]);

  const resetForm = () => {
    setEditedCustomer({
      name: "",
      street: "",
      city: "",
      state: "",
      zip_code: "",
      domains: [],
      default_email: "",
      site_url: "",
    });
    setDomainFields([{ id: 1, value: "" }]);
  };

  return {
    editedCustomer,
    setEditedCustomer,
    domainFields,
    setDomainFields,
    resetForm,
  };
};
