import React, { useEffect, useRef, useState } from 'react';
import { useStore } from "../../../../hooks";
import { observer } from "mobx-react";
import { TemplateContainer } from "./Marketing.styled";
import TemplateEditor from "../../../../elements/v2/TemplateEditor/TemplateEditor";
import { SuccessfulModal } from '../../../../elements';
import {EmailTemplateWrapper, MessageEditorError} from "./Message.styled";
import {MarketingTab} from "../../../../utils/enums";

const Message = observer(() => {
  const { authStore, messageEditorStore, marketingStore, contactSegmentStore } = useStore();

  const initialLoadRef = useRef(false);

  useEffect(() => {
    messageEditorStore.updateGeneralField("customer", authStore.selectedAccount.id);
    messageEditorStore.updateGeneralField("s3FileRoute", "email_templates");
    messageEditorStore.templateEditorStore.updateField("to", "{{To}}");
  }, []);


  // Load initial template content
  useEffect(() => {
    const loadInitialContent = async () => {
      try {
        if (!initialLoadRef.current && messageEditorStore.templateId) {
          const emailPayload = marketingStore.getEmailPayload();
          const bodyField = emailPayload.find(item => item.field === "body");
          
          if (bodyField?.value) {
            const content = await marketingStore.getEmailTemplateContent(bodyField.value);
            if (content && !marketingStore.mainEmailContent) {
              marketingStore.setMainEmailContent(content);
            }
            initialLoadRef.current = true;
          }
        }
      } catch (error) {
        // Keeping error handling but removing console.error
      }
    };

    void loadInitialContent();
  }, [messageEditorStore.templateId, marketingStore]);

  // Handle template editor initialization
  useEffect(() => {
    if (messageEditorStore.templateId) {
      messageEditorStore.loadTemplate();
    }

    return () => {
      messageEditorStore.updateGeneralField("isLoading", false);
    };
  }, [messageEditorStore.templateId]);

  const onExportEditor = async () => {
    try {
      marketingStore.setMarketingAttribute("isLoading", true);

      const { html, design } = messageEditorStore.templateEditorStore.exportData();

      if (messageEditorStore.templateEditorStore.requiredFields) {
        marketingStore.setCurrentDesign(design);
        marketingStore.setMainEmailContent(html);

        messageEditorStore.savePayload();

        await messageEditorStore.saveTemplate(marketingStore.mainEmailContent, marketingStore.currentDesign);

        marketingStore.updateBindingsAndPayload(
          messageEditorStore.payload.payload,
          messageEditorStore.payload.binding,
        );

        await marketingStore.save(
          authStore.selectedAccount.id,
          contactSegmentStore.advancedFilters,
          contactSegmentStore.totalRowCount
        );

        marketingStore.setMarketingAttribute("isLoading", false);
      } else {
        if (marketingStore.currentTab !== MarketingTab.Message){
          marketingStore.setError(`Missing complete information into Message Tab`);
        }
        messageEditorStore.templateEditorStore.showRequiredFieldsError();
        marketingStore.setMarketingAttribute("isLoading", false);
      }
    } catch (error) {
      marketingStore.setError(`Error saving template:', ${error}`);
      marketingStore.setMarketingAttribute("isLoading", false);
    }
  };

  marketingStore.setEmailTemplateExportAction(onExportEditor);

  return (

    <TemplateContainer>
      <EmailTemplateWrapper>
        {
          messageEditorStore.templateEditorStore.requiredFieldsError &&
          (
            <MessageEditorError>One or more required fields are missing. Please review the form and complete all mandatory fields before submitting.</MessageEditorError>
          )
        }
        <TemplateEditor
          store={messageEditorStore.templateEditorStore}
          key={`main-message-${messageEditorStore.templateId}-${!!marketingStore.mainEmailContent}`}
          isPopup={false}
        />
      </EmailTemplateWrapper>
      {marketingStore.isError && (
        <SuccessfulModal
          isOpen={marketingStore.isError}
          onClose={() => marketingStore.clearError()}
          title="Error"
          subtitle={marketingStore.errorMessage}
          subtitle2="Please try again later."
        />
      )}
    </TemplateContainer>
  );
});

export default Message;
