import {Backdrop, ButtonWrapper, TemplateEditorFooter, TemplateEditorWrapper} from "./TemplateEditor.styled";
import BasedTemplateEditor from "./BasedTemplateEditor";
import {RoundedButton} from "../../button";
import {useCallback} from "react";
import {useTemplateEditorStore} from "./hook";

const ModalTemplateEditor = ({ isOpen, isReadMode, onClose, onSave }) => {
  const store = useTemplateEditorStore();

  const onHandleClose = useCallback(() => {
    onClose && onClose();
  }, [onClose]);

  const onHandleExport = useCallback(() => {
    const dataToExport = store.exportData();
    onSave && onSave(dataToExport);
  }, [onSave]);

  return (
    <Backdrop isOpen={isOpen}>
      <TemplateEditorWrapper>
        <BasedTemplateEditor />
        <TemplateEditorFooter>
          <RoundedButton
            width="79px"
            kind="secondary"
            onClick={onHandleClose}
          >
            Cancel
          </RoundedButton>
          {!isReadMode && (
            <ButtonWrapper>
              <RoundedButton
                width="89px"
                onClick={onHandleExport}
                disabled={false}
              >
                Update
              </RoundedButton>
            </ButtonWrapper>
          )}
        </TemplateEditorFooter>
      </TemplateEditorWrapper>
    </Backdrop>
  );
};

export default ModalTemplateEditor;
