import React from 'react'
import {WarningAlert, ContentAlert, AlertTitle, AlertMessage} from "./Alerts.styled";

const Alerts = ({top, right, left, width, title, message, severity, onClose}) => {
  return (
    <WarningAlert
      severity={severity}
      width={width}
      top={top}
      right={right}
      left={left}
      onClose={onClose}
    >
      <ContentAlert>
          {title && <AlertTitle>{title}</AlertTitle>}
          <AlertMessage>{message}</AlertMessage>
      </ContentAlert>
    </WarningAlert>
  )
}

export default Alerts
