function fixDateFormat(dateString) {
  let fixedString = dateString.replace(" ", "T");

  fixedString = fixedString.replace(/(\.\d{3})\d+/, "$1");

  if (!fixedString.includes("Z") && !fixedString.match(/([+-]\d{2}:\d{2})$/)) {
    fixedString += "Z";
  }

  return new Date(fixedString);
}
export default function timeAgo(dateString) {
  const date = fixDateFormat(dateString);
  const now = new Date();
  const seconds = Math.round((date - now) / 1000);

  const rtf = new Intl.RelativeTimeFormat("en", { numeric: "auto" });

  const intervals = {
    year: 31536000,
    month: 2592000,
    week: 604800,
    day: 86400,
    hour: 3600,
    minute: 60,
    second: 1,
  };

  for (const [unit, secondsInUnit] of Object.entries(intervals)) {
    if (Math.abs(seconds) >= secondsInUnit || unit === "second") {
      return rtf.format(Math.round(seconds / secondsInUnit), unit);
    }
  }
}
