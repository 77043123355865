import { action, makeObservable, observable, flow, toJS } from "mobx";
import { API } from "aws-amplify";
import {
	deleteMarketingCampaign,
  getCampaigns,
  getUploadUrlFile,
  getUrlFile,
  putCampaignMarketing,
  getCampaignRetryTemplate
  
} from "../../graphql/queries";
import _ from "lodash";
import dayjs from "dayjs";
import {v4 as uuidv4} from 'uuid';
import axios from "axios";
import {MarketingStatus, MarketingTab} from "../../utils/enums";

class MarketingStore {
	isLoading = false;
	isError = false;
	isSuccessful = false;
	currentTab = MarketingTab.Segments;
	emailTemplatePayload = [];
	emailTemplateBinding = [];
	selectedAgent = null;
	marketingConversations = [];
	marketingConversation = {};
	emailTemplateExportAction = null;
	name = "";
	description = "";
	startDate = null;
    endDate = null;
    startTime = null;
    endTime = null;
    minEndTime = null;
	nameRead = "";
	descriptionRead = "";
    startDateRead = null;
    endDateRead = null;
    startTimeRead = null;
    endTimeRead = null;
	activeCampaign = true;
	status = MarketingStatus.DRAFT;
	confirmationDialogMessage = null;
	contactFile = {};
	numberSegments = 0;
	contactCSVFile = null;
	successfulAlert = { isOpen: false, title: '', message: '' };
	selectedRows = [];
	templateMessage = "";
	currentTemplate = "";
	isGeneratingRetry = false;
	retries = [];
	isError = false;
  	errorMessage = "";
	mainEmailContent = null;
	currentDesign = null;
	temporaryRetryEdits = {};

	constructor() {
		makeObservable(this, {
			isLoading: observable,
			isError: observable,
			currentTab: observable,
			isSuccessful: observable,
			selectedAgent: observable,
			marketingConversations: observable,
			marketingConversation: observable,
			emailTemplateExportAction: observable,
			name: observable,
			description: observable,
			nameRead: observable,
			descriptionRead: observable,
			startDateRead: observable,
            endDateRead: observable,
            startTimeRead: observable,
            endTimeRead: observable,
			startDate: observable,
            endDate: observable,
            startTime: observable,
            endTime: observable,
			activeCampaign: observable,
			status: observable,
			confirmationDialogMessage: observable,
			contactFile: observable,
			numberSegments: observable,
			contactCSVFile: observable,
			successfulAlert: observable,
			selectedRows: observable,
			setMarketingAttribute: action,
			clear: action,
			onClearError: action,
			setEmailTemplateExportAction: action,
			setSelectedAgent: action,
			update: action,
			clearConfirmationDialog: action,
			getMarketingConversations: flow,
			newMarketingConversation: flow,
			save: flow,
			cancel: flow,
			duplicate: flow,
			duplicateS3File: flow,
			deleteCampaignMarketing: flow,
			getCampaignRetryTemplate: flow,
			templateMessage: observable,
			setTemplateMessage: action,
			setCurrentTab: action,
			getTemplateMessage: action,
			getEmailBody: action,
			getEmailPayload: action,
			currentTemplate: observable,
			isGeneratingRetry: observable,
			setIsGeneratingRetry: action,
			retries: observable,
			setRetries: action,
			updateMarketingConversation: action,
			clearError: action,
			setError: action,
      		errorMessage: observable,
			mainEmailContent: observable,
			setMainEmailContent: action,
			setCurrentTemplate: action,
			currentDesign: observable,
			setCurrentDesign: action,
			temporaryRetryEdits: observable,
			setTemporaryRetryEdit: action,
			getTemporaryRetryEdit: action,
			clearTemporaryRetryEdits: action,
			saveFileToS3: flow
		});
	}

	setCurrentTab(tab) {
		this.currentTab = tab;
	}

	clearConfirmationDialog(){
		this.confirmationDialogMessage = null;
	}

	update(){
        this.nameRead = this.name;
		this.descriptionRead = this.description;
        this.startDateRead = this.startDate.set('hour', this.startTime?.get('hour') || 0 ).set('minute', this.startTime?.get('minute') || 0);
        this.endDateRead = this.endDate && this.endTime ? this.endDate.set('hour', this.endTime?.get('hour')).set('minute', this.endTime.get('minute')) : "";
    }

	*deleteCampaignMarketing(customer, id){
		try {
			this.isLoading = true;
			this.isError = false;

			return yield API.graphql({
				query: deleteMarketingCampaign,
				variables: { 
					input: { 
						customer,
						id
					} 
				},
				authMode :'AMAZON_COGNITO_USER_POOLS'
			});
		} catch (error) {
			this.isError = true;
		} finally {
			this.isLoading = false;
		}
	}

	*newMarketingConversation(customer){
		try {
			this.isLoading = true;
			this.isError = false;

			// Create initial campaign object with required fields
			const campaignObject = {
				customer, 
				name: "New Marketing Campaign",
				start_datetime: dayjs().format(), // Format the date
				end_datetime: dayjs().add(1, 'day').format(), // Format the date
				status: MarketingStatus.DRAFT,
				metadata: JSON.stringify({
					description: "This is a marketing campaign",
					segment_count: 0,
					filters: [],
					actions: [{
						action: "send_email",
						id: uuidv4(),
						binding: [],
						payload: []
					}]
				}),
				contact_file: JSON.stringify({}), 
				agent_id: "",
				retries: JSON.stringify([]) // Initialize empty retries array
			};

			const response = yield API.graphql({
				query: putCampaignMarketing,
				variables: { 
					input: campaignObject
				},
				authMode :'AMAZON_COGNITO_USER_POOLS'
			});

			const newConversation = JSON.parse(response.data?.putCampaignMarketing?.body);

			return newConversation.id;

		} catch (error) {
			console.error('Error creating new campaign:', error);
			this.isError = true;
			throw error;
		} finally {
			this.isLoading = false;
		}
	}

	*getMarketingConversations(customer, id = "") {
		try {
		  this.isLoading = true;
		  this.isError = false;
	  
		  const response = yield API.graphql({
			query: getCampaigns,
			variables: { input: { customer, id } },
			authMode: "AMAZON_COGNITO_USER_POOLS",
		  });
		  const conversations = JSON.parse(response.data?.getCampaigns?.body);
	  
		  if (id) {
			this.marketingConversation = conversations;
			this.selectedAgent = conversations.agent_id;
			this.name = this.nameRead = conversations.name;
			this.description = this.descriptionRead = conversations.metadata?.description;
			this.startDateRead = this.startDate = this.startTime = conversations.start_datetime ? dayjs(conversations.start_datetime) : null;
			this.endDateRead = this.endDate = this.endTime = conversations.end_datetime ? dayjs(conversations.end_datetime) : null;
			this.activeCampaign = conversations.status === MarketingStatus.ACTIVE;
			this.contactFile = conversations.contact_file;
			this.status = conversations.status;
			this.contactCSVFile = conversations.metadata?.uploaded_contact_file;
			this.retries = Object.keys(conversations.first_message).length
			  ? Object.entries(conversations.first_message).map(([key, value]) => ({ [key]: value }))
			  : [];
		  } else {
			this.marketingConversations = Array.isArray(conversations) && conversations.length > 0
			  ? [...conversations].sort((a, b) => {
				  const aActivity = new Date(a.last_modified || a.insert_date);
				  const bActivity = new Date(b.last_modified || b.insert_date);
				  return bActivity - aActivity;
				})
			  : [];
		  }
		} catch (error) {
		  this.isError = true;
		} finally {
		  this.isLoading = false;
		}
	  }
	  
	  

	updateBindingsAndPayload(payload = [], bindings = []) {
		this.emailTemplatePayload = _.cloneDeep(payload);
		this.emailTemplateBinding = _.cloneDeep(bindings);
	}

	setMarketingAttribute(attribute, value) {
		this[attribute] = value;
	}

	clear() {
		this.isLoading = false;
		this.isError = false;
		this.isSuccessful = false;
		this.emailTemplatePayload = [];
		this.emailTemplateBinding = [];
		this.selectedAgent = null;
		this.marketingConversations = [];
		this.marketingConversation = {};
		this.emailTemplateExportAction = null;
		this.name = "";
		this.description = "";
		this.startDate = null;
		this.endDate = null;
		this.startTime = null;
		this.endTime = null;
		this.minEndTime = null;
		this.nameRead = "";
		this.descriptionRead = "";
		this.startDateRead = null;
		this.endDateRead = null;
		this.startTimeRead = null;
		this.endTimeRead = null;
		this.activeCampaign = true;
		this.status = MarketingStatus.DRAFT;
		this.confirmationDialogMessage = null;
		this.contactFile = {};
		this.numberSegments = 0;
		this.contactCSVFile = null;
		this.successfulAlert = { isOpen: false, title: '', message: '' };
		this.selectedRows = [];
		this.mainEmailContent = null;
		this.currentTemplate = "";
		this.currentDesign = null;
	}

	onClearError() {
		this.isError = false;
	}

	setSelectedAgent(id){
		this.selectedAgent = id;
	}

	setEmailTemplateExportAction(action) {
		this.emailTemplateExportAction = action;
	}

	*save(customer, filters, segment_count) {
		try {
			this.isLoading = true;
			this.isError = false;

			// Process and save retry templates
			const processedRetries = yield Promise.all(
				this.retries.map(async (retry, index) => {
					const versionKey = Object.keys(retry)[0];
					const retryData = retry[versionKey];

					// Only save if there's content to save
					if (retryData.tempContent || retryData.message) {
						const retryTemplateId = uuidv4();
						const retryPrefix = `${this.marketingConversation.id.replaceAll('#', '')}/retries/retry_${index + 1}`;
						const retryHtmlFile = `${retryPrefix}.html`;
						const retryJsonFile = `${retryPrefix}.json`;

						// Create default design if none exists
						const defaultDesign = {
							body: {
								rows: [{
									cells: [1],
									columns: [{
										contents: [{
											type: "text",
											values: {
												text: retryData.tempContent || retryData.message
											}
										}]
									}],
									values: {}
								}],
								values: {
									backgroundColor: "#ffffff",
									width: "600px",
									padding: "20px"
								}
							}
						};

						// Save retry HTML content
						await this.saveFileToS3(
							customer,
							retryHtmlFile,
							retryData.tempContent || retryData.message,
							'text/html',
							'email_templates'
						);

						// Always save a design file
						await this.saveFileToS3(
							customer,
							retryJsonFile,
							JSON.stringify(retryData.design || defaultDesign),
							'application/json',
							'email_templates'
						);

						const updatedRetry = {
							[versionKey]: {
								...retryData,
								templateId: retryTemplateId,
								message: `retries/retry_${index + 1}.html`,
								design: retryJsonFile,
								designData: retryData.design || defaultDesign
							}
						};

						return updatedRetry;
					}
					return retry;
				})
			);

			// Create campaign object with updated file references
			const campaignObject = {
				name: this.nameRead,
				id: this.marketingConversation.id,
				customer,
				start_datetime: this.startDate.set('hour', this.startTime?.get('hour') || 0).set('minute', this.startTime?.get('minute') || 0),
				end_datetime: this.endDate.set('hour', this.endTime?.get('hour') || 0).set('minute', this.endTime.get('minute') || 0),
				status: this.status,
				metadata: JSON.stringify({
					description: this.descriptionRead,
					segment_count: filters.length ? segment_count : (this.numberSegments || this.marketingConversation.metadata?.segment_count),
					filters: filters,
					actions: [{
						action: "send_email",
						id: this.marketingConversation.metadata?.actions?.[0]?.id || uuidv4(),
						binding: this.emailTemplateBinding,
						payload: this.emailTemplatePayload
					}],
					uploaded_contact_file: filters.length ? null : this.contactCSVFile
				}),
				contact_file: filters.length ? JSON.stringify({}) : JSON.stringify(this.contactFile),
				agent_id: this.selectedAgent,
				retries: JSON.stringify(processedRetries)
			};

			const response = yield API.graphql({
				query: putCampaignMarketing,
				variables: { input: campaignObject },
				authMode: 'AMAZON_COGNITO_USER_POOLS'
			});

			const updatedConversation = JSON.parse(response.data?.putCampaignMarketing?.body);
			
			this.isSuccessful = true;
			this.marketingConversation = updatedConversation;
			this.status = updatedConversation.status;
			this.activeCampaign = this.status === MarketingStatus.ACTIVE;
			this.retries = processedRetries;

		} catch (error) {
			this.setError('Error saving campaign');
			this.isError = true;
		} finally {
			this.isLoading = false;
		}
	}

	*saveFileToS3(customer, fileName, content, contentType, fileRoute = 'marketing_conversations') {

		try {
			// Get upload URL
			const responsePut = yield API.graphql({
				query: getUploadUrlFile,
				variables: {
					input: {
						customer,
						file_name: fileName,
						file_type: contentType,
						file_route: fileRoute,
						overwrite: true
					},
				},
				authMode: 'AMAZON_COGNITO_USER_POOLS'
			});

			const uploadUrl = JSON.parse(responsePut.data.getUploadUrlFile?.body);
			
			// Create blob from content
			const blobFile = new Blob([content], { type: contentType });
			const config = {
				headers: {
				  "Content-Type": contentType,
				},
			  };

			yield axios.put(uploadUrl, blobFile, config);

		} catch (error) {
			throw error;
		}
	}

	*cancel(customer){
		try {
			this.isLoading = true;
			this.isError = false;

			const response = yield API.graphql({
				query: putCampaignMarketing,
				variables: { 
					input: {
						id: this.marketingConversation.id,
						customer, 
						status: MarketingStatus.CANCELLED,
					} 
				},
				authMode :'AMAZON_COGNITO_USER_POOLS'
			});

			const updatedConversation = JSON.parse(response.data?.putCampaignMarketing?.body);
			
			this.isSuccessful = true;
			this.marketingConversation = updatedConversation;
			this.status = updatedConversation.status
			this.activeCampaign = this.status === MarketingStatus.ACTIVE

		} catch (error) {
			this.isError = true;
		} finally {
			this.isLoading = false;
		}

	}

	*duplicate(conversation){
		try {
			this.isLoading = true;
			this.isError = false;
			
			// 1. Create the new campaign based on another one, including the same metadata
			const response = yield API.graphql({
				query: putCampaignMarketing,
				variables: { 
					input: { 
						customer: conversation.customer, 
						name: `Copy of ${conversation.name}`,
						start_datetime: conversation.start_datetime,
						end_datetime: conversation.end_datetime,
						status: MarketingStatus.DRAFT,
						metadata: JSON.stringify(conversation.metadata),
						contact_file: JSON.stringify(conversation.contact_file), 
						agent_id: conversation.agent_id
					} 
				},
				authMode :'AMAZON_COGNITO_USER_POOLS'
			});
			const newConversation = JSON.parse(response.data?.putCampaignMarketing?.body);
			
			// 2. Find if the campaign to be duplicated contains actions, and pick the first action that is of send_email type
			const action = newConversation.metadata.actions?.filter(action => action.action === 'send_email')[0];

			// 3. If an action is found, will make sure to duplicated its content from the original campaign
			//    instead of using the same exact info such as filenames. Otherwise, duplicated operation will
			//    be done.
			if(action){
				// 4. Find body and template parameters which contains the file name for the email template used by the original campaign
				const bodyFilePath = action.payload.filter(field => field.field === 'body').pop();
				const templateFilePath = action.payload.filter(field => field.field === 'template').pop();
				// 5. Generate a new file name for the soon to be duplicated files
				const filePrefix = `${newConversation.id}/${newConversation.id}`.replaceAll('#', '');

				if(bodyFilePath.value && templateFilePath.value){
					//  6. Duplicate the html and json email template files for the new duplicated campaign
					yield this.duplicateS3File(conversation.customer, bodyFilePath.value, `${filePrefix}.html`, 'text/html', 'email_templates');
					yield this.duplicateS3File(conversation.customer, templateFilePath.value, `${filePrefix}.json`, 'application/json');
					
					// 7. Generate and replace new id for the duplicated action
					action.id = uuidv4();
					// 8. Replace the "old" template files with the newly duplicated ones
					action.payload = [
						...action.payload.filter(field => !['body', 'template'].includes(field.field)),
						{field: 'body', value: `${filePrefix}.html` },
						{field: 'template', value: `${filePrefix}.json` },
					]
				}

				// 9. Update the duplicated campaign with the new data
				yield API.graphql({
					query: putCampaignMarketing,
					variables: { 
						input: {
							id: newConversation.id,
							customer: newConversation.customer,
							metadata: JSON.stringify(newConversation.metadata),
						} 
					},
					authMode :'AMAZON_COGNITO_USER_POOLS'
				});

			}

			this.successfulAlert = {
				isOpen: true,
				title: 'Great!',
				message: 'The conversation has been duplicated and is now available as a separate copy!'
			}

		} catch (error) {
			this.successfulAlert = {
				isOpen: true,
				title: 'Oops!',
				message: 'An error occurred while duplicating the campaign!'
			}
		} finally {
			this.isLoading = false;
		}
	}

	*duplicateS3File(customer, file_name, new_file_name, type, file_route='marketing_conversations'){
		const response = yield API.graphql({
		  query: getUrlFile,
		  variables: {
			input: {
			  customer,
			  file_name,
			  file_route,
			},
		  },
		  authMode: 'AMAZON_COGNITO_USER_POOLS'
		});
	
		const getUrl = JSON.parse(response.data.getUrlFile?.body);
		const res = yield axios({ url: getUrl, method: 'GET', responseType: 'blob' })
		const blobFile = new Blob([res.data], {type});

		const responsePut = yield API.graphql({
		  query: getUploadUrlFile,
		  variables: {
			input: {
			  customer,
			  file_name: new_file_name,
			  file_type: type,
			  file_route,
			  overwrite: true
			},
		  },
		  authMode: 'AMAZON_COGNITO_USER_POOLS'
		});
	
		const uploadUrl = JSON.parse(responsePut.data.getUploadUrlFile?.body);
		const config = {
		  headers: {
			"Content-Type": type,
		  },
		};
	
		yield axios.put(uploadUrl, blobFile, config);

	}

	*getCampaignRetryTemplate(retry){
		try {
			this.setIsGeneratingRetry(true);
			
			const response = yield API.graphql({
				query: getCampaignRetryTemplate,
					variables: { 
						input: {
								message: retry.message,
								unit: retry.unit,
								value: retry.value,
								customer_id: retry.customer_id,
								campaign_id: retry.campaign_id
							} 
					},
					authMode :'AMAZON_COGNITO_USER_POOLS'
			});

			return response.data?.getCampaignRetryTemplate;

		} catch (error) {
			this.isError = true;
			throw error;
		} finally {
			this.setIsGeneratingRetry(false);
		}
	}

	setTemplateMessage(message) {
		this.templateMessage = message;
	}
	
	getTemplateMessage() {
		return this.templateMessage;
	}

	getEmailBody() {
		const bodyField = this.emailTemplatePayload.find((item) => item.field === "body");
		return bodyField ? bodyField.value : "";
	}
	
	getEmailPayload() {
		return this.emailTemplatePayload;
	}

	setCurrentTemplate(template) {
		this.currentTemplate = template;
	}
	
	setIsGeneratingRetry(value) {
		this.isGeneratingRetry = value;
	}

	async getEmailTemplateContent(fileName) {
		try {
			
			const response = await API.graphql({
				query: getUrlFile,
				variables: {
					input: {
						customer: this.marketingConversation.customer,
						file_name: fileName,
						file_route: 'email_templates',
					},
				},
				authMode: 'AMAZON_COGNITO_USER_POOLS'
			});

			const getUrl = JSON.parse(response.data.getUrlFile?.body);
			
			try {
				const res = await axios.get(getUrl);
				
				if (res.data) {
					this.setMainEmailContent(res.data);
					return res.data;
				}
			} catch (fetchError) {
				// If the file doesn't exist, return a default template structure
				const defaultContent = {
					body: {
						rows: [{
							cells: [1],
							columns: [{
								contents: [{
									type: "text",
									values: {
										text: ""
									}
								}]
							}],
							values: {}
						}],
						values: {
							backgroundColor: "#ffffff",
							width: "600px",
							padding: "20px"
						}
					}
				};
				
				// Save the default template
				await this.saveFileToS3(
					this.marketingConversation.customer,
					fileName,
					JSON.stringify(defaultContent),
					'application/json',
					'email_templates'
				);
				
				return defaultContent;
			}
			
		} catch (error) {
			return null;
		}
	}

	setRetries(retries) {
		this.retries = retries;
	}

	updateMarketingConversation(updatedConversation) {
		this.marketingConversation = updatedConversation;
		this.retries = updatedConversation.retries;
	}

	clearError() {
		this.isError = false;
		this.errorMessage = "";
	}

	setError(message) {
		this.isError = true;
		this.errorMessage = message;
	}

	setMainEmailContent(content) {
		if (!content) return;
		this.mainEmailContent = content;
		
		// Only set currentTemplate if it's different from mainEmailContent
		if (content !== this.currentTemplate) {
			this.currentTemplate = content;
		}
	}

	setCurrentDesign(design) {
		this.currentDesign = design;
	}

	setCurrentTemplate(template) {
		// Only update if it's not the same as mainEmailContent
		if (template !== this.mainEmailContent) {
			this.currentTemplate = template;
		}
	}

	setTemporaryRetryEdit(retryIndex, content) {
		if (!content) return;
		
		const index = retryIndex.toString();
		this.temporaryRetryEdits[index] = content;
	}

	getTemporaryRetryEdit(retryIndex) {
		const index = retryIndex.toString();
		return this.temporaryRetryEdits[index];
	}

	clearTemporaryRetryEdits() {
		this.temporaryRetryEdits = {};
	}

}

export default MarketingStore;
