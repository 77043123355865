import React, { useState, useMemo, useEffect } from "react";
import { useStore } from "../../../../hooks";
import { observer } from "mobx-react";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { useMediaQueries } from "../../../../utils/mediaQueries";
import { getDensity } from "../../../../utils/GetDensity/GetDensity";
import {
  StyledContainer,
  StyledText,
  TablePaper,
  TableContainer,
} from "./AlertTable.styled";
import dayjs from "dayjs";
import AlertFilter from "./AlertFilter";
import StatusSelect from "./select/StatusSelect";
import UserSelect from "./select/UserSelect";
import { SuccessfulModal } from "../../../../elements";
import { RowStatus } from "../../../../utils/enums";

const AlertTable = observer(({ filters }) => {
  const { alertStore, authStore } = useStore();
  const { userId, selectedAccount } = authStore;
  const { isMobile, isTablet, isDesktop } = useMediaQueries();

  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([{ id: "date", desc: true }]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const [isLoading, setIsLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [selectedUserFilter, setSelectedUserFilter] = useState([]);
  const [selectedDateFilter, setSelectedDateFilter] = useState([]);
  const [selectedStatusFilter, setSelectedStatusFilter] = useState([]);
  const [usersTable, setUsersTable] = useState([]);
  const [localStatuses, setLocalStatuses] = useState({});
  const [localAssignees, setLocalAssignees] = useState({});
  const [rowSelection, setRowSelection] = useState({});

  const [usersError, setUsersError] = useState(false);
  const [alertsError, setAlertsError] = useState(false);
  const [genericError, setGenericError] = useState(false);

  const density = useMemo(
    () => getDensity(isMobile, isTablet, isDesktop),
    [isMobile, isTablet, isDesktop]
  );

  const handleClear = () => {
    setSearchValue("");
    setSelectedUserFilter([]);
    setSelectedDateFilter([]);
    setSelectedStatusFilter([]);
  };

  useEffect(() => {
    setGlobalFilter(searchValue);
    setPagination((prev) => ({ ...prev, pageIndex: 0 }));
  }, [searchValue]);

  useEffect(() => {
    const loadUsers = async () => {
      if (!selectedAccount.id) return;
      try {
        const response = await authStore.fetchAllUsers();
        const formattedUsers = response?.map((user) => ({
          id: user.id,
          name: `${user.first_name} ${user.last_name}`,
        })) ?? [];
        setUsersTable(formattedUsers);
      } catch (error) {
        setUsersError(true);
      }
    };
    loadUsers();
  }, [selectedAccount.id, alertStore.filteredAlerts]);  

  useEffect(() => {
    const loadAlerts = async () => {
      if (!selectedAccount.id || !userId) return;
      try {
        setIsLoading(true);
        const thereAreUserFilters = selectedUserFilter.length > 0;
        const thereAreDateFilters = selectedDateFilter.length > 0;
        const thereAreStatusFilters = selectedStatusFilter.length > 0;

        const appliedFilters = {};

        if (thereAreUserFilters) {
          appliedFilters.assignee = selectedUserFilter;
        }

        if (thereAreDateFilters) {
          appliedFilters.created_date = selectedDateFilter;
        }

        if (thereAreStatusFilters) {
          appliedFilters.status = selectedStatusFilter;
        }

        await alertStore.getAlerts({
          customerId: selectedAccount.id,
          pageIndex: pagination.pageIndex,
          pageSize: pagination.pageSize,
          sortBy: sorting,
          search: globalFilter,
          filters: { ...filters, ...appliedFilters },
        });
        const initialStatuses = alertStore.filteredAlerts.reduce((acc, alert) => {
          acc[alert.id] = alert.status;
          return acc;
        }, {});
        setLocalStatuses(initialStatuses);
      } catch (error) {
        setAlertsError(true);
      } finally {
        setIsLoading(false);
      }
    };
    loadAlerts();
  }, [
    selectedAccount,
    selectedDateFilter,
    selectedStatusFilter,
    userId,
    pagination,
    sorting,
    globalFilter,
    selectedUserFilter,
    alertStore,
    filters,
  ]);

  const dateOptions = useMemo(() => {
    return Array.from(
      new Set(
        alertStore.filteredAlerts?.map((alert) => alert.created_date)
          .filter(Boolean)
          .map((date) => dayjs(date).format("MM/DD/YYYY")) || []
      )
    );
  }, [alertStore.filteredAlerts]);
  

  const statusOptions = useMemo(() => {
    const statuses = alertStore.filteredAlerts?.map((alert) => alert.status) ?? [];
    return Array.from(
      new Set(
        statuses.filter((status) => status != null)
      )
    );
  }, [alertStore.filteredAlerts]);
  

  const handleUserChange = async (rowId, newUserId) => {
    try {
      const currentAlert = alertStore.filteredAlerts?.find((alert) => alert.id === rowId);
      if (!currentAlert) return;
      const currentStatus = currentAlert.status;
      await alertStore.updateAlert(rowId, selectedAccount.id, currentStatus, newUserId);
      setLocalAssignees((prev) => ({ ...prev, [rowId]: newUserId }));
      alertStore.setFilteredAlerts(
        [...alertStore.filteredAlerts].map((alert) =>
          alert.id === rowId ? { ...alert, assignee: newUserId } : alert
        )
      );
    } catch (error) {
      setGenericError(true);
    }
  };

  const handleStatusChange = async (id, newStatus) => {
    try {
      const currentAlert = alertStore.filteredAlerts?.find((alert) => alert.id === id);
      if (!currentAlert) return;
      const currentAssignee = currentAlert.assignee;
      await alertStore.updateAlert(
        id,
        selectedAccount.id,
        newStatus.toLowerCase(),
        currentAssignee
      );
      setLocalStatuses((prevStatuses) => ({
        ...prevStatuses,
        [id]: newStatus.toLowerCase(),
      }));
      alertStore.setFilteredAlerts(
        [...alertStore.filteredAlerts].map((alert) =>
          alert.id === id
            ? { ...alert, status: newStatus.toLowerCase(), assignee: currentAssignee }
            : alert
        )
      );      
    } catch (error) {
      setGenericError(true);
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "title",
        header: "Title",
        Cell: ({ cell }) => <StyledText>{cell.getValue()}</StyledText>,
      },
      {
        accessorKey: "message",
        header: "Message",
        Cell: ({ cell }) => <StyledText>{cell.getValue()}</StyledText>,
      },
      {
        accessorKey: "assignee",
        header: "Assigned User",
        Cell: ({ row }) => {
          const assignedUserId =
            localAssignees[row.original.id] ?? row.original.assignee ?? "Unassigned";
          return (
            <UserSelect
              users={usersTable}
              value={assignedUserId}
              onChange={(newUserId) => handleUserChange(row.original.id, newUserId)}
            />
          );
        },
      },
      {
        accessorKey: "created_date",
        header: "Creation Date",
        Cell: ({ cell }) => (
          <StyledText>{dayjs(cell.getValue()).format("MM/DD/YYYY")}</StyledText>
        ),
      },
      {
        accessorKey: "status",
        header: "Status",
        Cell: ({ row }) => {
          const { id, status } = row.original;
          const currentStatus = localStatuses[id] || status;
          return (
            <StatusSelect
              value={currentStatus.charAt(0).toUpperCase() + currentStatus.slice(1)}
              onChange={(e) => handleStatusChange(id, e.target.value)}
              options={[RowStatus.UNRESOLVED, RowStatus.RESOLVED]}
            />
          );
        },
      },
    ],
    [localStatuses, usersTable]
  );

  const table = useMaterialReactTable({
    columns,
    enableTopToolbar: false,
    enableStickyHeader: true,
    data: alertStore.filteredAlerts || [],
    manualPagination: true,
    manualSorting: true,
    manualGlobalFilter: true,
    rowCount: alertStore.totalRowCount || 0,
    initialState: { sorting, density },
    state: {
      pagination,
      globalFilter,
      sorting,
      isLoading,
      rowSelection,
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onGlobalFilterChange: setGlobalFilter,
    onRowSelectionChange: setRowSelection,
    muiTableBodyRowProps: ({ row }) => ({
      sx: {
        backgroundColor:
          (localStatuses[row.original.id] || row.original.status) === RowStatus.RESOLVED
            ? "white"
            : "#F2F7FF",
      },
    }),
    muiTableBodyCellProps: ({ row, column }) => ({
      sx: {
        fontSize: "12px",
        fontFamily: "'Inter', sans-serif",
      },
    }),
    muiTablePaperProps: {
      component: TablePaper,
    },
    muiTableContainerProps: {
      component: TableContainer,
    },
    getRowId: (row) => row.id,
    enableToolbarSelect: false,
  });

  return (
    <StyledContainer isMobile={isMobile} isTablet={isTablet} isDesktop={isDesktop}>
      <AlertFilter
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        onClear={handleClear}
        userOptions={usersTable}
        dateOptions={dateOptions}
        statusOptions={statusOptions}
        selectedUserFilter={selectedUserFilter}
        selectedDateFilter={selectedDateFilter}
        selectedStatusFilter={selectedStatusFilter}
        onUserFilterChange={setSelectedUserFilter}
        onDateFilterChange={setSelectedDateFilter}
        onStatusFilterChange={setSelectedStatusFilter}
      />
      <MaterialReactTable table={table} />
      <SuccessfulModal
        isOpen={usersError}
        onClose={() => setUsersError(false)}
        title="Error loading users"
        subtitle="Failed to load users. Please try again later."
        subtitle2=""
        height="auto"
        width="400px"
        zIndex={true}
      />
      <SuccessfulModal
        isOpen={alertsError}
        onClose={() => setAlertsError(false)}
        title="Error loading alerts"
        subtitle="Failed to load alerts. Please try again later."
        subtitle2=""
        height="auto"
        width="400px"
        zIndex={true}
      />
      <SuccessfulModal
        isOpen={genericError}
        onClose={() => setGenericError(false)}
        title="Oops!"
        subtitle="Please try again later."
        subtitle2=""
        height="auto"
        width="400px"
        zIndex={true}
      />
    </StyledContainer>
  );
});

export default AlertTable;
