import styled from "styled-components";

export const EmailEditorWrapper = styled.div`
  flex: 5;
  transition: all 0.3s ease;

  > div {
    height: 100%;
    min-width: 0;
  }

  iframe {
    /* Intended to use important since the library forces the width to be a minimum of 1024px */
    min-width: 100% !important;
  }
`;