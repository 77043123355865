import React from "react";
import { createBrowserRouter, Navigate, Route, createRoutesFromElements } from "react-router-dom";
import { observer } from "mobx-react";
import { useStore } from "../hooks";
import App from "../components/app/App";
import AppPage from "../components/app/index";
import ErrorPage from "../error-page";
import UserPage from "../pages/UsersPage";
import CustomerAccountPage from "../pages/CustomerAccountPage";
import KeyPage from "../pages/CustomerDataPlatformPage";
import ChannelPage from "../pages/ChannelManagmentModulePage";
import CampaignPage from "../pages/Campaigns";
import AssistantManagementPage from "../pages/AssistantManagement";
import ContactPage from "../pages/ContactPage";
import OnboardingPage from "../pages/OnboardingPage";
import ProfilePage from "../pages/ProfilePage/ProfilePage";
import NewAgentPage from "../pages/NewAgentPage";
import NewUserPage from "../pages/NewUserPage/NewUserPage";
import HomeChatPage from "../pages/HomeChatPage/HomeChatPage";
import NewCampaignPage from "../pages/NewCampaignPage/NewCampaignPage";
import EditAgentPage from "../pages/AgentPage/EditAgent/EditAgentPage";
import ContactFlowPage from "../pages/ContactFlow";
import PlaygroundPage from "../pages/AgentPage/PlaygroundPage/PlaygroundPage";
import NotificationPage from "../pages/NotificationPage";
import UnifiedProfilePage from "../pages/UnifiedProfile";
import AnnUnifiedProfilePage from "../pages/ann-unified-profile";
import DashboardsPage from "../pages/DashboardsPage/DashboardsPage";
import AgentsTreePage from "../pages/v2/AgentsTeam/AgentsTree/AgentsTreePage";
import CreateAgentsTeamStepper from "../pages/v2/AgentsTeam/CreateAgentsTeamStepper";
import UsersPage from "../pages/v2/UsersPage";
import ForbiddenPage from "../components/forbidenPage"; // Updated import
import UnifiedProfileManagement from "../pages/v2/UnifiedProfilePage/index";

//v2
import AssistantManagementPageV2 from "../pages/v2/AsistantManagement";
import AgentsTeamPage from "../pages/v2/AgentsTeam";
import TemplateEditor from "../elements/v2/TemplateEditor/TemplateEditor";
import ConversationPage from "../pages/v2/Conversations";
import RolesPage from "../pages/v2/RolesPage";
import HomePageV2 from "../pages/v2/HomePage";
import GroupsPage from "../pages/v2/GroupsPage";
import PersonalizationPage from "../pages/v2/PersonaPage/PersonaPage";

import { Loading } from "../components/loading";
import BlastCampaignConfiguration from "../pages/v2/BlastCampaigns/BlastCampaignConfiguration";
import HumanInTheLoop from "../elements/v2/HumanInTheLoop/HumanInTheLoop";
import NotificationUserPage from "../pages/v2/NotificationUserPage";
import VehiclePage from "../pages/v2/VehiclesPage/VehiclesPage";
import RepairOrdersPage from "../pages/v2/RepairOrdersPage/RepairOrderPage";
import AppointmentsPage from "../pages/v2/AppointmentsPage/AppointmentsPage";
import ProfilePageV2 from "../pages/v2/ProfilePage/ProfilePage"
import InventoryPage from "../pages/v2/InventoryPage/InventoryPage";
import ChatWidgetComponent from "../pages/v2/EmbeddedChatPage/ChatWidgetComponent";
import QuestionnairesPage from "../pages/v2/QuestionsPage";
import AlertPage from "../pages/v2/AlertPage";

const ProtectedRoute = observer(
  ({ element: Element, permissionCode, ...rest }) => {
    const { authStore } = useStore();

    return (
      <>
        {authStore.isLoading && <Loading />}
        {!authStore.isLoading && (
          <>
            {authStore.isGroupSuperAdmin ||  authStore.isGroupAdmin || (authStore.userPermissions.length &&
              authStore.hasPermission(permissionCode, "VIEW")) ? (
              <Element {...rest} />
            ) : (
              <ForbiddenPage />
            )}
          </>
        )}
      </>
    );
  },
);

const RoutesMenu = createBrowserRouter(
  createRoutesFromElements(
    <Route path={"/"} element={<AppPage />} errorElement={<ErrorPage />}>
      {/* Add this new route for the forbidden page */}
      <Route
        path="/forbidden"
        element={<ForbiddenPage />}
        errorElement={<ErrorPage />}
      />

      <Route
        index
        element={<Navigate to="/home" />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="/home"
        element={<ProtectedRoute element={HomePageV2} permissionCode="HOME" />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="/users/*"
        element={
          <ProtectedRoute element={UserPage} permissionCode="USERS_VIEW" />
        }
        errorElement={<ErrorPage />}
      />
      <Route
        path="/customers/*"
        element={
          <ProtectedRoute
            element={CustomerAccountPage}
            permissionCode="CUSTOMERS_VIEW"
          />
        }
        errorElement={<ErrorPage />}
      />
      <Route
        path="/keys/*"
        element={
          <ProtectedRoute element={KeyPage} permissionCode="KEYS_VIEW" />
        }
        errorElement={<ErrorPage />}
      />
      <Route
        path="/channels/*"
        element={
          <ProtectedRoute
            element={ChannelPage}
            permissionCode="CHANNELS_VIEW"
          />
        }
        errorElement={<ErrorPage />}
      />
      <Route
        path="/conversations/*"
        element={
          <ProtectedRoute
            element={CampaignPage}
            permissionCode="CONVERSATIONS_VIEW"
          />
        }
        errorElement={<ErrorPage />}
      />
      <Route
        path="/assistants/*"
        element={
          <ProtectedRoute
            element={AssistantManagementPage}
            permissionCode="AGENTS_VIEW"
          />
        }
        errorElement={<ErrorPage />}
      />
      <Route
        path="/contacts/*"
        element={
          <ProtectedRoute
            element={ContactPage}
            permissionCode="CONTACTS_VIEW"
          />
        }
        errorElement={<ErrorPage />}
      />
      <Route
        path="/chat/*"
        element={<ProtectedRoute element={ChatWidgetComponent} permissionCode="CHAT_VIEW" />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="/metrics/*"
        element={
          <ProtectedRoute
            element={DashboardsPage}
            permissionCode="METRICS_VIEW"
          />
        }
        errorElement={<ErrorPage />}
      />
      <Route
        path="/new-agent/*"
        element={
          <ProtectedRoute
            element={NewAgentPage}
            permissionCode="AGENTS_CREATE"
          />
        }
        errorElement={<ErrorPage />}
      />
      <Route
        path="/duplicate-agent/*"
        element={
          <ProtectedRoute
            element={EditAgentPage}
            permissionCode="AGENTS_DUPLICATE"
          />
        }
        errorElement={<ErrorPage />}
      />
      <Route
        path="/playground/*"
        element={
          <ProtectedRoute
            element={PlaygroundPage}
            permissionCode="PLAYGROUND_VIEW"
          />
        }
        errorElement={<ErrorPage />}
      />
      <Route
        path="/new-user/*"
        element={
          <ProtectedRoute element={NewUserPage} permissionCode="USERS_CREATE" />
        }
        errorElement={<ErrorPage />}
      />
      <Route
        path="/new-conversation/*"
        element={
          <ProtectedRoute
            element={NewCampaignPage}
            permissionCode="CONVERSATIONS_CREATE"
          />
        }
        errorElement={<ErrorPage />}
      />
      <Route
        path="/profile/*"
        element={
          <ProtectedRoute element={ProfilePage} permissionCode="PROFILE_VIEW" />
        }
        errorElement={<ErrorPage />}
      />
      <Route
        path="/notifications"
        element={
          <ProtectedRoute
            element={NotificationPage}
            permissionCode="NOTIFICATIONS_VIEW"
          />
        }
        errorElement={<ErrorPage />}
      />
      <Route
        path="/contacts/upload/*"
        element={
          <ProtectedRoute
            element={ContactFlowPage}
            permissionCode="CONTACTS_UPLOAD"
          />
        }
        errorElement={<ErrorPage />}
      />
      <Route
        path="/unified-profile"
        element={
          <ProtectedRoute
            element={UnifiedProfileManagement}
            permissionCode="UNIFIED_PROFILE_VIEW"
          />
        }
        errorElement={<ErrorPage />}
      />
      <Route
        path="/ann-unified-profile"
        element={
          <ProtectedRoute
            element={AnnUnifiedProfilePage}
            permissionCode="UNIFIED_PROFILE_VIEW"
          />
        }
        errorElement={<ErrorPage />}
      />

      <Route
        path="/blast-campaigns/configure"
        element={
          <ProtectedRoute
            element={BlastCampaignConfiguration}
            permissionCode="BLAST_CAMPAIGN"
          />
        }
        errorElement={<ErrorPage />}
      />

      <Route path="v2">
        <Route
          path="agents-team/*"
          element={
            <ProtectedRoute
              element={AgentsTeamPage}
              permissionCode="AGENTS_TEAM_VIEW"
            />
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="assistants/*"
          element={
            <ProtectedRoute
              element={AssistantManagementPageV2}
              permissionCode="AGENTS_VIEW"
            />
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="template/*"
          element={
            <ProtectedRoute
              element={TemplateEditor}
              permissionCode="TEMPLATE_EDIT"
            />
          }
        />
        <Route
          path="users/*"
          element={
            <ProtectedRoute element={UsersPage} permissionCode="USERS_VIEW" />
          }
          errorElement={<ErrorPage />}
        />

        <Route
          path="questions/*"
          element={
            <ProtectedRoute element={QuestionnairesPage} permissionCode="QUESTIONS_VIEW" />
          }
          errorElement={<ErrorPage />}
        />

        <Route
          path="conversations/*"
          element={
            <ProtectedRoute
              element={ConversationPage}
              permissionCode="CONVERSATIONS_VIEW"
            />
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="alerts/*"
          element={
            <ProtectedRoute
              element={AlertPage}
            />
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="roles/*"
          element={
            <ProtectedRoute element={RolesPage} permissionCode="ROLES_VIEW" />
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="groups/*"
          element={
            <ProtectedRoute element={GroupsPage} permissionCode="GROUPS_VIEW" />
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="personalization/*"
          element={<PersonalizationPage />}
          errorElement={<ErrorPage />}
        />
        <Route
          path="unified-profile"
          element={
            <ProtectedRoute
              element={UnifiedProfileManagement}
              permissionCode="UNIFIED_PROFILE_VIEW"
            />
          }
          errorElement={<ErrorPage />}
        />
        <Route
          path="human/*"
          element={<HumanInTheLoop />}
          errorElement={<ErrorPage />}
        />
        <Route
          path="notifications/*"
          element={<NotificationUserPage />}
          errorElement={<ErrorPage />}
        />
        <Route
          path="vehicles/*"
          element={<VehiclePage />}
          errorElement={<ErrorPage />}
        />
        <Route
          path="repair-orders/*"
          element={<RepairOrdersPage />}
          errorElement={<ErrorPage />}
        />
        <Route
          path="appointments/*"
          element={<AppointmentsPage />}
          errorElement={<ErrorPage />}
        />
        <Route
          path="profile/*"
          element={<ProtectedRoute element={ProfilePageV2} permissionCode="PROFILE_VIEW" />}
          errorElement={<ErrorPage />}
        />
        <Route
          path="inventory/*"
          element={<InventoryPage />}
          errorElement={<ErrorPage />}
        />
      </Route>
    </Route>,
  ),
);

export default RoutesMenu;
