import styled from "styled-components";

export const EmailTemplateWrapper = styled.div`
  height: 80vh;
`;

export const MessageEditorError = styled.div`
    padding: 15px 10px;
    background-color: ${(props) => props.theme.colors.redLavender};
    color: ${(props) => props.theme.colors.red};
    margin-bottom: 10px;
    text-align: center;
`;
