import React from "react";
import {
  FiltersContainer,
  SearchIcon,
  InputField,
  CustomGrid,
  FilterContainter,
  CustomSearchInput,
} from "./AlertFilter.styled";
import { Icon, RoundedButton } from "../../../../elements";
import FilterSelector from "../../../../elements/v2/FilterSelector/FilterSelector";
import UserTableSelect from "./select/AlertUserSelectTable";

const AlertFilter = ({
  searchValue,
  setSearchValue,
  onClear,
  userOptions = [],
  dateOptions = [],
  statusOptions = [],
  selectedUserFilter = "",
  selectedDateFilter = [],
  selectedStatusFilter = [],
  onUserFilterChange,
  onDateFilterChange,
  onStatusFilterChange,
}) => {
  const handleClearAll = () => {
    setSearchValue("");
    onUserFilterChange("");
    onDateFilterChange([]);
    onStatusFilterChange([]);
    if (onClear) {
      onClear();
    }
  };

  return (
    <FiltersContainer>
      <CustomGrid>
        <FilterContainter>
          <CustomSearchInput>
            <SearchIcon>
              <Icon name="magnifier" height="20px" width="20px" />
            </SearchIcon>
            <InputField
              type="text"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder="Search"
            />
          </CustomSearchInput>
          <UserTableSelect
            users={userOptions}
            value={selectedUserFilter}
            onChange={(newUserId) => onUserFilterChange(newUserId)}
            itemHeight={48}
            itemPaddingTop={8}
            minWidthForm={80}
            minWidthPaper={240}
            disabled={userOptions.length === 0}
          />
          <FilterSelector
            title="Date"
            data={dateOptions}
            disabled={dateOptions.length === 0}
            itemHeight={48}
            itemPaddingTop={8}
            minWidthForm={80}
            minWidthPaper={240}
            value={selectedDateFilter}
            handleChange={(event) => onDateFilterChange(event.target.value)}
            type="multiple"
          />
          <FilterSelector
            title="Status"
            data={statusOptions}
            disabled={statusOptions.length === 0}
            itemHeight={48}
            itemPaddingTop={8}
            minWidthForm={110}
            minWidthPaper={240}
            value={selectedStatusFilter}
            handleChange={(event) => onStatusFilterChange(event.target.value)}
            type="multiple"
          />
          <RoundedButton
            width="86px"
            height="40px"
            kind="confirm"
            outline={true}
            fontWeight="normal"
            fontSize="14px"
            margin="0"
            borderRadius="8px"
            onClick={handleClearAll}
          >
            Clear all
          </RoundedButton>
        </FilterContainter>
      </CustomGrid>
    </FiltersContainer>
  );
};

export default AlertFilter;
