import { useCallback, useState} from 'react';
import { 
  ModalWrapper,
  CloseButtonWrapper,
  ModalContentWrapper
} from '../../../../elements/v2/modals/Modals.styled';
import { InputWrapper, Subtitle, ActivationScheduleWrapper, ModalActionsWrapper, ModalBoxGray } from '../components/NewConversationModal/NewConversationModal.styled';
import { observer } from 'mobx-react';
import dayjs from 'dayjs';
import TextArea from '../../../../elements/v2/TextArea/TextArea';
import InputText from '../../../../elements/v2/TextInput/TextInput';
import { useStore } from '../../../../hooks';
import { IconButton, RoundedButton } from '../../../../elements';
import DateTimePicker from '../../../../elements/v2/DateTimePicker/DateTimePicker';

const EditMarketingModal = observer(({ isOpen, setIsOpen }) => {
    const { marketingStore, authStore } = useStore();
    const [titleError, setTitleError] = useState(false);
    
     const onHandleClose = () => {
      setIsOpen(false);
      setTitleError(false);
    };

    const onHandleConfirm = useCallback(() => {
        if (!marketingStore.name?.trim()) {
            setTitleError(true);
            return;
        }
        marketingStore.update();
        setIsOpen(false);
        setTitleError(false);
    }, [marketingStore]);

    const handleTitleChange = (e) => {
        marketingStore.setMarketingAttribute("name", e.target.value);
        if (e.target.value.trim() && titleError) {
            setTitleError(false);
        }
    };

    const handleEndDateChange = (value) => {
        if (marketingStore.startDate && marketingStore.startDate.isSame(value, 'day')) {
            const minEndTime = marketingStore.startTime ? marketingStore.startTime.add(4, 'hour') : null;
            marketingStore.setMarketingAttribute('endDate', value);
            marketingStore.setMarketingAttribute('minEndTime', minEndTime);
        } else {
            marketingStore.setMarketingAttribute('endDate', value);
            marketingStore.setMarketingAttribute('minEndTime', null);
        }
    };

    const handleEndTimeChange = (value) => {
        marketingStore.setMarketingAttribute('endTime', value);
    };

    const minEndTime = marketingStore.startDate && marketingStore.endDate && marketingStore.startDate.isSame(marketingStore.endDate, 'day')
        ? (marketingStore.startTime ? marketingStore.startTime.add(4, 'hour') : null)
        : null;

    return (
      <ModalWrapper isOpen={isOpen} $zIndex={5}>
        <ModalBoxGray width="50%" height="auto">
          <CloseButtonWrapper>
            <IconButton icon="close" width="16px" height="16px" onClick={onHandleClose} />
          </CloseButtonWrapper>
          <ModalContentWrapper>
            <InputWrapper>
                <Subtitle>Title</Subtitle>
                <InputText 
                    placeholder="Name" 
                    value={marketingStore.name} 
                    onChange={handleTitleChange}
                    displayError={titleError}
                    validationError={titleError ? "Name cannot be empty. Please enter a valid name." : ""}
                />
            </InputWrapper>

            <InputWrapper>
                <Subtitle>Description</Subtitle>
                <TextArea 
                    placeholder="Enter a description"
                    height="120px"
                    resize="none"
                    value={marketingStore.description} 
                    onChange={e => marketingStore.setMarketingAttribute("description", e.target.value)}
                />
            </InputWrapper>

           <InputWrapper>
                <Subtitle>Activation Schedule</Subtitle>

                <ActivationScheduleWrapper>

                    <DateTimePicker 
                        label="Start Date" 
                        value={marketingStore.startDate} 
                        onChange={value => marketingStore.setMarketingAttribute("startDate", value)}
                        minDate={dayjs()}
                    />
                    <DateTimePicker 
                        label="Start Time" 
                        value={marketingStore.startTime} 
                        type="time"
                        onChange={value => marketingStore.setMarketingAttribute("startTime", value)}
                    />
                    <DateTimePicker 
                        label="End Date - optional" 
                        value={marketingStore.endDate} 
                        onChange={handleEndDateChange}
                        minDate={marketingStore.startDate ? marketingStore.startDate.add(4, 'hour') : dayjs()}
                    />
                    <DateTimePicker 
                        label="End Time - optional" 
                        value={marketingStore.endTime} 
                        type="time"
                        onChange={handleEndTimeChange}
                        minTime={minEndTime}
                    />
                </ActivationScheduleWrapper>
            </InputWrapper>
          </ModalContentWrapper>
          <ModalActionsWrapper>
            <RoundedButton onClick={onHandleConfirm} width="115px">Save</RoundedButton>
          </ModalActionsWrapper>
        </ModalBoxGray>
      </ModalWrapper>
    );
});

export default EditMarketingModal;
