import React from "react";
import { Select, MenuItem, FormControl, Typography, Box } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import {
  getFormControlStyles,
  getSelectStyles,
  getCircleIconStyles,
  getTypographyStyles,
  getMenuTypographyStyles,
} from "./StatusSelect.styled";

const StatusSelect = ({ value, onChange, options }) => (
  <FormControl variant="outlined" size="small" sx={getFormControlStyles()}>
    <Select
      value={value}
      onChange={onChange}
      displayEmpty
      sx={getSelectStyles(value)}
      renderValue={(selected) => (
        <Box display="flex" alignItems="center" gap={1}>
          <CircleIcon sx={getCircleIconStyles(selected)} />
          <Typography variant="body2" sx={getTypographyStyles()}>
            {selected}
          </Typography>
        </Box>
      )}
    >
      {options?.map((option) => {
      const capitalizedOption = option.charAt(0).toUpperCase() + option.slice(1);
      return (
        <MenuItem key={option} value={option}>
          <Box display="flex" alignItems="center" gap={1}>
            <Typography variant="body2" sx={getMenuTypographyStyles()}>
              {capitalizedOption}
            </Typography>
          </Box>
        </MenuItem>
      );
    })}
    </Select>
  </FormControl>
);

export default StatusSelect;
