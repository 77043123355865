import React, {
  useEffect,
  useMemo,
  useState,
} from "react";
import { useStore } from "../../../../hooks";
import { observer } from "mobx-react";
import {
  MarketingContainer,
  MarketingWrapper,
  MarketingWrapperHeader,
  CustomGenericListStyleDesktop,
  MessageActionsWrapper,
  TabButtonContainer,
  TabContainer,
  UploadFilesActionsWrapper,
  SegmentsActionsWrapper,
  Subtitle,
} from "./Marketing.styled";
import {  CloseButtonWrapper, ModalActionsWrapper, ModalBox, ModalContentWrapper, ModalWrapper } from "../../../../elements/v2/modals/Modals.styled";
import {MarketingStatus, MarketingErrorMessages, MarketingTab} from "../../../../utils/enums";
import { useSearchParams, useNavigate } from "react-router-dom";
import { RoundedButton } from "../../../../elements/button";
import { SuccessfulModal } from "../../../../elements";
import { useMediaQueries } from "../../../../utils/mediaQueries";
import { ConfirmationModal } from "../../../../elements/v2/modals";
import { useLocation } from "react-router-dom";
import { Title } from "../../../../elements/v2/modals/ConfirmationModal.styled";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import DynamicBreadcrumbs from "../../../../elements/v2/DynamicBreadcrumbs/DynamicBreadcrumbs";
import Segments from "./Segments";
import Overview from "./Overview";
import Message from "./Message";
import Spinner from "../../../../components/common/Spinner";
import Tabs2 from "../../../../elements/v2/Tabs2/Tabs2";
import IconSelect from "../../../../elements/v2/Selects/IconSelect/IconSelect";
import SuccessModal from '../../../../elements/v2/SuccessModal/SuccessModal';
import AuditLogs from "./AuditLogs";
import Alerts from "../../../../elements/v2/Alerts/Alerts";

import isPastDate from "../../../../utils/isPastDate";
import dayjs from "dayjs";

const Marketing = observer(() => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { authStore, marketingStore, messageEditorStore, contactSegmentStore } = useStore();
  const [clickedOption, setClickedOption] = useState(null);
  const { rulesStore } = contactSegmentStore;
  const { isMobile } = useMediaQueries();
  const [showAlert, setShowAlert] = useState(false);
  const [showAgentSelectionError, setShowAgentSelectionError] = useState(false);
  const [showAgentSelectionErrorOnDraft, setShowAgentSelectionErrorOnDraft] = useState(false);
  const [openCampaignScheduleModal, setOpenCampaignScheduleModal] = useState(false);
  const [datePlus10Min, setDatePlus10Min] = useState("");
  const [datePlus10MinPlus30Days, setDatePlus10MinPlus30Days] = useState("");
  const [datePlus10MinRaw, setDatePlus10MinRaw] = useState("");
  const [datePlus10MinPlus30DaysRaw, setDatePlus10MinPlus30DaysRaw] = useState("");

  useEffect(() => {
    marketingStore.setCurrentTab(MarketingTab.Segments);
  }, []);

  const tabs = [
    {
      label: "Segments",
      disabled: false,
    },
    {
      label: "Message",
      disabled: !contactSegmentStore.isFileUploaded,
      onDisabled: () => {
        setShowAlert(true);
        marketingStore.setCurrentTab(MarketingTab.Segments);
      }
    },
    {
      label: "Overview",
      disabled: false,
    },
    {
      label: "Audit logs",
      disabled: false,
    },
  ];

  const location = useLocation();

  const actionsOptions = useMemo(() => [
    {
      name: "Cancel",
      title: "Cancel",
      icon: "cancelSquare",
      onClick: () => {
        marketingStore.setMarketingAttribute("confirmationDialogMessage", "You're about to end the upcoming conversation. Do you want to continue?")
      },
      onConfirm: () => {
        marketingStore.clearConfirmationDialog();
        marketingStore.cancel(authStore.selectedAccount.id);
      },
      displayOnStatus: [MarketingStatus.ACTIVE]
    },
    {
      name: "Duplicate",
      title: "Duplicate Conversation",
      icon: "duplicateOutline",
      onClick: () => {
        marketingStore.setMarketingAttribute("confirmationDialogMessage", `You are about to duplicate this conversation. This action will create an exact copy of the conversation. Are you sure you want to proceed?`)
      },
      onConfirm: () => {
        marketingStore.duplicate(marketingStore.marketingConversation)
      },
      displayOnStatus: [MarketingStatus.DRAFT, MarketingStatus.ACTIVE, MarketingStatus.CANCELLED]
    }
  ], [marketingStore])

  const breadcrumbItems = [
    { label: "Conversations", href: "/v2/conversations" },
    { label: "Marketing", href: "/v2/conversations" },
    { label: marketingStore?.nameRead }
  ];

  useEffect(() => {
    authStore.fetchAgents();
  }, [authStore]);

  useEffect(() => {
    marketingStore.clear();
    messageEditorStore.clearTemplate();
    contactSegmentStore.clear();

    const marketingId = searchParams.get("id");

    if (!marketingId) {
      navigate("/v2/conversations");
    }

    const id = `${marketingId}/${marketingId}`.replaceAll('#', "");
    marketingStore.getMarketingConversations(authStore.selectedAccount.id, marketingId);
    messageEditorStore.setId(id);
    contactSegmentStore.setFileId(`${id}_contacts`);
    contactSegmentStore.init(authStore.selectedAccount.id)
    contactSegmentStore.updateGeneralField("s3FileRoute", "marketing_conversations");
  }, [searchParams]);

  useEffect(() => {
		messageEditorStore.updateGeneralField('sourceList', contactSegmentStore.emailTemplateEventSourceList);
	}, [contactSegmentStore.emailTemplateEventSourceList[0].fields.length])

  useEffect(() => {
    const actions = marketingStore.marketingConversation?.metadata?.actions;
    const rules = marketingStore.marketingConversation?.metadata?.filters;

    if(actions?.length){
      messageEditorStore.loadPayload(actions[0]);
      messageEditorStore.loadTemplate();
    }

    if(rules?.length && !Object.keys(contactSegmentStore.rulesStore?.rulesGroups).length){
      contactSegmentStore.rulesStore.loadExistingRules(rules)
      contactSegmentStore.updateAdvancedFilter()
    }
  }, [marketingStore.marketingConversation])

  useEffect(() => {
    let alertTimeout;
    if(marketingStore.successfulAlert.isOpen){
      alertTimeout = setTimeout(() => {
        marketingStore.setMarketingAttribute('successfulAlert', { isOpen: false, title: '', message: '' })
      }, 3000);
    }

    return () => {
      clearTimeout(alertTimeout)
    }
  }, [marketingStore.successfulAlert.isOpen])

  useEffect(() => {
    if (showAlert) {
      const timeout = setTimeout(() => setShowAlert(false), 5000);
      return () => clearTimeout(timeout);
    }
  }, [showAlert]);

  const onTabChange = (selectedTab) => {
    setShowAlert(false);
    marketingStore.setCurrentTab(selectedTab);
  };

  const onSubmitScheduleInfo = () => {
    marketingStore.setMarketingAttribute('startDateRead', dayjs(datePlus10MinRaw));
    marketingStore.setMarketingAttribute('endDateRead', dayjs(datePlus10MinPlus30DaysRaw));
    onConfirmAndPublish();
  }

  const onConfirmAndPublish = () => {
    marketingStore.setMarketingAttribute("status", MarketingStatus.ACTIVE);
    marketingStore.emailTemplateExportAction();
    setOpenCampaignScheduleModal(false);
  }

  const onPublish = () => {
    const thereIsNoEndDate = !marketingStore?.endDateRead;
    const startDateIsPastDate = isPastDate(marketingStore?.startDateRead);

    setShowAgentSelectionError(false);

    if (!marketingStore.selectedAgent) {
      setShowAgentSelectionError(true);
      return;
    }

    if (thereIsNoEndDate || startDateIsPastDate) {
      return setOpenCampaignScheduleModal(true);
    }

    onConfirmAndPublish();
  }

  const onDraft = () => {
    setShowAgentSelectionErrorOnDraft(false);
    if (!marketingStore.selectedAgent) {
      setShowAgentSelectionErrorOnDraft(true);
      return;
    }
    marketingStore.setMarketingAttribute("status", MarketingStatus.DRAFT)
    marketingStore.emailTemplateExportAction()
  }

  const actionsButtons = (
    <>
      {showAgentSelectionError && (
        <Alerts
          severity={"error"}
          width={"auto"}
          top={"61px"}
          right={"16px"}
          title={MarketingErrorMessages.PREVENT_PUBLISH_WITHOUT_AGENT.title}
          message={MarketingErrorMessages.PREVENT_PUBLISH_WITHOUT_AGENT.message}
          onClose={() => setShowAgentSelectionError(false)}
        />
      )}
      {![MarketingStatus.ACTIVE, MarketingStatus.CANCELLED].includes(marketingStore.status) && <>
      <RoundedButton
        width="80px"
        height="40px"
        fontSize="0.875rem"
        kind={"publish"}
        disabled={!marketingStore.emailTemplateExportAction}
        onClick={onDraft}
      >
        Save
      </RoundedButton>

      <RoundedButton
        width="65px"
        height="40px"
        fontSize="0.875rem"
        kind={"blue"}
        disabled={!marketingStore.emailTemplateExportAction ||!contactSegmentStore.isFileUploaded}
        onClick={onPublish}
      >
        Publish
      </RoundedButton>
      </>}
    </>
  );

  const getCorrectPaddingRight = () => {
    const isSegmentTab = marketingStore.currentTab === MarketingTab.Segments;
    if (isSegmentTab) {
      return rulesStore?.showRightPreview ? "470px" : "120px";
    }
    return "5px";
  };

  const onCloseConfirmationModal = () => {
    marketingStore.clearConfirmationDialog()
    setClickedOption(null)
  }

  const shouldRenderMarketingWrapper = !marketingStore.isLoading && !marketingStore.updatingTemplate;

  const filteredActionOptions = useMemo(() => {
    return actionsOptions.filter(action =>
      action.displayOnStatus.includes(marketingStore.status)
    );
  }, [actionsOptions, marketingStore.status]);

  const onSaveAsDraft = () => {
    setShowAgentSelectionErrorOnDraft(false);
    marketingStore.setMarketingAttribute("status", MarketingStatus.DRAFT)
    marketingStore.emailTemplateExportAction()
  }

  const onAssignAgent = () => {
    setShowAgentSelectionErrorOnDraft(false);
    marketingStore.setCurrentTab(MarketingTab.Overview);
  }


  const onEditSchedule = () => {
    setOpenCampaignScheduleModal(false);
    marketingStore.setCurrentTab(MarketingTab.Overview);
  }

  const getFormattedDates = () => {
    const now = new Date();

    const datePlus10Min = new Date(now);
    datePlus10Min.setMinutes(datePlus10Min.getMinutes() + 10);

    const datePlus10MinPlus30Days = new Date(datePlus10Min);
    datePlus10MinPlus30Days.setDate(datePlus10MinPlus30Days.getDate() + 30);

    const formatter = new Intl.DateTimeFormat("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
    });
    const timeFormatter = new Intl.DateTimeFormat("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
    });

    setDatePlus10Min(`${formatter.format(datePlus10Min)} at ${timeFormatter.format(datePlus10Min)}`);
    setDatePlus10MinPlus30Days(`${formatter.format(datePlus10MinPlus30Days)} at ${timeFormatter.format(datePlus10MinPlus30Days)}`);
    setDatePlus10MinRaw(datePlus10Min);
    setDatePlus10MinPlus30DaysRaw(datePlus10MinPlus30Days);
  }

  useEffect(() => {
    const updateDates = () => getFormattedDates();

    const intervalId = setInterval(updateDates, 1000);

    updateDates();

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
        <ModalWrapper isOpen={showAgentSelectionErrorOnDraft}>
          <ModalBox width="500px" height="305px">
            <CloseButtonWrapper>
              <IconButton width="16px" height="16px" onClick={() => setShowAgentSelectionErrorOnDraft(false)}>
                <Close />
              </IconButton>
            </CloseButtonWrapper>
            <ModalContentWrapper>
              <Title>{MarketingErrorMessages.PREVENT_DRAFT_WITHOUT_AGENT.title}</Title>
              <p>{MarketingErrorMessages.PREVENT_DRAFT_WITHOUT_AGENT.message}</p>
            </ModalContentWrapper>
            <ModalActionsWrapper>
              <RoundedButton onClick={onSaveAsDraft} kind={"primary"}>Save as Draft</RoundedButton>
              <RoundedButton onClick={onAssignAgent} kind={"blue"}>Assign Agent</RoundedButton>
            </ModalActionsWrapper>
          </ModalBox>
      </ModalWrapper>
      
      <ModalWrapper isOpen={openCampaignScheduleModal}>
        <ModalBox width="500px" height="305px">
            <ModalContentWrapper>
              <Title>Schedule Adjustment Notice</Title>
              <p>We’ve adjusted your campaign schedule to ensure timely delivery. Please review the details below and confirm or make changes before publishing.</p>
              <div><Subtitle>Campaign Title:</Subtitle> {marketingStore.name}</div>
              <div><Subtitle>Start Date & Time:</Subtitle> {datePlus10Min}</div>
              <div><Subtitle>End Date & Time:</Subtitle> {datePlus10MinPlus30Days}</div>
            </ModalContentWrapper>
            <ModalActionsWrapper>
              <RoundedButton onClick={onSubmitScheduleInfo} kind={"blue"}>Confirm & Publish</RoundedButton>
              <RoundedButton onClick={onEditSchedule} kind={"primary"}>Edit Schedule</RoundedButton>
            </ModalActionsWrapper>
        </ModalBox>
      </ModalWrapper>

      {showAlert && (
        <Alerts
          severity={"error"}
          width={"auto"}
          top={"61px"}
          right={"16px"}
          title={MarketingErrorMessages.MESSAGE_TAB.title}
          message={MarketingErrorMessages.MESSAGE_TAB.message}
          onClose={() => setShowAlert(false)}
        />
      )}
      {showAgentSelectionError && (
        <Alerts
          severity={"error"}
          width={"auto"}
          top={"61px"}
          right={"16px"}
          title={MarketingErrorMessages.PREVENT_PUBLISH_WITHOUT_AGENT.title}
          message={MarketingErrorMessages.PREVENT_PUBLISH_WITHOUT_AGENT.message}
          onClose={() => setShowAgentSelectionError(false)}
        />
      )}
      <MarketingContainer isMobile={isMobile}>
        <DynamicBreadcrumbs items={breadcrumbItems} currentPath={location.pathname} />
        {shouldRenderMarketingWrapper ? <MarketingWrapper paddingRight={getCorrectPaddingRight()}>
          <MarketingWrapperHeader>
            <TabContainer>
              <Tabs2
                tabs={tabs}
                onToggleTab={onTabChange}
                defaultTab={marketingStore.currentTab}
              ></Tabs2>
              <TabButtonContainer>
                {marketingStore.currentTab === MarketingTab.Message && (
                  <MessageActionsWrapper>{actionsButtons}</MessageActionsWrapper>
                )}
                {marketingStore.currentTab === MarketingTab.Segments && (
                  <SegmentsActionsWrapper>
                    {actionsButtons}
                  </SegmentsActionsWrapper>
                )}
                {marketingStore.currentTab === MarketingTab.Overview && (
                  <UploadFilesActionsWrapper>
                    <IconSelect
                      placeholder="Actions"
                      options={filteredActionOptions}
                      setClickedOption={option => setClickedOption(option)}
                    />
                    {actionsButtons}
                  </UploadFilesActionsWrapper>
                )}
              </TabButtonContainer>
            </TabContainer>

            <Tabs2.Outlet show={marketingStore.currentTab === MarketingTab.Message}>
              <Message />
            </Tabs2.Outlet>

            <Tabs2.Outlet show={marketingStore.currentTab === MarketingTab.Segments}>
              <Segments />
            </Tabs2.Outlet>

            <Tabs2.Outlet show={marketingStore.currentTab === MarketingTab.Overview}>
              <Overview />
            </Tabs2.Outlet>

            <Tabs2.Outlet show={marketingStore.currentTab === MarketingTab.AuditLogs}>
              <AuditLogs />
            </Tabs2.Outlet>

          </MarketingWrapperHeader>
        </MarketingWrapper>  : <Spinner/>}
        <CustomGenericListStyleDesktop />

        <SuccessfulModal
          isOpen={marketingStore.isError}
          onClose={() => marketingStore.onClearError()}
          title="Oops!"
          subtitle={!marketingStore.errorMessage ? 'An error occurred.' : ''}
          subtitle2={marketingStore.errorMessage || 'Please try again later.'}
          height='300px'
          zIndex={true}
        />

        <SuccessfulModal
          isOpen={marketingStore.isSuccessful}
          onClose={() => marketingStore.setMarketingAttribute('isSuccessful', false)}
          title="Success!"
          subtitle="Campaign has been saved successfully."
          height='200px'
          width='500px'
          zIndex={true}
        />

        <ConfirmationModal
          isOpen={clickedOption}
          title={clickedOption?.title}
          message={marketingStore.confirmationDialogMessage}
          icon={clickedOption?.icon}
          onConfirm={() => {
            clickedOption?.onConfirm?.()
            setClickedOption(null)
          }}
          onClose={onCloseConfirmationModal}
          onCancel={onCloseConfirmationModal}
          onConfirmLabel="Continue"
        />

        <SuccessModal
          isOpen={marketingStore.successfulAlert.isOpen}
          icon="check_circle"
          onClose={() => marketingStore.setMarketingAttribute('successfulAlert', { isOpen: false, title: '', message: '' })}
          title={marketingStore.successfulAlert.title}
          message={marketingStore.successfulAlert.message}
        />
      </MarketingContainer>
    </>
  );
});

export default Marketing;
